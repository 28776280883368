import { Component, Input, OnInit } from '@angular/core';
import { AlertsControlOptions } from '../../../../shared/alerts-component.model';
import { AlertQualification, AlertSearchFilter } from '../../../../shared/alert.model';
import { DateTime } from 'luxon';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../services/utils.service';

@Component({
  selector: 'app-alerts-controls',
  templateUrl: './alerts-controls.component.html',
  styleUrls: ['./alerts-controls.component.scss']
})
export class AlertsControlsComponent implements OnInit {

  public chevronQualification = 'log-au-chevron-down';
  public openQualification = false;
  public newSelected!: boolean;
  public aberentDataSelected!: boolean;
  public healthAlertSelected!: boolean;
  public thresholdAlertSelected!: boolean;
  public sampleErrorSelected!: boolean;
  public uncertainDataSelected!: boolean;
  
  public qualification = AlertQualification;
  public startDate: DateTime | undefined;
  public endDate: DateTime | undefined;
  public globalStartDate = '';
  public globalEndDate = '';
  private selectedQualifications: Set<AlertQualification> = new Set<AlertQualification>([AlertQualification.NEW, 
                                                                                         AlertQualification.ABERENT_DATA,
                                                                                         AlertQualification.HEALTH_ALERT,
                                                                                         AlertQualification.THRESHOLD_ALERT,
                                                                                         AlertQualification.SAMPLE_ERROR,
                                                                                         AlertQualification.UNCERTAIN_DATA]);

  constructor(private route: ActivatedRoute, private router: Router) {
    this.initComponent();
  }

  @Input() set options(options: AlertsControlOptions) {
    const filter = options.params;
    if (filter?.qualification) {
      this.selectedQualifications = new Set<AlertQualification>();
      const qualifications = UtilsService.paramsToArray(filter.qualification);
      for (const q of qualifications) {
        this.selectedQualifications.add(q);
      }
    } else {
      this.selectedQualifications = new Set<AlertQualification>([AlertQualification.NEW, 
                                                                 AlertQualification.ABERENT_DATA,
                                                                 AlertQualification.HEALTH_ALERT,
                                                                 AlertQualification.THRESHOLD_ALERT,
                                                                 AlertQualification.SAMPLE_ERROR,
                                                                 AlertQualification.UNCERTAIN_DATA]);
    }
    if (filter?.startDate) {
      this.startDate = DateTime.fromISO(filter.startDate).toUTC();
    } else {
      this.startDate = undefined;
    }
    if (filter?.endDate) {
      this.endDate = DateTime.fromISO(filter.endDate).toUTC();
    } else {
      this.endDate = undefined;
    }
    this.globalStartDate = options.dateRange.minDate;
    this.globalEndDate = options.dateRange.maxDate;
    this.initComponent();
  }

  ngOnInit(): void {
  }

  public resetFilters(): void {
    this.selectedQualifications.add(AlertQualification.NEW)
                               .add(AlertQualification.ABERENT_DATA)
                               .add(AlertQualification.HEALTH_ALERT)
                               .add(AlertQualification.THRESHOLD_ALERT)
                               .add(AlertQualification.SAMPLE_ERROR)
                               .add(AlertQualification.UNCERTAIN_DATA);
    this.startDate = undefined;
    this.endDate = undefined;
    this.goToFilteredAlerts();
  }

  public toggleQualificationOptions(): void {
    this.openQualification = !this.openQualification;
    this.chevronQualification = this.openQualification ? 'log-au-chevron-up' : 'log-au-chevron-down';
  }

  public setQualification($event: boolean, target: AlertQualification) {
    if ($event) {
      this.selectedQualifications.add(target);
    } else {
      this.selectedQualifications.delete(target);
    }
    this.goToFilteredAlerts();
  }

  public setStartDate(evt: DateTime) {
    this.startDate = evt;
    this.goToFilteredAlerts();
  }

  public setEndDate(evt: DateTime) {
    this.endDate = evt;
    this.goToFilteredAlerts();
  }

  public goToFilteredAlerts() {
    const queryParams: AlertSearchFilter = {};
    if (this.selectedQualifications.size < 6) {
      queryParams.qualification = Array.from(this.selectedQualifications.values());
    }
    if (this.startDate) {
      UtilsService.applyDateFilter(queryParams, 'start', this.startDate);
    }
    if (this.endDate) {
      UtilsService.applyDateFilter(queryParams, 'end', this.endDate);
    }
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, skipLocationChange: false }).then();

  }

  private initComponent(): void {
    this.newSelected            = this.selectedQualifications.has(AlertQualification.NEW);
    this.aberentDataSelected    = this.selectedQualifications.has(AlertQualification.ABERENT_DATA);
    this.healthAlertSelected    = this.selectedQualifications.has(AlertQualification.HEALTH_ALERT);
    this.thresholdAlertSelected = this.selectedQualifications.has(AlertQualification.THRESHOLD_ALERT);
    this.uncertainDataSelected  = this.selectedQualifications.has(AlertQualification.UNCERTAIN_DATA);
    this.sampleErrorSelected    = this.selectedQualifications.has(AlertQualification.SAMPLE_ERROR);
  }
}
