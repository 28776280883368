<app-mini-map #miniMapComponent [options]="options"></app-mini-map>
<div class="controls-body">
  <h1>{{ itemName }}</h1>

  <!-- HIERARCHY -->
  <h2
    *ngIf="options.target !== type.STRUCTURE && options.tree.structureId !== undefined">{{ 'SYNTHESIS.CONTROLS.MASTER_STRUCTURE' | translate }}
    :
    <span (click)="goToStructure(structureId)" class="h2-link"> {{ options.tree.structureName }}</span>
  </h2>
  <h2
    *ngIf="options.target === type.SENSOR && options.tree.bacId !== undefined">{{ 'SYNTHESIS.CONTROLS.MASTER_BAC' | translate }}
    :
    <span (click)="goToBac(options.tree.bacId)" class="h2-link"> {{ options.tree.bacName }}</span>
  </h2>

  <!-- STEP -->
  <h2 *ngIf="options.target !== type.SENSOR && options.stations">{{ options.stations + 'STEP' | translate | uppercase }}</h2>

  <!-- STRUCTURE PROPERTIES -->
  <div class="table-wrapper" *ngIf="options.target === type.STRUCTURE && options.bacList.length">
    <table [dataSource]="options.bacList" mat-table>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell>{{ 'SYNTHESIS.CONTROLS.BAC_LIST' | translate }}</th>
        <td (click)="goToBac(bac.bacId)" *matCellDef="let bac" mat-cell>{{ bac.name | uppercase }}</td>
      </ng-container>

      <ng-container matColumnDef="surface">
        <th *matHeaderCellDef mat-header-cell>{{ 'SYNTHESIS.CONTROLS.BAC_SURFACE' | translate }}</th>
        <td *matCellDef="let bac" mat-cell>{{ bac.surface ? bac.surface + ' ha' : '' }}</td>
      </ng-container>

      <ng-container matColumnDef="surface-percentage">
        <th *matHeaderCellDef mat-header-cell>{{ 'SYNTHESIS.CONTROLS.BAC_SURFACE_PERCENTAGE' | translate }}</th>
        <td *matCellDef="let bac" mat-cell>{{ bac.surfacePercentage ? bac.surfacePercentage + '%' : '' }}</td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
  </div>

  <!-- BAC PROPERTIES -->
  <h2 *ngIf="options.target === type.BAC && options.summary.surface">{{ 'SYNTHESIS.CONTROLS.BAC_SURFACE' | translate }} :
    <span>{{ options.summary.surface }} ha</span>
  </h2>

  <h2 *ngIf="options.target === type.BAC && options.summary.surfacePercentage">{{ 'SYNTHESIS.CONTROLS.BAC_SURFACE_PERCENTAGE' | translate }} :
    <span>{{ options.summary.surfacePercentage }}%</span>
  </h2>

  <!-- BAC AND SENSOR PROPERTIES -->
  <h2 *ngIf="options.target !== type.STRUCTURE && options.summary.population">{{ 'SYNTHESIS.POPULATION_TITLE' | translate }} :
    <span>{{ options.summary.population }} habitants</span>
  </h2>

  <!-- STRUCTURE AND BAC PROPERTIES -->
  <div *ngIf="options.target !== type.SENSOR" class="sensor-wrapper">
    <h2>{{ 'SENSOR_PLURAL' | translate | capitalizeFirst }} :</h2>
    <div class="sensor-list">
      <div *ngFor="let sensor of options.sensors" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="icon" svgIcon="log-au-sensor-ico"></mat-icon>
        <p (click)="goToSensor(sensor.sensorId)" class="link">{{ sensor.name | uppercase }}</p>
      </div>
      <div *ngIf="!options.sensors.length">{{ 'SYNTHESIS.NO_DATA' | translate }}</div>
    </div>
  </div>

  <!-- SENSOR PROPERTIES -->


</div>

