<div id="container" fxLayout="column">
  <!-- FILTERS  -->
  <div id="filters" fxLayout="column" fxLayoutGap="10px">
    <h1>{{ ("SYNTHESIS.FILTERS.TITLE" | translate) + (selectedSupervisor ? ' - ' + selectedSupervisor : '')}}</h1>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <app-date-picker-wrapper
        [(ngModel)]="startDate"
        placeHolder="{{ startObsDate }}"
        (ngModelChange)="filterSynthesis()"
      ></app-date-picker-wrapper>
      <app-date-picker-wrapper
        [(ngModel)]="endDate"
        placeHolder="{{ endObsDate }}"
        (ngModelChange)="filterSynthesis()"
      ></app-date-picker-wrapper>
    </div>
    <div *ngIf="projectSupervisors.length"
         fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="10px"
    >
      <mat-form-field appearance="outline">
        <mat-select
          placeholder='{{ "SYNTHESIS.FILTERS.PROJECTSUPERVISOR" | translate }}'
          [(ngModel)]="selectedSupervisor"
          (selectionChange)="filterSynthesis()"
        >
          <mat-option
            *ngFor="let supervisor of projectSupervisors"
            [value]="supervisor.name"
          >
            {{ supervisor.name }} ({{ supervisor.acronym }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-icon-button
        *ngIf="selectedSupervisor"
        (click)="clearSupervisor()"
        id="clear-supervisor-btn"
        class="modern-white-btn"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div id="graph-wrapper" #graphWrapper>
    <!-- RESOURCE PRESSURE  -->
    <!--

    <div *ngIf="options.target !== type.BAC" class="section">
      <p class="section-title">{{ 'SYNTHESIS.RESOURCE_PRESSURE.TITLE' | translate }}</p>
    </div>
    <app-graph-wrapper [source]="resourcePressureOptions" class="resource-pressure" *ngIf="options.target !== type.BAC"></app-graph-wrapper>
-->

    <!-- WATER PRODUCTION  -->
    <!--
    <div *ngIf="options.target !== type.SENSOR" class="section">
      <p class="section-title">{{ 'SYNTHESIS.WATER_PRODUCTION' | translate }}</p>
    </div>
    <div fxLayout="row" *ngIf="options.target !== type.SENSOR">
      <app-graph-wrapper [source]="waterProductionOptions" class="water-production" *ngIf="options.target === type.STRUCTURE"></app-graph-wrapper>
      <div>
        <h3 class="first">{{ 'SYNTHESIS.POPULATION_TITLE' | translate }}:</h3>
        <p class="description first">{{ 'SYNTHESIS.STRUCTURE.POPULATION_DESCRIPTION' | translate: {value: options.population ?? '--'} }}</p>
        <h3>{{ 'SYNTHESIS.STRUCTURE.VOLUME_TITLE' | translate }}</h3>
        <p class="description">{{ 'SYNTHESIS.STRUCTURE.VOLUME_DESCRIPTION' | translate: {value: volume, year} }}</p>
      </div>
    </div>
-->
    <!-- KEY FIGURES -->
    <div class="section">
      <p class="section-title">
          {{ "SYNTHESIS.KEY_FIGURES.TITLE" | translate }}
      </p>
    </div>
    <ng-container *ngIf="options.keyFigures !== null">
      <div class="key-figures">
        <div class="key-figures-item">
          <h1 class="figure">{{ options.keyFigures.nMolecules }}</h1>
          <p
            [innerHTML]="
              'SYNTHESIS.KEY_FIGURES.DETECTED_MOLECULES'
                | translate : { phyto: options.keyFigures.nMoleculesPhyto }
            "
          ></p>
        </div>
        <div class="key-figures-item">
          <h1 class="figure">{{ options.keyFigures.nAlerts }}</h1>
          <p
            [innerHTML]="
              'SYNTHESIS.KEY_FIGURES.ALERTS'
                | translate
                  : {
                      phyto:
                        options.keyFigures.nAlertsPhyto -
                        options.keyFigures.nAlertsSumPesticides,
                      pesticides: options.keyFigures.nAlertsSumPesticides
                    }
            "
          ></p>
        </div>
        <div class="key-figures-item">
          <h1 class="figure">{{ options.keyFigures.nAlertsNitrates }}</h1>
          <p [innerHTML]="'SYNTHESIS.KEY_FIGURES.NITRATES' | translate"></p>
        </div>
      </div>
    </ng-container>

    <!-- NITRATES  -->
    <div class="section">
      <p class="section-title">{{ "SYNTHESIS.NITRATES" | translate }}</p>
    </div>

    <mat-grid-list [cols]="graphColumns" rowHeight="504px">
      <mat-grid-tile
        [rowspan]="1"
        [colspan]="1"
        *ngIf="options.target === type.STRUCTURE"
      >
        <app-graph-wrapper
          [source]="nitrateP90Options"
          info="{{ 'SYNTHESIS.NP90.INFO' | translate }}"
          class="nitrate-p90"
          [mouseoverCallback]="structureNitratesCallback"
        ></app-graph-wrapper>
      </mat-grid-tile>
      <!--<mat-grid-tile [rowspan]="1" [colspan]="1" *ngIf="options.target === type.STRUCTURE">
        <div class="nitrate-details">
          <h1>{{nitrateDetailsParams.year}}</h1>
          <h1>{{nitrateDetailsParams.level}}</h1>
        </div>
      </mat-grid-tile>-->

      <ng-container *ngIf="options.target === type.BAC">
        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="sensorNitratesOptions"
            info="{{ 'SYNTHESIS.BAC_NITRATES.INFO' | translate }}"
            class="nitrate-p90"
          ></app-graph-wrapper>
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="nitrateP90Options"
            info="{{ 'SYNTHESIS.BAC_NITRATES_BAR.INFO' | translate }}"
            class="nitrate-p90"
          ></app-graph-wrapper>
        </mat-grid-tile>
      </ng-container>

      <ng-container *ngIf="options.target === type.SENSOR">
        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="nitratesOptions"
            info="{{ 'SYNTHESIS.SENSOR_NITRATES.INFO' | translate }}"
            class="mp-type"
          ></app-graph-wrapper>
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="nitrateGaugeOptions"
            info="{{ 'SYNTHESIS.SENSOR_NITRATES.GAUGE_INFO' | translate }}"
            class="mp-type"
          ></app-graph-wrapper>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>

    <!-- MICROPOLLUTANTS AND PHYTOSANITARY SUBSTANCES  -->
    <div class="section">
      <p class="section-title">{{ "SYNTHESIS.MP_AND_PS" | translate }}</p>
    </div>

    <mat-grid-list [cols]="graphColumns" rowHeight="504px">
      <ng-container *ngIf="options.target !== type.BAC">
        <mat-grid-tile
          [rowspan]="1"
          [colspan]="1"
          *ngIf="options.target === type.STRUCTURE"
        >
          <app-graph-wrapper
            [source]="waterChemistryOptions"
            info="{{ 'SYNTHESIS.WATERCHEMISTRY.INFO' | translate }}"
            [graphConfig]="waterChemistryGraphConfig"
            class="mp-type"
          ></app-graph-wrapper>
        </mat-grid-tile>
        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="mpOptions"
            info="{{ 'SYNTHESIS.MP.INFO' | translate }}"
            [graphConfig]="mpGraphConfig"
            class="mp"
          ></app-graph-wrapper>
        </mat-grid-tile>
      </ng-container>
      <ng-container *ngIf="options.target === type.STRUCTURE">
        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="psTypeOptions"
            info="{{ 'SYNTHESIS.PSTYPE.INFO' | translate }}"
            [graphConfig]="psTypeGraphConfig"
            class="ps-type"
          ></app-graph-wrapper>
        </mat-grid-tile>

        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="structurePsOptions"
            info="{{ 'SYNTHESIS.PS.INFO' | translate }}"
            class="ps"
          ></app-graph-wrapper>
        </mat-grid-tile>
      </ng-container>
      <ng-container *ngIf="options.target === type.SENSOR">
        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="sensorPsOptions"
            info="{{ 'SYNTHESIS.MP.INFO' | translate }}"
            [graphConfig]="sensorPsGraphConfig"
            class="ps"
          ></app-graph-wrapper>
        </mat-grid-tile>
        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="seasonalityOptions"
            info="{{ 'SYNTHESIS.MPPS_BAC.INFO' | translate }}"
            class="seasonality"
          ></app-graph-wrapper>
        </mat-grid-tile>
      </ng-container>
      <ng-container *ngIf="options.target === type.BAC">
        <!--      <app-graph-wrapper [source]="substanceSummary" [graphConfig]="sensorPsGraphConfig" class="ps"></app-graph-wrapper>-->
        <mat-grid-tile [rowspan]="1" [colspan]="1">
          <app-graph-wrapper
            [source]="seasonalityOptions"
            info="{{ 'SYNTHESIS.MPPS_BAC.INFO' | translate }}"
            class="seasonality"
          ></app-graph-wrapper>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>

    <!-- LAND USE  -->
    <!--
    <div *ngIf="options.target === type.BAC" class="section">
      <p class="section-title">{{ 'SYNTHESIS.LAND_USE.TITLE' | translate }}</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="options.target === type.BAC">
      <app-graph-wrapper [source]="landUseOptions" class="mp-type"></app-graph-wrapper>
      <app-graph-wrapper [source]="agriculturalUseOptions" class="mp"></app-graph-wrapper>
    </div>
-->
    <!-- TURBIDITY  -->
    <!--
    <div *ngIf="options.target !== type.STRUCTURE" class="section">
      <p class="section-title">{{'SYNTHESIS.TURBIDITY' | translate | capitalizeFirst}}</p>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="options.target === type.SENSOR">
        <p class="turbidity-desc">{{'SYNTHESIS.N_OF_ALERTS' | translate | capitalizeFirst}}: {{options.turbidity | async}}</p>
      </div>
      <ng-container *ngIf="options.target === type.BAC">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngFor="let t of options.turbidity | async">
          <p class="turbidity-desc">{{'SYNTHESIS.N_OF_ALERTS' | translate | capitalizeFirst}} <span class="sensor-name">{{t.sensorName}}</span>: {{t.alerts}}</p>
          <img src="/assets/icon_alertes.svg" alt="Alerte" (click)="goToAlert(t.sensorId)" class="download-icon">
        </div>
      </ng-container>
    </div>
-->
  </div>
</div>
