import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Resolve, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, mergeMap, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { DashboardResolverData } from '../shared/resolver-data.model';
import { TranslateService } from '@ngx-translate/core';
import { AppInitializerService } from '../services/app-initializer.service';
import { DataTarget, DataType } from '../shared/enums.model';
import { Tree } from '../shared/tree.model';
import { UtilsService } from '../services/utils.service';
import { AlertsService } from '../services/alerts.service';
import { MeasuresService } from '../services/measures.service';
import { SynthesisService } from '../services/synthesis.service';
import { SensorFiltersService } from '../services/sensor-filters.service';
import { Structure } from '../shared/structure.model';

@Injectable({
  providedIn: 'root'
})
export class StructureDashboardResolver implements Resolve<DashboardResolverData> {

  private readonly GRAPHS_PER_LINE: number;
  private readonly PAGE_SIZE: number;

  constructor(private apiService: ApiService, private authService: AuthService, private translateService: TranslateService,
              private utilsService: UtilsService, private alertsService: AlertsService,
              private measuresService: MeasuresService, private synthesisService: SynthesisService, private sensorFiltersService: SensorFiltersService) {
    this.GRAPHS_PER_LINE = AppInitializerService.CONFIG.measuresGraphsPerLine;
    this.PAGE_SIZE = AppInitializerService.CONFIG.measuresPageSize;
  }

  private getObservables(type: DataType, structureId: number | 'all', summary: Structure, tree: Tree | undefined, params: Params | undefined): Observable<DashboardResolverData> {
    switch (type) {
      case DataType.SYNTHESIS:
        return this.synthesisService.getResolverObservable({ structureId, summary, tree, target: DataTarget.STRUCTURE, params });
      case DataType.ALERTS:
        return this.alertsService.getResolverObservable({ structureId, params, target: DataTarget.STRUCTURE });
      case DataType.MEASURES:
        const name = summary.name;
        return this.measuresService.getResolverObservable({ structureAcronym: summary.acronyme, structureId, params, graphsPerLine: this.GRAPHS_PER_LINE, pageSize: this.PAGE_SIZE, name, target: DataTarget.STRUCTURE });
      case DataType.SENSORS_MAP:
        return this.sensorFiltersService.getResolverObservable({ params, name: summary.name, structureId, target: DataTarget.STRUCTURE }, false);
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DashboardResolverData> {
    let structureId: number | 'all';
    if (route.params['id'] === undefined) {
      structureId = this.authService.structure.id;
    } else if (route.params['id'] === 'all') {
      structureId = 'all';
    } else {
      structureId = Number(route.params['id']);
    }
    const params: Params = route.queryParams;
    const type = UtilsService.getDataTypeFromUrl(route.url);
    const structureObs = structureId !== 'all' ? this.apiService.structures.getById(Number(structureId)) : this.apiService.structures.getCurrentStructure();
    //useful when a structure is selected. When undefined, gets the defaultStructure
    return this.utilsService.wrapObservable(structureObs, undefined,'ERROR_MSG.GET_STRUCTURE')
      .pipe(
        mergeMap( res => {
          const structureName = structureId === 'all' ? this.translateService.instant('HEADER.ALL_STRUCTURES') : res.name;
          const tree = { structureId, structureName };
          return this.getObservables(type, structureId, res, tree, params);
        }),
        catchError(() => EMPTY)
      );
  }
}
