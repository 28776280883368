import {
  Component,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { DataTarget } from '../../../../shared/enums.model';
import { AlertDataSource } from '../../classes/alert-data-source';
import { AlertsResolverData } from '../../../../shared/resolver-data.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ApiService } from '../../../../services/api.service';
import { UtilsService } from '../../../../services/utils.service';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap, Observable, of, Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BatchUpdateDialogComponent } from '../batch-update-dialog/batch-update-dialog.component';
import { AuthService } from '../../../../services/auth.service';
import { AlertItemComponent } from '../alert-item/alert-item.component';
import { AlertsService } from '../../../../services/alerts.service';
import { Sort } from '@angular/material/sort';
import { AlertBatch } from '../../../../shared/@types/alert/alert-types';
import { AlertUpdatedEvent } from '../../../../shared/@types/alert/alert-filters';

@Component({
  selector: 'app-alerts-data',
  templateUrl: './alerts-data.component.html',
  styleUrls: ['./alerts-data.component.scss']
})
export class AlertsDataComponent implements OnInit, OnChanges, OnDestroy {


  @ViewChild(CdkVirtualScrollViewport) virtualScroll!: CdkVirtualScrollViewport;
  @ViewChildren(AlertItemComponent) alertItems!: QueryList<AlertItemComponent>;
  @Input() options!: AlertsResolverData;
  title!: string;
  currentAlerts!: number;
  archivedAlerts!: number;
  currentAlertsMsg!: string;
  archivedAlertsMsg!: string;
  ds!: AlertDataSource;
  itemSize = 80;
  allAlertsSelected = false;
  alertSelectionIndeterminate = false;
  selectedAlerts: Set<number> = new Set();
  canBatchUpdate = false;
  canValidateAlerts = false;
  sortedData?: AlertDataSource;
  private destroy$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private authService: AuthService,
    private alertsService: AlertsService) {
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['options'].firstChange) {
      this.resetVirtualScroll();
      this.initComponent();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAlertUpdated(event: AlertUpdatedEvent): void {
    if (event.oldVal !== event.newVal) {
      this.resetAfterUpdate();
    }
  }

  public subscribe() {
    this.utilsService.wrapObservable(
      this.apiService.alerts.subscribe(this.options.alertSubscription),
      'SUCCESS_MSG.ALERT_SUBSCRIPTION', 'ERROR_MSG.ALERT_SUBSCRIPTION'
    ).pipe(takeUntil(this.destroy$))
      .subscribe({
        error: err => {
          console.error(err);
        }
      });
  }

  public download(): void {
    this.alertsService.getAlerts(this.options).pipe(takeUntil(this.destroy$)).subscribe({
      next: alerts => {
        const date = this.translateService.instant('DATE');
        const station = this.translateService.instant('ACCOUNT.ALERT_SUBSCRIPTIONS.SENSOR');
        const substance = this.translateService.instant('ACCOUNT.SHARED_SUBSTANCES.SUBSTANCE');
        const status = this.translateService.instant('ALERTS.ATTRIBUTE_QUALIFICATION');
        const type = this.translateService.instant('ALERTS.ATTRIBUTE_TYPE');
        const value = this.translateService.instant('VALUE');
        const threshold = this.translateService.instant('MEASURES.THRESHOLD');
        const comment = this.translateService.instant('ALERTS.TITLE_COMMENT');
        const header = `${date}, ${station}, ${substance}, ${status}, ${type}, ${value}, ${threshold}, ${comment}\n`;
        const data = alerts
          .map(alert => {
            const status = this.translateService.instant('ALERTS.QUALIFICATION.' + alert.qualification);
            const comment = alert.comment != undefined ? alert.comment.replace(/\n/g, ' ') : '';
            const params: Array<string> = [
              new Date(alert.sampleDate * 1000).toISOString(),
              alert.sensorName,
              alert.paramName,
              status,
              alert.value + alert.thresholdUom,
              alert.threshold + alert.thresholdUom,
              comment
            ];
            return params.join(',');
          });
        const file = new File([header + data.join('\n')], 'Aqualit_alerts.csv', { type: 'text/csv;charset=utf-8' });
        saveAs(file);
      }
    });
  }

  private initComponent(): void {
    this.currentAlerts = this.options.new;
    this.archivedAlerts = this.options.archived;
    this.alertsService.alertCounts$.pipe(takeUntil(this.destroy$)).subscribe((counts) => {
      this.currentAlerts = counts.new;
      this.archivedAlerts = counts.archived;
    });
    this.canValidateAlerts = this.authService.isAdmin;
    this.canBatchUpdate = false;
    this.allAlertsSelected = false;
    this.alertSelectionIndeterminate = false;
    this.selectedAlerts.clear();
    this.setTitles();
    this.ds = new AlertDataSource(this.options.alertDatasourceConfig, this.options.params);
    switch (this.options.alertDatasourceConfig.target) {
      case DataTarget.STRUCTURE:
        this.title = 'ALERTS.TITLE_STRUCTURE';
        break;
      case DataTarget.BAC:
        this.title = 'ALERTS.TITLE_BAC';
        break;
      case DataTarget.SENSOR:
        this.title = 'ALERTS.TITLE_SENSOR';
        break;
    }
  }

  private resetVirtualScroll(): void {
    // If we do not switch off appendOnly option, Material will force the range to the highest value. So to force it to our
    // desired range we have to switch it off and on again.
    this.virtualScroll.appendOnly = false;
    this.virtualScroll.setRenderedRange({ start: 0, end: 0 });
    this.virtualScroll.appendOnly = true;

    // Before creating the new dataSource we empty the Virtual Scroll by calling the disconnect method
    this.ds.disconnect();
  }

  private setTitles(): void {
    this.currentAlertsMsg = this.currentAlerts <= 1 ? 'ALERTS.CURRENT.SINGULAR' : 'ALERTS.CURRENT.PLURAL';
    this.archivedAlertsMsg = this.archivedAlerts <= 1 ? 'ALERTS.ARCHIVED.SINGULAR' : 'ALERTS.ARCHIVED.PLURAL';
  }

  public updateAllAlertSelection($event: boolean): void {
    this.allAlertsSelected = $event;
    if ($event) {
      this.canBatchUpdate = true;
    } else {
      this.selectedAlerts.clear();
      this.canBatchUpdate = false;
    }
  }

  public onAlertSelectionChanged($event: boolean, id: number): void {
    if ($event) {
      this.selectedAlerts.add(id);
    } else {
      // Before deselecting the alert we check if the "select-all" was selected
      if (this.allAlertsSelected) {
        // It is possible that we have scrolled, in this case we have to add all alert ids before deselecting the current one
        for (const a of this.ds.loadedData) {
          this.selectedAlerts.add(a.id);
        }
      }
      this.selectedAlerts.delete(id);
    }
    if (this.selectedAlerts.size === 0) {
      this.allAlertsSelected = false;
      this.alertSelectionIndeterminate = false;
    } else {
      if (this.selectedAlerts.size === this.currentAlerts + this.archivedAlerts) {
        this.allAlertsSelected = true;
        this.alertSelectionIndeterminate = false;
      } else {
        this.allAlertsSelected = false;
        this.alertSelectionIndeterminate = true;
      }
    }
    this.canBatchUpdate = this.allAlertsSelected || this.selectedAlerts.size > 0;
  }

  public batchUpdate(): void {
    const observable: Observable<Array<number>> = this.allAlertsSelected ?
      this.alertsService.getAlerts(this.options)
        .pipe(
          map(alerts => alerts.filter(a => a.structureId === this.authService.structure.id).map(a => a.id))
        ) : of(Array.from(this.selectedAlerts.keys()));
    observable
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(ids => {
          const dialog = this.dialog.open(BatchUpdateDialogComponent, {
            height: '320px',
            width: '400px',
            data: { ids }
          });
          return dialog.afterClosed();
        })
      )
      .subscribe({
        next: (value: AlertBatch | undefined) => {
          if (value != undefined) {
            this.resetAfterUpdate();
          }
        }
      });
  }

  sortData(sort: Sort): void {
      this.resetVirtualScroll();
      this.options.alertDatasourceConfig.sortDirection = sort.direction;
      this.options.alertDatasourceConfig.orderBy = sort.active;
      this.ds = new AlertDataSource(this.options.alertDatasourceConfig, this.options.params);
  }

  private resetAfterUpdate(): void {
    this.alertsService.alertUpdated$.next();
    this.setTitles();
    this.resetVirtualScroll();
    this.allAlertsSelected = false;
    this.alertSelectionIndeterminate = false;
    this.selectedAlerts.clear();
    this.ds = new AlertDataSource(this.options.alertDatasourceConfig, this.options.params);
  }
}
