import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { AlertsControlsComponent } from './components/alerts-controls/alerts-controls.component';
import { AlertsDataComponent } from './components/alerts-data/alerts-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { AlertItemComponent } from './components/alert-item/alert-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { GraphWrapperModule } from '../graph-wrapper/graph-wrapper.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DatePickerWrapperModule } from '../date-picker-wrapper/date-picker-wrapper.module';
import { SensorThingsModule } from '@geomatys/ngx-core/sensor-things';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BatchUpdateDialogComponent } from './components/batch-update-dialog/batch-update-dialog.component';
import { ValidationFormComponent } from './components/validation-form/validation-form.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { PipesModule } from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    AlertsComponent,
    AlertsControlsComponent,
    AlertsDataComponent,
    AlertItemComponent,
    BatchUpdateDialogComponent,
    ValidationFormComponent
  ],
  imports: [
    CommonModule,
    DashboardModule,
    TranslateModule,
    MatIconModule,
    FlexModule,
    ReactiveFormsModule,
    MatSelectModule,
    GraphWrapperModule,
    ScrollingModule,
    MatCheckboxModule,
    FormsModule,
    DatePickerWrapperModule,
    SensorThingsModule.withConfig({ DEFAULT_PAGE_SIZE: 9 }),
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatSortModule,
    PipesModule
  ]
})
export class AlertsModule {
}
