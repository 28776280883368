import { Component } from '@angular/core';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { NavigationEnd, Router, UrlSegment } from '@angular/router';
import { AuthService } from './services/auth.service';
import { filter, forkJoin, map, mergeMap, Observable, of, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from './services/api.service';
import { UtilsService } from './services/utils.service';
import { Tree } from './shared/tree.model';
import { BacSummary } from './shared/bac.model';
import { SensorSummary } from './shared/sensor.model';
import { ViewerService } from './services/viewer.service';
import { Structure } from './shared/structure.model';

type ParsedUrl = {
  route: string;
  target: string;
  id: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title!: string;
  public faCircleUser = faCircleUser;
  public faArrowRightLong = faArrowRightLong;
  public isAuthenticated = false;
  public isAdmin = false;
  public isAdminRoute = false;
  public usrLogin = '';
  public sensorTree!: Tree;
  public structureOptions: Pick<Structure, 'id' | 'name' | 'geometry' | 'geometryContentType'>[] = [];
  public bacOptions: Pick<BacSummary, 'id' | 'bacId' | 'name' | 'geometry' | 'geometryContentType'>[] = [];
  public sensorOptions: Pick<SensorSummary, 'id' | 'sensorId' | 'name' | 'lon' | 'lat'>[] = [];
  private _selectedRoute = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private apiService: ApiService,
    private viewerService: ViewerService) {

    UtilsService.registerIcons(iconRegistry, sanitizer);

    this.authService.authenticationChange.subscribe(value => {
      this.isAuthenticated = value;
      if (value) {
        this.usrLogin = this.authService.user.login;
        this.title = this.authService.structure.name;
        this.isAdmin = this.authService.isAdmin;
      } else {
        this.isAdmin = false;
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe({
        next: (res) => {
          const event = res as NavigationEnd;
          const segments = this.getUrlSegments(event.url);
          const parsed = this.parseUrlSegments(segments);
          this._selectedRoute = parsed.route;

          this.isAdminRoute = parsed.route === 'administration' || parsed.route === 'account';
          if (this.isAdminRoute) {
            let key = '';
            switch (parsed.target) {
              case 'structures':
                key = 'ADMIN.STRUCTURES';
                break;
              case 'users':
                key = 'ADMIN.USERS';
                break;
              default:
                key = 'ADMIN.ACCOUNT';
                break;
            }
            this.translateService.get(key)
              .subscribe({
                next: (value: string) => this.title = value
              });
          } else {
            this.updateTreeAndName(parsed);
          }
        }
      });
  }

  navigateTo(route: string) {
    if (route.indexOf('administration') === -1 && route.indexOf('account') === -1) {
      route = this.buildRoute(route);
    }
    this.router.navigate([route]).then();
  }

  logout() {
    const origin = window.location.origin;
    window.location.href = origin + '/proxy/oauth2/logout?state=' + origin;
  }

  onStructureSelected($event: string) {
    let navigate;
    if ($event !== undefined) {
      const struct = this.structureOptions.find(s => s.name === $event)!;
      navigate = this.sensorTree.sensorId !== undefined || this.sensorTree.bacId !== undefined || struct.id !== this.sensorTree.structureId;
      this.sensorTree = { structureId: struct.id, structureName: struct.name };
    } else {
      navigate = this.sensorTree.sensorId !== undefined || this.sensorTree.bacId !== undefined || this.authService.structure.id !== this.sensorTree.structureId;
      this.sensorTree = { structureId: this.authService.structure.id, structureName: this.authService.structure.name };
    }
    if (navigate) {
      this.navigateTo(this._selectedRoute);
    }
  }

  onBacSelected($event: string) {
    if (this.sensorTree) {
      const bac = this.bacOptions.find(b => b.name === $event)!;
      this.sensorTree.bacId = bac.bacId;
      this.sensorTree.bacName = bac.name;
      this.sensorTree.sensorId = undefined;
      this.sensorTree.sensorName = undefined;
      if (bac) {
        this.navigateTo(this._selectedRoute);
      }
    }
  }

  onSensorSelected($event: string) {
    if (this.sensorTree) {
      const sensor = this.sensorOptions.find(s => s.name === $event)!;
      this.sensorTree.sensorId = sensor.sensorId;
      this.sensorTree.sensorName = sensor.name;
      if (sensor) {
        this.navigateTo(this._selectedRoute);
      }
    }
  }

  private getUrlSegments(url = this.router.url): Array<UrlSegment> {
    const treeUrl = this.router.parseUrl(url);
    const children = treeUrl.root.children;
    const segments: Array<UrlSegment> = [];
    if (children['primary'] !== undefined) {
      const paths = children['primary'].segments;
      segments.push(...paths);
    }
    return segments;
  }

  private parseUrlSegments(segments: Array<UrlSegment>): ParsedUrl {
    let route = '';
    let target = '';
    let id = '';

    if (segments.length === 1) {
      route = segments[0].path;
    } else if (segments.length === 2) {
      if (segments[0].path === 'administration') {
        route = 'administration';
        target = segments[1].path;
      } else {
        target = segments[0].path;
        id = segments[1].path;
      }
    } else if (segments.length === 3) {
      route = segments[0].path;
      target = segments[1].path;
      id = segments[2].path;
    }
    return { route, target, id };
  }

  private updateTreeAndName(parsedUrl: ParsedUrl): void {

    const target = parsedUrl.target as 'structure' | 'bac' | 'sensor';
    const id = parsedUrl.id;

    const getObservable = (target: 'structure' | 'bac' | 'sensor', id: string): Observable<Tree> => {

      switch (target) {
        case 'sensor':
          return this.apiService.sensors.summary(id)
            .pipe(
              map(res => UtilsService.sensorSummaryToTree(res))
            );
        case 'bac':
          return this.apiService.bac.summary(id)
            .pipe(
              map(res => UtilsService.bacSummaryToTree(res))
            );
        case 'structure':
          if (id === 'all') {
            return of({ structureId: 'all', structureName: this.translateService.instant('HEADER.ALL_STRUCTURES') });
          } else {
            const sid = Number(id);
            return this.apiService.structures.getById(sid)
              .pipe(
                map(res => UtilsService.structureSummaryToTree(res))
              );
          }
      }
    };

    getObservable(target, id)
      .pipe(
        mergeMap(res => {
          this.sensorTree = res;
          let observable: Observable<boolean> = of(true);
          const structureObservable = parsedUrl.route === 'synthesis' ? of([this.authService.structure]) : this.apiService.structures.getAvailable();
          switch (target) {
            case 'structure':
              const structureId = id === 'all' ? undefined : Number(id);
              const structBacObservable = this.apiService.bac.summariesByStructure(structureId);
              const structSensorObservable = this.apiService.sensors.summariesByStructure(structureId);
              observable = forkJoin([structureObservable, structBacObservable, structSensorObservable])
                .pipe(
                  tap(res => {
                    this.structureOptions = res[0].map(s => ({ name: s.name, id: s.id, geometry: s.geometry, geometryContentType: s.geometryContentType }));
                    /*                    if (res[0].length > 1) {
                                          this.structureOptions.unshift({ name: this.translateService.instant('HEADER.ALL_STRUCTURES'), id: 'all' });
                                        }*/
                    this.bacOptions = res[1].map(bac => ({ name: bac.name, id: bac.id, bacId: bac.bacId, geometry: bac.geometry, geometryContentType: bac.geometryContentType}));
                    this.sensorOptions = res[2].map(sensor => ({ name: sensor.name, id: sensor.id, sensorId: sensor.sensorId, lon: sensor.lon, lat: sensor.lat }));
                    const currentStructure = this.structureOptions.find(s => s.id === structureId)!;
                    this.viewerService.flyToEntityGeometry(currentStructure);
                  }),
                  map(() => true)
                );
              break;
            case 'bac':
              const bacBacObservable: Observable<BacSummary[]> = this.apiService.bac.summary(id)
                .pipe(
                  mergeMap((bacRes) => this.apiService.bac.summariesByStructure(bacRes.structureId))
                );
              const bacSensorObservable: Observable<SensorSummary[]> = this.apiService.sensors.summariesByBac(id!);

              observable = forkJoin([structureObservable, bacBacObservable, bacSensorObservable])
                .pipe(
                  tap(res => {
                    this.structureOptions = res[0].map(s => ({ name: s.name, id: s.id, geometry: s.geometry, geometryContentType: s.geometryContentType}));
                    /*                    if (res[0].length > 1) {
                                          this.structureOptions.unshift({ name: this.translateService.instant('HEADER.ALL_STRUCTURES'), id: 'all' });
                                        }*/
                    this.bacOptions = res[1].map(bac => ({ name: bac.name, id: bac.id, bacId: bac.bacId, geometry: bac.geometry, geometryContentType: bac.geometryContentType }));
                    this.sensorOptions = res[2].map(sensor => ({ name: sensor.name, id: sensor.id, sensorId: sensor.sensorId, lon: sensor.lon, lat: sensor.lat }));

                    const currentBac = this.bacOptions.find(bac => bac.bacId === id)!;
                    this.viewerService.flyToEntityGeometry(currentBac);
                  }),
                  map(() => true)
                );
              break;
            case 'sensor':
              const sensorIdObservable: Observable<SensorSummary> = this.apiService.sensors.summary(this.sensorTree.sensorId!);

              observable = forkJoin([structureObservable, sensorIdObservable])
                .pipe(
                  mergeMap(res => {
                    this.structureOptions = res[0].map(s => ({ name: s.name, id: s.id, geometry: s.geometry }));
                    /*                    if (res[0].length > 1) {
                                          this.structureOptions.unshift({ name: this.translateService.instant('HEADER.ALL_STRUCTURES'), id: 'all' });
                                        }*/
                    const allBacObservable: Observable<BacSummary[]> = this.apiService.bac.summariesByStructure(res[1].structureId);
                    let sensorObservable: Observable<SensorSummary[]>;

                    if (res[1].bacName != undefined && res[1].bacId != undefined) {
                      sensorObservable = this.apiService.sensors.summariesByBac(res[1].bacId);
                    } else {
                      sensorObservable = this.apiService.sensors.summariesByStructure(res[1].structureId);
                    }
                    return forkJoin([sensorObservable, allBacObservable])
                      .pipe(
                        tap(res => {
                          this.bacOptions = res[1].map(bac => ({ name: bac.name, id: bac.id, bacId: bac.bacId, geometry: bac.geometry, geometryContentType: bac.geometryContentType }));
                          this.sensorOptions = res[0].map(sensor => ({ name: sensor.name, id: sensor.id, sensorId: sensor.sensorId, lon: sensor.lon, lat: sensor.lat }));
                          const currentSensor = this.sensorOptions.find(sensor => sensor.sensorId === id)!;
                          this.viewerService.flyToEntityGeometry(currentSensor);
                        })
                      );
                  }),
                  map(() => true)
                );
              break;
          }
          return observable;
        }),
        mergeMap(() => {
          return this.apiService.structures.getById(this.authService.user.structureId);
        })
      )
      .subscribe({
        next: res => {
          this.title = res.name;
        }
      });
  }

  private buildRoute(baseRoute: string): string {
    let route = baseRoute;
    if (this.sensorTree?.sensorId !== undefined) {
      route += `/sensor/${this.sensorTree.sensorId}`;
    } else if (this.sensorTree?.bacId !== undefined) {
      route += `/bac/${this.sensorTree.bacId}`;
    } else if (this.sensorTree?.structureId !== undefined) {
      route += `/structure/${this.sensorTree.structureId}`;
    }
    return route;
  }

  public openExamind(): void {
    const origin = window.location.origin;
    window.open(origin + '/examind/admin.html');
  }
}
