import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Alert, AlertQualification } from '../shared/alert.model';
import { forkJoin, map, Observable, of, Subject } from 'rxjs';
import { PageableOptions } from '../shared/pageable';
import { HttpResponse } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { DataTarget, DataType } from '../shared/enums.model';
import { AlertDataSourceConfig } from '../shared/@types/alert/alert-data-source-config';
import { SensorThingsService, ThingEntity } from '@geomatys/ngx-core/sensor-things';
import { AlertsResolverConfig, AlertsResolverData } from '../shared/resolver-data.model';
import { AuthService } from './auth.service';
import { DateRange } from '../shared/date-range';
import { TranslateService } from '@ngx-translate/core';
import { RegisterSensorFilter } from '../shared/sensor.model';
import { AlertSearchFilter } from '../shared/@types/alert/alert-filters';
import { AlertStats, AlertSubscription } from '../shared/@types/alert/alert-types';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  alertUpdated$ = new Subject<void>();
  alertCounts$: Subject<{ new: number; archived: number }> = new Subject();

  private readonly firstPageOptions: PageableOptions = {
    offset: 0,
    pageNumber: 0,
    pageSize: 1
  };

  constructor(private apiService: ApiService, private utilsService: UtilsService, private stsService: SensorThingsService,
              private authService: AuthService, private translateService: TranslateService) {
  }

  public getAll(pageableOptions: PageableOptions, filters?: AlertSearchFilter): Observable<Array<Alert>> {
    const obs = this.apiService.alerts.getAll(pageableOptions, filters)
      .pipe(
        map(res => res.body ?? [])
      );
    return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.GET_ALERTS', []);
  }

  public getByStructureId(structureId: number, pageableOptions: PageableOptions, filters?: AlertSearchFilter): Observable<Array<Alert>> {
    const obs = this.apiService.alerts.getByStructureId(structureId, pageableOptions, filters)
      .pipe(
        map(res => res.body ?? [])
      );
    return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.GET_ALERTS', []);
  }

  public getByBacId(bacId: string, pageableOptions: PageableOptions, filters?: AlertSearchFilter): Observable<Array<Alert>> {
    const obs = this.apiService.alerts.getByBacId(bacId, pageableOptions, filters)
      .pipe(
        map(res => res.body ?? [])
      );
    return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.GET_ALERTS', []);
  }

  public getBySensorId(sensorId: string, pageableOptions: PageableOptions, filters?: AlertSearchFilter): Observable<Array<Alert>> {
    filters = this.setSensorIdsFilters(sensorId, filters);
    const obs = this.apiService.alerts.getBySensorId(pageableOptions, filters)
      .pipe(
        map(res => res.body ?? [])
      );
    return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.GET_ALERTS', []);
  }

  public getThingEntityForAlert(sensorCode: string): Observable<ThingEntity> {
    const url = './proxy/sts/v1.1';
    return this.stsService.createThingEntity(url, sensorCode, true, undefined);
  }

  public getStatistics(config: AlertDataSourceConfig, filters?: AlertSearchFilter): Observable<AlertStats> {
    let newAlertsFilter: AlertSearchFilter = { qualification: [AlertQualification.NEW] };
    let archivedAlertsFilter: AlertSearchFilter = {
      qualification: [AlertQualification.ABERENT_DATA,
        AlertQualification.HEALTH_ALERT,
        AlertQualification.SAMPLE_ERROR,
        AlertQualification.THRESHOLD_ALERT,
        AlertQualification.UNCERTAIN_DATA]
    };

    let searchNewAlerts = true;
    let searchArchivedAlerts = true;
    if (filters?.qualification !== undefined) {
      if (Array.isArray(filters.qualification)) {
        searchNewAlerts = filters.qualification.indexOf(AlertQualification.NEW) !== -1;
        archivedAlertsFilter = {};
        const qualification = filters.qualification.filter(q => q !== AlertQualification.NEW);
        if (qualification.length > 0) {
          archivedAlertsFilter.qualification = qualification;
        } else {
          searchArchivedAlerts = false;
        }
      } else {
        if (filters.qualification !== AlertQualification.NEW) {
          searchNewAlerts = false;
          archivedAlertsFilter.qualification = [filters.qualification];
        } else {
          searchArchivedAlerts = false;
        }
      }
    }

    if (filters?.startDate) {
      newAlertsFilter.startDate = filters.startDate;
      archivedAlertsFilter.startDate = filters.startDate;
    }

    if (filters?.endDate) {
      newAlertsFilter.endDate = filters.endDate;
      archivedAlertsFilter.endDate = filters.endDate;
    }

    if (filters?.sensorIds) {
      newAlertsFilter.sensorIds = filters.sensorIds;
      archivedAlertsFilter.sensorIds = filters.sensorIds;
    }

    if (filters?.paramCodes) {
      newAlertsFilter.paramCodes = filters.paramCodes;
      archivedAlertsFilter.paramCodes = filters.paramCodes;
    }

    let obsNewAlerts: Observable<HttpResponse<Array<Alert>> | undefined> = of(undefined);
    let obsArchivedAlerts: Observable<HttpResponse<Array<Alert>> | undefined> = of(undefined);

    if (searchNewAlerts) {
      switch (config.target) {
        case DataTarget.STRUCTURE:
          obsNewAlerts = config.id ? this.apiService.alerts.getByStructureId(config.id, this.firstPageOptions, newAlertsFilter) :
            this.apiService.alerts.getAll(this.firstPageOptions, newAlertsFilter);
          break;
        case DataTarget.BAC:
          obsNewAlerts = this.apiService.alerts.getByBacId(config.id, this.firstPageOptions, newAlertsFilter);
          break;
        case DataTarget.SENSOR:
          newAlertsFilter = this.setSensorIdsFilters(config.id, newAlertsFilter);
          obsNewAlerts = this.apiService.alerts.getBySensorId(this.firstPageOptions, newAlertsFilter);
          break;

      }
    }
    if (searchArchivedAlerts) {
      switch (config.target) {
        case DataTarget.STRUCTURE:
          obsArchivedAlerts = config.id ? this.apiService.alerts.getByStructureId(config.id, this.firstPageOptions, archivedAlertsFilter) :
            this.apiService.alerts.getAll(this.firstPageOptions, archivedAlertsFilter);
          break;
        case DataTarget.BAC:
          obsArchivedAlerts = this.apiService.alerts.getByBacId(config.id, this.firstPageOptions, archivedAlertsFilter);
          break;
        case DataTarget.SENSOR:
          archivedAlertsFilter = this.setSensorIdsFilters(config.id, archivedAlertsFilter);
          obsArchivedAlerts = this.apiService.alerts.getBySensorId(this.firstPageOptions, archivedAlertsFilter);
          break;

      }
    }

    return forkJoin([obsNewAlerts, obsArchivedAlerts])
      .pipe(
        map(res => {
          const out: AlertStats = {
            new: 0,
            archived: 0
          };

          if (res[0]?.body) {
            out.new = Number(res[0].headers.get('X-Total-Count'));
          }

          if (res[1]?.body) {
            out.archived = Number(res[1].headers.get('X-Total-Count'));
          }

          return out;
        })
      );
  }

  public getResolverObservable(config: AlertsResolverConfig): Observable<AlertsResolverData> {
    let alertDatasourceConfig: AlertDataSourceConfig;
    let alertSubscription: AlertSubscription;
    const userId = this.authService.user.id;
    let dateRangeFilter: AlertSearchFilter = {};
    const sensorFilter: RegisterSensorFilter = {
      structIds: ('structureId' in config && config.structureId && config.structureId !== 'all') ? [config.structureId] : [],
      bacIds: ('bacId' in config && config.bacId) ? [config.bacId] : [],
      sensorIds: ('sensorId' in config && config.sensorId) ? [config.sensorId] : [],
    };

    switch (config.target) {
      case DataTarget.STRUCTURE:
        alertSubscription = { userId, structureId: config.structureId.toString() };
        const structureId = config.structureId === 'all' ? undefined : config.structureId;
        alertDatasourceConfig = {
          target: config.target,
          alertsService: this,
          length: 1,
          pageSize: 1,
          id: structureId
        };
        dateRangeFilter.structureId = structureId;
        break;
      case DataTarget.BAC:
        alertDatasourceConfig = {
          target: config.target,
          alertsService: this,
          length: 1,
          pageSize: 1,
          id: config.bacId
        };
        alertSubscription = { userId, bacId: config.bacId };
        dateRangeFilter.bacId = config.bacId;
        break;
      case DataTarget.SENSOR:
        alertDatasourceConfig = {
          target: config.target,
          alertsService: this,
          length: 1,
          pageSize: 1,
          id: config.sensorId
        };
        alertSubscription = { userId, sensorId: config.sensorId };
        dateRangeFilter = this.setSensorIdsFilters(config.sensorId, dateRangeFilter);
        break;
    }

    Object.assign(dateRangeFilter, config.params);

    const dateRangeDefault: DateRange = {
      minDate: this.translateService.instant('DATE_FROM'),
      maxDate: this.translateService.instant('DATE_TO')
    };
    const statsObs = this.utilsService.wrapObservable(this.getStatistics(alertDatasourceConfig, config.params), undefined, 'ERROR_MSG.GET_ALERTS');
    const dateRangObs = this.utilsService.wrapObservable(this.getDateRange(dateRangeFilter), undefined, undefined, dateRangeDefault);
    const sensorsObs = this.utilsService.wrapObservable(this.apiService.sensors.search(sensorFilter, { pageSize: 200000 }), undefined, 'ERROR_MSG.GET_SENSORS', []);
    const propertiesObs = this.utilsService.wrapObservable(this.apiService.properties.getAllForCurrentStructure(), undefined, 'ERROR_MSG.GET_ALERTS', []);

    return forkJoin([statsObs, dateRangObs, sensorsObs, propertiesObs])
      .pipe(
        map(res => {
          const stats = res[0];
          alertDatasourceConfig.length = stats.new + stats.archived;
          alertDatasourceConfig.pageSize = this.utilsService.CONFIG.alertsPageSize;
          return {
            type: DataType.ALERTS,
            alertDatasourceConfig,
            new: stats.new,
            archived: stats.archived,
            alertSubscription,
            params: config.params,
            dateRange: res[1],
            availableSensors: res[2],
            availableProperties: res[3],
          };
        })
      );
  }

  getAlerts(options?: AlertsResolverData): Observable<Array<Alert>> {
    let complement = {};
    if (options?.alertSubscription.structureId !== undefined) {
      complement = { structureId: +options.alertSubscription.structureId };
    }
    if (options?.alertSubscription.bacId !== undefined) {
      complement = { bacId: options.alertSubscription.bacId };
    }
    if (options?.alertSubscription.sensorId !== undefined) {
      complement = { sensorId: options.alertSubscription.sensorId };
    }

    const filter: AlertSearchFilter = Object.assign({}, options?.params ?? {}, complement);
    const observable = this.apiService.alerts.search({ pageSize: 20000 }, filter);

    return this.utilsService.wrapObservable(observable, undefined, 'ERROR_MSG.GET_ALERTS');
  }

  setSensorIdsFilters(sensorId: string, filters: AlertSearchFilter = {}): AlertSearchFilter {
    const f = {...filters};
    if (Array.isArray(f?.sensorIds)) {
      f?.sensorIds.push(sensorId);
    } else if (f?.sensorIds) {
      const sensorIds = f.sensorIds;
      f.sensorIds = [sensorIds, sensorId];
    } else {
      f.sensorIds = sensorId;
    }

    return f;
  }

  private getDateRange(filters?: AlertSearchFilter): Observable<DateRange> {
    return this.apiService.alerts.getDateRange(filters)
      .pipe(
        map(res => {
          return {
            minDate: res.minDate ? new Date(res.minDate).toLocaleDateString('fr-Fr') : this.translateService.instant('DATE_FROM'),
            maxDate: res.maxDate ? new Date(res.maxDate).toLocaleDateString('fr-Fr') : this.translateService.instant('DATE_TO')
          };
        })
      );
  }
}


