import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewerComponent } from './viewer.component';
import { CesiumContainerModule } from '@geomatys/ngx-cesium';
import { TimelineModule } from '@geomatys/ngx-cesium/layers';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../pipes/pipes.module';
import { LegendContainerModule } from '../legend-container/legend-container.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    ViewerComponent
  ],
    imports: [
        CommonModule,
        CesiumContainerModule,
        FlexModule,
        RouterModule,
        MatIconModule,
        TimelineModule,
        PipesModule,
        LegendContainerModule,
        BrowserAnimationsModule,
        MatTooltipModule,
    ],
  exports: [
    ViewerComponent
  ]
})
export class ViewerModule {
}
