import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AlertQualification } from '../../../../shared/alert.model';
import { DateTime } from 'luxon';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UtilsService } from '../../../../services/utils.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  combineLatest,
  distinctUntilChanged,
  first,
  map,
  Observable,
  of,
  skip,
  startWith,
  Subject,
  Subscription,
  takeUntil
} from 'rxjs';
import { SensorSummary } from '../../../../shared/sensor.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Property } from '../../../../shared/property.model';
import { ApiService } from '../../../../services/api.service';
import { AlertsService } from '../../../../services/alerts.service';
import {
  AlertsControlOptions,
  AlertSearchFilter,
  FilterFormValues,
  ParseFilterAccessor
} from '../../../../shared/@types/alert/alert-filters';
import { AlertsGuardState } from '../../../../shared/@types/alert/alert-guard';

@Component({
  selector: 'app-alerts-controls',
  templateUrl: './alerts-controls.component.html',
  styleUrls: ['./alerts-controls.component.scss']
})
export class AlertsControlsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() options!: AlertsControlOptions;

  @ViewChild('sensorsInnerInput') sensorsInnerInput!: ElementRef<HTMLInputElement>;
  @ViewChild('propertiesInnerInput') propertiesInnerInput!: ElementRef<HTMLInputElement>;

  sensors?: SensorSummary[];
  properties?: Property[];
  globalStartDate = '';
  globalEndDate = '';
  filterForm = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
    sensors: new FormControl({ value: new Set<string>(), disabled: this.onSensorRoute() }),
    properties: new FormControl(new Set<string>()),
    qualifications: new FormGroup({
      NEW: new FormControl(false),
      HEALTH_ALERT: new FormControl(false),
      THRESHOLD_ALERT: new FormControl(false),
      UNCERTAIN_DATA: new FormControl(false),
      SAMPLE_ERROR: new FormControl(false),
      ABERENT_DATA: new FormControl(false),
    }),
  });

  sensorInnerInput = new FormControl({ value: null, disabled: this.onSensorRoute() });
  propertyInnerInput = new FormControl(null);
  countByQualification: Record<AlertQualification, number> = {
    NEW: 0,
    HEALTH_ALERT: 0,
    THRESHOLD_ALERT: 0,
    UNCERTAIN_DATA: 0,
    SAMPLE_ERROR: 0,
    ABERENT_DATA: 0
  };

  sub?: Subscription;

  sensorSelected(event: MatAutocompleteSelectedEvent): void {
    this.optionSelected<SensorSummary>(event, 'sensors', this.sensorInnerInput, this.sensorsInnerInput);
  }

  sensorRemoved(sensor: SensorSummary): void {
    this.optionRemoved<SensorSummary>(sensor, 'sensors');
  }

  propertySelected(event: MatAutocompleteSelectedEvent): void {
    this.optionSelected<Property>(event, 'properties', this.propertyInnerInput, this.propertiesInnerInput);
  }

  propertyRemoved(property: Property): void {
    this.optionRemoved<Property>(property, 'properties');
  }

  onStructureRoute(route: ActivatedRouteSnapshot['routeConfig'] = this.route.snapshot.routeConfig): boolean {
    return route?.path?.includes('structure/:id') ?? false;
  }

  onBacRoute(route: ActivatedRouteSnapshot['routeConfig'] = this.route.snapshot.routeConfig): boolean {
    return route?.path?.includes('bac/:id') ?? false;
  }

  onSensorRoute(route: ActivatedRouteSnapshot['routeConfig'] = this.route.snapshot.routeConfig): boolean {
    return route?.path?.includes('sensor/:id') ?? false;
  }

  private destroy$ = new Subject<void>();

  filteredSensorsOptions$: Observable<SensorSummary[]> = of([]);
  filteredPropertiesOptions$: Observable<Property[]> = of([]);

  constructor(public route: ActivatedRoute, private router: Router, private apiService: ApiService, private alertsService: AlertsService) {
  }

  ngOnInit(): void {
    this.alertsService.alertUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.initCounts();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes['options'].currentValue) !== JSON.stringify(changes['options'].previousValue)) {
      this.onOptionsChanges(changes['options'].currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.filterForm.get('qualifications')?.get('NEW')?.setValue(true);
  }

  private onOptionsChanges(options: AlertsControlOptions): void {
    this.sensors = options.availableSensors;
    this.properties = options.availableProperties;

    this.globalStartDate = options.dateRange.minDate;
    this.globalEndDate = options.dateRange.maxDate;

    const formValue = this.queryParamToFilterFormValue(options.params);

    // This set value is considered as setting new initial value so we don't want to trigger valueChanges for this
    this.filterForm.setValue(formValue, { emitEvent: false });

    this.filteredPropertiesOptions$ = this.initOptions(
      this.propertyInnerInput,
      'properties',
      formValue.properties,
      this.properties ?? []
    );
    this.filteredSensorsOptions$ = this.initOptions(
      this.sensorInnerInput,
      'sensors',
      formValue.sensors,
      this.sensors ?? []
    );

    this.initCounts();

    this.sub?.unsubscribe();

    this.sub = combineLatest([
      this.filterChange$('startDate', formValue.startDate),
      this.filterChange$('endDate', formValue.endDate),
      this.filterChange$('properties', formValue.properties),
      this.filterChange$('sensors', formValue.sensors),
      this.filterChange$('qualifications', formValue.qualifications),
    ])
      .pipe(
        skip(1),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe(([startDate, endDate, properties, sensors, qualifications]) => {
        this.goToFilteredAlerts({
          startDate,
          endDate,
          properties,
          sensors,
          qualifications,
        });
      });
  }

  private goToFilteredAlerts(formValues: Partial<FilterFormValues>): void {
    const queryParams = this.formatFormValues(formValues);
    const additionalState: AlertsGuardState = {
      filterApplied: true,
    };

    this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams,
      skipLocationChange: false,
      state: additionalState
    }).then();
  }

  private queryParamToFilterFormValue(filter: AlertsControlOptions['params']): FilterFormValues {
    const startDate = filter?.startDate ? DateTime.fromISO(filter.startDate).toUTC() : null;
    const endDate = filter?.endDate ? DateTime.fromISO(filter.endDate).toUTC() : null;

    const qualifications = this.queryParamToQualifications(UtilsService.paramsToArray(filter?.qualification));

    const properties = filter?.paramCodes && this.properties
      ? this.parseFiltersToForm<Property>(UtilsService.paramsToArray(filter.paramCodes), this.properties, 'propertyId')
      : new Set<Property>();

    let sensors: Set<SensorSummary>;

    if (this.onSensorRoute()) {
      const sensorRouteId = this.route.snapshot.params['id'] as SensorSummary['sensorId'];
      sensors = sensorRouteId && this.sensors
        ? this.parseFiltersToForm<SensorSummary>(UtilsService.paramsToArray(sensorRouteId), this.sensors, 'sensorId')
        : new Set();
    } else {
      sensors = filter?.sensorIds && this.sensors
        ? this.parseFiltersToForm<SensorSummary>(UtilsService.paramsToArray(filter.sensorIds), this.sensors, 'sensorId')
        : new Set();
    }

    return {
      startDate,
      endDate,
      qualifications,
      properties,
      sensors,
    };
  }

  private formatFormValues(formValues: Partial<FilterFormValues>): AlertSearchFilter {
    const formattedFormValue: AlertSearchFilter = {};

    if (formValues?.startDate) {
      UtilsService.applyDateFilter(formattedFormValue, 'start', formValues.startDate);
    }
    if (formValues?.endDate) {
      UtilsService.applyDateFilter(formattedFormValue, 'end', formValues.endDate);
    }

    if (formValues?.qualifications) {
      formattedFormValue.qualification = Object.keys(formValues.qualifications).reduce((acc, curr) => {
        const currentKey = curr as AlertQualification;
        if (formValues.qualifications![currentKey]) {
          acc.push(currentKey);
        }
        return acc;
      }, [] as AlertQualification[]);
    }

    if (formValues?.sensors && !this.onSensorRoute()) {
      formattedFormValue.sensorIds = [...formValues.sensors].map((sensor: SensorSummary) => sensor.sensorId);
    }

    if (formValues?.properties) {
      formattedFormValue.paramCodes = [...formValues.properties].map((property: Property) => property.propertyId);
    }

    return formattedFormValue;
  }

  private queryParamToQualifications(qualifications: AlertQualification[]): FilterFormValues['qualifications'] {
    const parsedQualifications: FilterFormValues['qualifications'] = {
      NEW: false,
      HEALTH_ALERT: false,
      THRESHOLD_ALERT: false,
      UNCERTAIN_DATA: false,
      SAMPLE_ERROR: false,
      ABERENT_DATA: false
    };

    qualifications.forEach(qualif => {
      parsedQualifications[qualif] = true;
    });

    return parsedQualifications;
  }

  private parseFiltersToForm<T extends Record<string, unknown>>(
    filterItems: string[],
    baseValue: T[],
    accessor: ParseFilterAccessor
  ): Set<T> {
    return new Set(
      filterItems.reduce((acc, filterItem) => {
        const prop = baseValue.find((value) => value[accessor] === filterItem);
        if (prop) {
          acc.push(prop);
        }
        return acc;
      }, [] as T[]));
  }

  private initCounts(): void {
    let filters: Partial<AlertSearchFilter> = { ...this.route.snapshot.queryParams };
    delete filters.qualification;

    if (this.onStructureRoute()) {
      filters.structureId = this.route.snapshot.params['id'];
    } else if (this.onBacRoute()) {
      filters.bacId = this.route.snapshot.params['id'];
    } else if (this.onSensorRoute()) {
      filters = this.alertsService.setSensorIdsFilters(this.route.snapshot.params['id'], filters);
    }

    this.apiService.alerts.getCountByQualification$(filters)
      .pipe(first())
      .subscribe((countByQualification) => {
        this.countByQualification = {
          NEW: 0,
          HEALTH_ALERT: 0,
          THRESHOLD_ALERT: 0,
          UNCERTAIN_DATA: 0,
          SAMPLE_ERROR: 0,
          ABERENT_DATA: 0
        };
        countByQualification.forEach(res => {
          this.countByQualification[res.qualification] = res.count;
        });

        this.updateHeaderCounts();
      });
  }

  private updateHeaderCounts(): void {
    const qualificationParams: AlertQualification[] = UtilsService.paramsToArray(this.route.snapshot.queryParams['qualification']);
    const archived = qualificationParams.reduce((acc, curr) => {
      if (curr !== AlertQualification.NEW) {
        acc += this.countByQualification[curr];
      }
      return acc;
    }, 0);

    const headerCounts = {
      new: qualificationParams.includes(AlertQualification.NEW) ? this.countByQualification[AlertQualification.NEW] : 0,
      archived,
    };

    this.alertsService.alertCounts$.next(headerCounts);
  }

  private filterChange$<T>(controlName: string, defaultValue: T): Observable<T> {
    return this.filterForm.get(controlName)!.valueChanges.pipe(startWith(defaultValue));
  }

  private filterOptions<T extends SensorSummary | Property>(
    options: T[],
    value: string | T | null,
    formControlValue: Set<T>
  ): T[] {
    if (typeof value !== 'string') {
      return options.filter(option => !formControlValue.has(option));
    }
    return options.filter(option => !formControlValue.has(option) && option.name.toLowerCase().includes(value.toLowerCase()));
  }

  private optionRemoved<T>(option: T, controlName: string): void {
    const formControl = this.filterForm.get(controlName);
    if (formControl) {
      const value = formControl.value as Set<T>;
      value.delete(option);
      formControl.setValue(value);
    }
  }

  private optionSelected<T>(
    event: MatAutocompleteSelectedEvent,
    controlName: string,
    autocompleteControl: AbstractControl,
    nativeInput: ElementRef<HTMLInputElement>
  ): void {
    const formControl = this.filterForm.get(controlName);
    if (formControl) {
      const sensorFcValue = formControl.value as Set<T>;
      sensorFcValue.add(event.option.value);
      formControl.setValue(sensorFcValue);
    }
    autocompleteControl.setValue('');
    nativeInput.nativeElement.value = '';
  }

  private initOptions<T>(innerInput: AbstractControl, filterFormControlName: string, defaultValue: Set<T>, valuesList: T[]): Observable<T[]> {
    return combineLatest([
      innerInput.valueChanges.pipe(startWith('')),
      this.filterForm.get(filterFormControlName)!.valueChanges.pipe(startWith(defaultValue))
    ]).pipe(
      takeUntil(this.destroy$),
      map(([inputValue, currentValue]) => this.filterOptions(valuesList ?? [], inputValue, currentValue ?? new Set<T>()))
    );
  }
}
