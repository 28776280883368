import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BacChip,
  ChipType,
  FamilyChip,
  ProjectSupervisorChip,
  PropertyChip,
  SelectedChip,
  SensorChip,
  StructureChip
} from '../../shared/measures-component.model';
import { debounceTime, map, mergeMap, Observable, of, startWith, switchMap } from 'rxjs';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ItemType, TreeChildConfig, TreeNode } from '../../shared/tree-display.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UtilsService } from '../../services/utils.service';
import { RegisterSensorFilter, SensorFilterOptions, SensorType } from '../../shared/sensor.model';
import { DateTime } from 'luxon';
import { Property } from '../../shared/property.model';
import { DataTarget } from '../../shared/enums.model';
import { StsGroupType } from '@geomatys/ngx-core/sensor-things';
import { MeasuresForm } from './sensor-filter';
import { SensorBillboardService } from '../../services/sensor-billboard.service';
import { ViewerService } from '../../services/viewer.service';


@Component({
  selector: 'app-sensor-filters',
  template: '',
  styleUrls: []
})
export abstract class SensorFiltersComponent {

  projectSupervisors: Array<{ name: string; acronym: string }> = [];
  selectedChips: Array<SelectedChip> = [];
  root: TreeNode | undefined;
  chipType = ChipType;
  propertyFamilies: Array<string> = [];
  measuresForm = new FormGroup({
    property: new FormControl(''),
    startDate: new FormControl(),
    endDate: new FormControl(),
    drillDate: new FormControl(),
    waterMassCode: new FormControl(''),
    sensor: new FormControl(''),
    quantified: new FormControl(false),
    detected: new FormControl(false),
    authorized: new FormControl(false),
    unauthorized: new FormControl(false),
    active: new FormControl(false),
    idle: new FormControl(false),
    projectSupervisor: new FormControl(''),
    family: new FormControl(''),
    phyto: new FormControl(false),
    microP: new FormControl(false),
    sensorTypes: new FormGroup({
      underground: new FormControl(false),
      meteo: new FormControl(false),
      surface: new FormControl(false),
      hydroSTD: new FormControl(false),
      hydroDEB: new FormControl(false),
    }),
  });

  filteredOptions$?: Observable<Array<{ name: string, id: string }>>;
  projectSupervisorOptions$ = this.measuresForm?.get('projectSupervisor')?.valueChanges.pipe(
    startWith(''),
    debounceTime(200),
    switchMap(queryStr => {
      if (typeof queryStr === 'string' && queryStr != '') {
        return this.apiService.sensors.searchProjectSupervisorByName(queryStr);
      } else {
        return of([]);
      }
    }),
    map(value => {
      return value;
    }),
  );

  sensorOptions$ = this.measuresForm?.get('sensor')?.valueChanges.pipe(
    startWith(''),
    debounceTime(200),
    map(value => this._filterById(value || '', this.availableSensors, this.selectedSensors)),
  );

  protected selectedResultQuality: Set<string> = new Set();
  protected selectedAuthorization: Set<string> = new Set();
  protected selectedState: Set<string> = new Set();
  protected selectedCategory: Set<string> = new Set();
  protected propertyCache: Array<{ name: string, id: string }> = [];
  protected selectedProperties: Map<string, string> = new Map();
  protected selectedFamilies: Set<string> = new Set();
  protected selectedStructures: Set<number> = new Set();
  protected selectedBacs: Set<string> = new Set();
  protected selectedSensors: Set<string> = new Set();
  protected selectedProjectSupervisor: Set<string> = new Set();
  protected selectedSensorTypes: Set<SensorType> = new Set();
  protected availableSensors: Array<{ name: string, id: string }> = [];
  protected groupType: StsGroupType = StsGroupType.NONE;

  @Input() set options(options: SensorFilterOptions) {
    this.initComponent(options);
  }

  @Output() queryParam = new EventEmitter<Params>();

  protected constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public apiService: ApiService,
    public utilsService: UtilsService,
    public sensorBillboardService: SensorBillboardService,
    public viewerService: ViewerService
  ) {
  }


  public resetFilters(): void {
    this.selectedProperties.clear();
    this.selectedFamilies.clear();
    this.selectedSensors.clear();
    this.selectedBacs.clear();
    this.selectedStructures.clear();
    this.selectedSensorTypes.clear();
    this.selectedResultQuality.clear();
    this.selectedAuthorization.clear();
    this.selectedState.clear();
    this.selectedProjectSupervisor.clear();
    this.measuresForm.reset();
    this.selectedChips = [];
    this.sensorBillboardService.selectSensor('map');
    this.goToFilteredMeasures();
  }

  protected parseQueryParams(): Params {
    const queryParams: RegisterSensorFilter & { groupType?: StsGroupType } = {};
    const startDate = this.measuresForm?.get('startDate')?.value as MeasuresForm['startDate'];
    const endDate = this.measuresForm?.get('endDate')?.value as MeasuresForm['endDate'];
    const drillDate = this.measuresForm?.get('drillDate')?.value as MeasuresForm['drillDate'];
    const waterMassCode = this.measuresForm?.get('waterMassCode')?.value as MeasuresForm['waterMassCode'];

    if (startDate) {
      UtilsService.applyDateFilter(queryParams, 'start', startDate);
    }
    if (endDate) {
      UtilsService.applyDateFilter(queryParams, 'end', endDate);
    }
    if (drillDate) {
      queryParams.lastDrillDiag = drillDate.toJSDate().toISOString();
    }
    if (waterMassCode) {
      queryParams.waterMassCode = waterMassCode;
    }
    if (this.selectedProperties.size) {
      queryParams.observedProperties = Array.from(this.selectedProperties.keys());
    }
    if (this.selectedFamilies.size) {
      queryParams.families = Array.from(this.selectedFamilies.keys());
    }
    if (this.selectedStructures.size) {
      queryParams.structIds = Array.from(this.selectedStructures.keys());
    }
    if (this.selectedBacs.size) {
      queryParams.bacIds = Array.from(this.selectedBacs.keys());
    }
    if (this.selectedSensors.size) {
      queryParams.sensorIds = Array.from(this.selectedSensors.keys());
    }
    if (this.selectedResultQuality.size) {
      queryParams.code_remarque = Array.from(this.selectedResultQuality.keys());
    }
    if (this.selectedAuthorization.size) {
      queryParams.authorization = Array.from(this.selectedAuthorization.keys());
    }
    if (this.selectedProjectSupervisor.size) {
      queryParams['project-supervisor'] = Array.from(this.selectedProjectSupervisor.keys());
    }
    if (this.selectedSensorTypes.size) {
      queryParams.sensorType = Array.from(this.selectedSensorTypes.keys());
    }

    queryParams.groupType = this.groupType;
    this.queryParam.emit(queryParams);
    return queryParams;
  }

  abstract goToFilteredMeasures(): void;

  public resetProperties(): void {
    this.measuresForm?.get('property')?.setValue(undefined);
  }

  public searchProperties(): void {
    const property = this.propertyCache.find(p => p.name === this.measuresForm?.get('property')?.value as MeasuresForm['property']);
    if (property) {
      this.selectedProperties.set(property.id, property.name);
      this.selectedChips.push({
        type: ChipType.PROPERTY,
        label: this.getLabelFromName(property.name),
        name: property.name,
        id: property.id
      });
      this.goToFilteredMeasures();
    }
    this.resetProperties();
  }

  public resetWaterMassCode(): void {
    this.measuresForm?.get('waterMassCode')?.setValue(undefined);
  }

  public resetSelectedSensor(): void {
    this.measuresForm?.get('sensor')?.setValue(undefined);
  }

  public searchSensors(): void {
    const sensor = this.availableSensors.find(s => s.name === this.measuresForm?.get('sensor')?.value as MeasuresForm['sensor']);
    if (sensor) {
      this.selectedSensors.add(sensor.id);
      this.selectedChips.push({
        type: ChipType.SENSOR,
        label: this.getLabelFromName(sensor.name),
        name: sensor.name,
        id: sensor.id
      });
      this.goToFilteredMeasures();
    }
    this.resetSelectedSensor();
  }

  public searchProjectSupervisor(): void {
    const value = this.measuresForm?.get('projectSupervisor')?.value;
    if (value) {
      let filter: string;
      let name: string;
      if (typeof value === 'string') {
        filter = value;
        name = value;
      } else {
        filter = value.name === value.acronym ? value.name : value.name + '_' + value.acronym;
        name = value.name;
      }
      this.selectedProjectSupervisor.add(filter);
      this.selectedChips.push({
        type: ChipType.PROJECT_SUPERVISOR,
        name: filter,
        label: this.getLabelFromName(name)
      });
      this.goToFilteredMeasures();
    }
    this.resetSelectedProjectSupervisor();
  }

  public resetSelectedProjectSupervisor(): void {
    this.measuresForm?.get('projectSupervisor')?.setValue(undefined);
  }

  public setQualification($event: boolean, attribute: string): void {

    if ($event) {
      //possible options are 'detected' or 'quantified', corresponding to resultQuality values of 1 and 10 respectively
      attribute === 'detected'
        ? this.selectedResultQuality.add('10')
        : this.selectedResultQuality.add('1');
    } else {
      attribute === 'detected'
        ? this.selectedResultQuality.delete('10')
        : this.selectedResultQuality.delete('1');
    }
    this.goToFilteredMeasures();
  }

  public setSensorType($event: boolean, value: SensorType): void {
    $event ? this.selectedSensorTypes.add(value) : this.selectedSensorTypes.delete(value);
    this.goToFilteredMeasures();
  }

  public setAuthorization($event: boolean, attribute: string): void {
    if ($event) {
      attribute === 'authorized'
        ? this.selectedAuthorization.add('authorized')
        : this.selectedAuthorization.add('unauthorized');
    } else {
      attribute === 'unauthorized'
        ? this.selectedAuthorization.delete('unauthorized')
        : this.selectedAuthorization.delete('authorized');
    }
    this.goToFilteredMeasures();
  }

  public setState($event: boolean, attribute: string): void {
    if ($event) {
      attribute === 'active'
        ? this.selectedState.add('active')
        : this.selectedState.add('idle');
    } else {
      attribute === 'idle'
        ? this.selectedState.delete('idle')
        : this.selectedState.delete('active');
    }
    this.goToFilteredMeasures();
  }

  public setCategory($event: boolean, category: string): void {
    $event
      ? this.selectedCategory.add(category)
      : this.selectedCategory.delete(category);
    this.goToFilteredMeasures();
  }

  public removeChip(item: SelectedChip, index: number) {
    switch (item.type) {
      case ChipType.PROPERTY:
        this.selectedProperties.delete(item.id);
        break;
      case ChipType.FAMILY:
        this.selectedFamilies.delete(item.name);
        break;
      case ChipType.SENSOR:
        this.selectedSensors.delete(item.id);
        break;
      case ChipType.BAC:
        this.selectedBacs.delete(item.id);
        break;
      case ChipType.STRUCTURE:
        this.selectedStructures.delete(Number(item.id));
        break;
      case ChipType.PROJECT_SUPERVISOR:
        this.selectedProjectSupervisor.delete(item.name);
    }
    this.selectedChips.splice(index, 1);
    this.goToFilteredMeasures();
  }

  public addFamily() {
    const value = this.measuresForm?.get('family')?.value as MeasuresForm['family'];
    if (value) {
      this.selectedFamilies.add(value);
      this.measuresForm?.get('family')?.setValue('');
      this.selectedChips.push({
        type: ChipType.FAMILY,
        name: value,
        label: this.getLabelFromName(value)
      });
      this.goToFilteredMeasures();
    }
  }

  public onItemSelected($event: TreeChildConfig) {
    let chipType: ChipType.SENSOR | ChipType.BAC | ChipType.STRUCTURE;
    switch ($event.type) {
      case ItemType.STRUCTURE:
        chipType = ChipType.STRUCTURE;
        this.selectedStructures.add($event.id);
        break;
      case ItemType.BAC:
        chipType = ChipType.BAC;
        this.selectedBacs.add($event.id);
        break;
      case ItemType.SENSOR:
        chipType = ChipType.SENSOR;
        this.selectedSensors.add($event.id);
        break;
    }
    this.selectedChips.push({
      type: chipType,
      name: $event.name,
      label: this.getLabelFromName($event.name),
      id: $event.id.toString()
    } as SelectedChip);
    this.goToFilteredMeasures();
  }

  public setDate($event: Event): void {
    if ($event) {
      this.goToFilteredMeasures();
    }
  }

  protected _filter(value: string, options: Array<string>, alreadySelected: Set<string>): Array<string> {
    const filterValue = value.toLowerCase();
    const availableOptions = options.filter(o => !alreadySelected.has(o)).sort((a, b) => a > b ? 1 : -1);
    return availableOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  protected _filterById(value: string, options: Array<{
    name: string,
    id: string
  }>, alreadySelected: Set<string>): Array<{ name: string, id: string }> {
    const filterValue = value.toLowerCase();
    const availableOptions = options.filter(o => !alreadySelected.has(o.id)).sort((a, b) => a.name > b.name ? 1 : -1);
    return availableOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  protected initFilteredOptions(control: AbstractControl | null,
                                apiByName: (value: string) => Observable<Array<Property>>) {
    return control?.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        mergeMap(value => {
          let obs: Observable<Array<Property>>;
          if (value && value !== '' && value.length > 1) {
            obs = apiByName(value);
          } else {
            obs = of([]);
          }
          return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.FILTER_ERROR', []);
        }),
        map(res => {
          const options = res.map(i => {
            const id = i.propertyId;
            return { name: i.name, id };
          });
          this.propertyCache = new Array(...options);
          return options.sort((a, b) => a.name.localeCompare(b.name));
        })
      );
  }

  protected getLabelFromName(name: string): string {
    return name.length < 18 ? name : name.slice(0, 15) + '...';
  }

  protected getPropertiesByName(query: string): Observable<Array<Property>> {
    return this.apiService.properties.searchByName(query);
  }

  protected getAllProperties(): Observable<Array<Property>> {
    return this.apiService.properties.getAll();
  }

  protected initComponent(options: SensorFilterOptions): void {
    const filters: Partial<RegisterSensorFilter> = {
      structIds: options.target === DataTarget.STRUCTURE && options.structureId !== 'all' ? [options.structureId] : [],
      bacIds: options.target === DataTarget.BAC ? [options.bacId] : [],
      sensorIds: options.target === DataTarget.SENSOR ? [options.sensorId] : [],
    };

    this.apiService.sensors.getAllProjectSupervisors(filters)
      .subscribe(
        (supervisors) => {
          this.projectSupervisors = supervisors;
        },
        (error) => {
          console.error('Failed to load project supervisors', error);
        }
      );

    this.propertyFamilies = options.availableFamilies;
    this.groupType = options.groupType ?? this.groupType;

    if (options.startDate !== undefined) {
      this.measuresForm?.get('startDate')?.setValue(DateTime.fromISO(options.startDate).toUTC(), { emitViewToModelChange: false });
    }
    if (options.endDate !== undefined) {
      this.measuresForm?.get('endDate')?.setValue(DateTime.fromISO(options.endDate).toUTC(), { emitViewToModelChange: false });
    }
    if (options.drillDate !== undefined) {
      this.measuresForm?.get('drillDate')?.setValue(DateTime.fromISO(options.drillDate).toUTC(), { emitViewToModelChange: false });
    }
    if (options.waterMassCode !== undefined) {
      this.measuresForm?.get('waterMassCode')?.setValue(options.waterMassCode);
    }

    this.filteredOptions$ = this.initFilteredOptions(this.measuresForm?.get('property'), this.getPropertiesByName.bind(this));

    this.selectedChips = [];
    this.selectedProperties = new Map();
    this.selectedFamilies = new Set();
    this.selectedStructures = new Set();
    this.selectedBacs = new Set();
    this.selectedSensors = new Set();
    this.selectedSensorTypes = new Set();
    this.selectedResultQuality = new Set();
    this.selectedAuthorization = new Set();
    this.selectedProjectSupervisor = new Set();

    // ToDo: refactor the loops

    if (options.selectedFamilies) {
      for (const f of options.selectedFamilies) {
        this.selectedFamilies.add(f);
        const chip: FamilyChip = {
          type: ChipType.FAMILY,
          name: f,
          label: this.getLabelFromName(f)
        };
        this.selectedChips.push(chip);
      }
    }

    if (options.selectedObservedProperties) {
      for (const p of options.selectedObservedProperties) {
        this.selectedProperties.set(String(p.propertyId), p.name);
        const chip: PropertyChip = {
          type: ChipType.PROPERTY,
          name: p.name,
          id: String(p.propertyId),
          label: this.getLabelFromName(p.name)
        };
        this.selectedChips.push(chip);
      }
    }

    if (options.target === DataTarget.STRUCTURE && options.structureId === 'all' && options.selectedStructures) {
      for (const s of options.selectedStructures) {
        this.selectedStructures.add(s.id);
        const chip: StructureChip = {
          type: ChipType.STRUCTURE,
          name: s.name,
          id: s.id.toString(),
          label: this.getLabelFromName(s.name)
        };
        this.selectedChips.push(chip);
      }
    }

    if (options.target === DataTarget.STRUCTURE && options.selectedBacs) {
      for (const b of options.selectedBacs) {
        this.selectedBacs.add(b.id);
        const chip: BacChip = {
          type: ChipType.BAC,
          name: b.name,
          id: b.id,
          label: this.getLabelFromName(b.name)
        };
        this.selectedChips.push(chip);
      }
    }

    if ((options.target === DataTarget.STRUCTURE || options.target === DataTarget.BAC) && options.selectedSensors) {
      for (const s of options.selectedSensors) {
        this.selectedSensors.add(s.id);
        const chip: SensorChip = {
          type: ChipType.SENSOR,
          name: s.name,
          id: s.id,
          label: this.getLabelFromName(s.name)
        };
        this.selectedChips.push(chip);
      }
    }

    this.root = options.treeRoot;

    if (options.selectedSensorTypes) {
      for (const sensorType of options.selectedSensorTypes) {
        this.selectedSensorTypes.add(sensorType);
        switch (sensorType) {
          case 'underground':
            this.measuresForm?.get('sensorTypes')?.get('underground')?.setValue(true);
            break;
          case 'meteo':
            this.measuresForm?.get('sensorTypes')?.get('meteo')?.setValue(true);
            break;
          case 'surface':
            this.measuresForm?.get('sensorTypes')?.get('surface')?.setValue(true);
            break;
          case 'hydro-STD':
            this.measuresForm?.get('sensorTypes')?.get('hydroSTD')?.setValue(true);
            break;
          case 'hydro-DEB':
            this.measuresForm?.get('sensorTypes')?.get('hydroDEB')?.setValue(true);
            break;
        }
      }
    }

    if (options.selectedResultQuality) {
      for (const s of options.selectedResultQuality) {
        this.selectedResultQuality.add(s);
        switch (s) {
          case '1':
            this.measuresForm?.get('quantified')?.setValue(true);
            break;
          case '10':
            this.measuresForm?.get('detected')?.setValue(true);
            break;
        }
      }
    }

    if (options.selectedAuthorization) {
      for (const s of options.selectedAuthorization) {
        this.selectedAuthorization.add(s);
        switch (s) {
          case 'authorized':
            this.measuresForm?.get('authorized')?.setValue(true);
            break;
          case 'unauthorized':
            this.measuresForm?.get('unauthorized')?.setValue(true);
            break;
        }
      }
    }

    if (options.selectedState) {
      for (const s of options.selectedState) {
        this.selectedState.add(s);
        switch (s) {
          case 'active':
            this.measuresForm?.get('active')?.setValue(true);
            break;
          case 'idle':
            this.measuresForm?.get('idle')?.setValue(true);
            break;
        }
      }
    }

    if (options.selectedProjectSupervisor) {
      for (const s of options.selectedProjectSupervisor) {
        this.selectedProjectSupervisor.add(s);
        const label = this.parseProjectSupervisorFilter(s);
        const chip: ProjectSupervisorChip = {
          type: ChipType.PROJECT_SUPERVISOR,
          name: s,
          label: this.getLabelFromName(label)
        };
        this.selectedChips.push(chip);
      }
    }

    if (options.selectedCategories) {
      for (const s of options.selectedCategories) {
        this.selectedCategory.add(s);
        switch (s) {
          case 'micro-polluant':
            this.measuresForm?.get('microP')?.setValue(true);
            break;
          case 'phyto-sanitaire':
            this.measuresForm?.get('phyto')?.setValue(true);
            break;
        }
      }
    }
  }

  public displayProjectSupervisor(event: { name: string, acronym: string } | undefined) {
    if (event) {
      return event.name === event.acronym ? event.name : event.name + ' - ' + event.acronym;
    } else {
      return '';
    }
  }

  public parseProjectSupervisorFilter(filter: string): string {
    const separatorIndexes = [...filter.matchAll(new RegExp('_', 'gi'))].map(m => m.index);
    if (separatorIndexes.length > 0) {
      let sameAsAcronym = false;
      let index = 0;
      while (!sameAsAcronym && index < separatorIndexes.length) {
        const separatorIndex = separatorIndexes[index]!;
        const acronym = filter.substring(0, separatorIndex);
        const name = filter.substring(separatorIndex + 1);
        sameAsAcronym = acronym === name;
        if (!sameAsAcronym) {
          index++;
        }
      }
      if (sameAsAcronym) {
        return filter.substring(0, separatorIndexes[index]);
      } else {
        return filter.substring(0, separatorIndexes[0]);
      }
    } else {
      return filter;
    }
  }
}
