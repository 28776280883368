<div id="dashboard" fxLayout="row">
  <div id="dashboard-controls" #dashboardControls [ngClass]="{ 'open': dashboardOpened }">
    <ng-content select=".controls"></ng-content>
  </div>

  <div id="dashboard-toggle" class="flex flex-col items-center justify-center cursor-pointer" (click)="toggleDashboard()">
    <fa-icon class="icon" [icon]="faCaretRight" *ngIf="!dashboardOpened"></fa-icon>
    <fa-icon class="icon" [icon]="faCaretLeft" *ngIf="dashboardOpened"></fa-icon>
  </div>

  <div id="dashboard-data" [ngClass]="{ 'open': dashboardDataOpened }">
    <ng-content select=".data"></ng-content>
  </div>
</div>
