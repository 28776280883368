<form (ngSubmit)="submit()" [formGroup]="alertForm" class=" flex flex-col gap-2.5" id="alert-options">
  <label>{{ 'ALERTS.TITLE_QUALIFICATION' | translate }} :</label>
  <mat-form-field appearance="outline">
    <mat-select formControlName="alertQualification">
      <mat-option *ngFor="let opt of qualificationOptions"
                  [value]="opt.value">{{ opt.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <label>{{ 'ALERTS.TITLE_COMMENT' | translate }} :</label>
  <mat-form-field appearance="outline">
    <textarea matInput [readOnly]="!canEdit" formControlName="alertComment" rows="5"></textarea>
  </mat-form-field>

  <button class="self-end text-white" [ngClass]="{'bg-[#00a2e2] hover:bg-[#0060df]' : !alertForm.invalid}"
          mat-flat-button *ngIf="canEdit"
          [disabled]="alertForm.invalid" type="submit">
    {{ 'VALIDATE_BUTTON' | translate }}
  </button>
</form>
