import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MeasuresResolverData } from '../../shared/resolver-data.model';
import { DataTarget } from '../../shared/enums.model';
import { MeasureDataOptions } from '../../shared/measures-component.model';
import { SensorFilterOptions } from '../../shared/sensor.model';

@Component({
  selector: 'app-measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.scss']
})
export class MeasuresComponent implements OnInit {
  public controlOptions!: SensorFilterOptions;
  public dataOptions!: MeasureDataOptions;
  public startObsDate: string | undefined;
  public endObsDate: string | undefined;
  private target!: DataTarget;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.data
      .subscribe({
        next: res => {
          const data: MeasuresResolverData = res['data'];
          const { dataSource, ...filterOptions } = data;
          this.target = data.target;
          this.dataOptions = { target: this.target, dataSource, groupType: data.groupType };
          this.controlOptions = filterOptions;

          if (!filterOptions.startDate || !filterOptions.endDate) {
            const dates = this.getAvailableDataDates(data);
            this.startObsDate = dates.startDate;
            this.endObsDate = dates.endDate;
          }
        }
      });
  }

  private getAvailableDataDates(data: MeasuresResolverData): { startDate: string, endDate: string } {
    let startDate = '';
    let endDate = '';
    const dataSource = data.dataSource;
    if (dataSource.globalStartDate && dataSource.globalEndDate) {
      // we only need to display Date data, not DateTime
      startDate = dataSource.globalStartDate.setLocale('fr').toLocaleString();
      endDate = dataSource.globalEndDate.setLocale('fr').toLocaleString();
    }
    return { startDate, endDate };
  }

}
