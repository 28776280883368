<div class="flex flex-col items-center w-full wrapper">
  <div class="grid grid-cols-[2%_5%_10%_15%_25%_30%_10%_3%] w-full items-center justify-center header">
    <div >
      <mat-checkbox
        [ngModel]="checked"
        (ngModelChange)="onSelectionChange($event)"
        *ngIf="canValidateAlerts">
      </mat-checkbox>
    </div>
    <div>
      <mat-icon [ngStyle]="{'color': iconColor, 'height': iconSize, 'width': iconSize, 'margin-left': iconMargin}"
                [svgIcon]="icon"></mat-icon>
    </div>
    <p class="date-label">{{ date }}</p>
    <p class="long-name">{{ structure }}</p>
    <p [matTooltip]="sensor | uppercase" class="long-name">{{ sensor | uppercase }}</p>
    <p [matTooltip]="substance" class="param-name long-name">{{ substance }}</p>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <p>{{ alert.value }} {{ alert.thresholdUom }}</p>
    </div>
    <mat-icon (click)="togglePanel()" [svgIcon]="chevronIcon" class="toggle"></mat-icon>
  </div>
  <div *ngIf="open" class="flex flex-row items-center w-full info-panel">
    <div fxFlex="75" class="graph-container">
      <app-graph-wrapper [source]="graphOptions"></app-graph-wrapper>
    </div>
    <div fxFlex="20">
      <app-validation-form [config]="alert" (alertUpdated)="onAlertUpdated($event)"></app-validation-form>
    </div>
  </div>
</div>
