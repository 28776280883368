<div class="flex flex-row w-full py-6 px-2 wrapper">
  <div class="w-full body">
    <div class="title flex flex-row justify-start items-center gap-2.5">
      <mat-icon class="icon sensor" svgIcon="log-au-sensor-ico"></mat-icon>
      <h1>{{ summary.name.toUpperCase() }}</h1>
    </div>
    <div class="flex flex-ow text-neutral-700">
      <div id="left" fxFlex>
        <p>{{ summary.structureName }} - {{ sensibilityLevel | translate }}</p>
        <p>Code BSS: {{ getUiBSS(summary.sensorId) }}</p>
      </div>
      <div class="actions-button flex flex-row items-center justify-end gap-2">
        <button mat-icon-button aria-label="chart button hover:bg-neutral-200" (click)="navigateTo('synthesis')"
                [matTooltip]="'SYNTHESIS_INFO' | translate">
          <img class="icon h-6 mx-auto" src="/assets/icon_synthese.svg" alt="Synthesis">
        </button>
        <button mat-icon-button aria-label="chart button hover:bg-neutral-200" (click)="navigateTo('measures')"
                [matTooltip]="'MEASURE_INFO' | translate">
          <img class="icon h-5 mx-auto" src="/assets/icon_chart-column.svg" alt="Chart">
        </button>
        <button mat-icon-button aria-label="chart button hover:bg-neutral-200" (click)="navigateTo('alerts')"
                [matTooltip]="'ALERT_INFO' | translate">
          <img class="icon h-6 mx-auto" src="/assets/icon_alertes.svg" alt="Alerte">
        </button>
        <button mat-icon-button class="zoom" [ngClass]="{'selected': selected}" (click)="zoom()"
                [matTooltip]="'SENSORS.ICONS.SELECT_SENSOR' | translate">
          <mat-icon class="flex items-center justify-center text-white icon" svgIcon="log-au-focus"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>


