import { Injectable } from '@angular/core';
import { CesiumContainerService, GeomUtils } from '@geomatys/ngx-cesium';
import { Structure } from '../shared/structure.model';
import { BacSummary } from '../shared/bac.model';
import { SensorSummary } from '../shared/sensor.model';
import { Rectangle } from 'cesium';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {
  private entity ?: Pick<Structure, 'id' | 'name' | 'geometry' | 'geometryContentType'> |
  Pick<BacSummary, 'id' | 'bacId' | 'name' | 'geometry' | 'geometryContentType'> |
  Pick<SensorSummary, 'id' | 'bacId' | 'name' | 'lon' | 'lat'>;


  constructor(
    private cesiumService: CesiumContainerService,
  ) {
  }

  flyToEntityGeometry (entity?: Pick<Structure, 'id' | 'name' | 'geometry' | 'geometryContentType'> |
    Pick<BacSummary, 'id' | 'bacId' | 'name' | 'geometry' | 'geometryContentType'> |
    Pick<SensorSummary, 'id' | 'bacId' | 'name' | 'lon' | 'lat'> | null) {
    if(!entity) {
      entity = this.entity;
    } else {
      this.entity = entity;
    }

    if (entity) {
      if('geometry' in entity) {
        this.flyToByGeometry(entity?.geometry, entity?.geometryContentType);
      } else if ('lon' in entity) {
        this.flyByFlatGeometry(entity?.lon, entity?.lat);
      }
    }
  }

  private flyToByGeometry(geometry: Structure['geometry'], geometryContentType?: Structure['geometryContentType']) {
    if (geometry) {
      const rectangle = geometryContentType === 'geojson' ? GeomUtils.getBboxFromGeoJSON(JSON.parse(geometry)) : GeomUtils.getBboxFromKML(geometry);

      if (rectangle) {
        this.cesiumService.getCurrentViewer()?.camera?.flyTo({ destination: rectangle });
      }
    }
  }

  private flyByFlatGeometry (longitude: number, latitude: number) {
      const halfWidth = 0.05;
      const destination = Rectangle.fromDegrees(
        longitude - halfWidth,
        latitude - halfWidth,
        longitude + halfWidth,
        latitude + halfWidth
      );
      this.cesiumService.getCurrentViewer()?.camera.flyTo({ destination });
  }
}
