import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AlertQualification } from '../shared/alert.model';
import { AlertsGuardState } from "../shared/@types/alert/alert-guard";

@Injectable({
  providedIn: 'root'
})
export class AlertsGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const currentNavigationState: AlertsGuardState = this.router.getCurrentNavigation()?.extras.state;

    const filterApplied = currentNavigationState?.['filterApplied'] ?? false;
    if (!route.queryParams?.['qualification']?.length && !filterApplied) {
      const params: NavigationExtras['queryParams'] = {
        ...(route.queryParams ?? {}),
        qualification: [AlertQualification.NEW],
      };

      return this.router.navigate([state.url], { queryParams: params, skipLocationChange: false }).then();
    }

    return true;
  }

}
