<div id="viewer-container">
  <gcs-cesium-container [options]="cesiumOptions"
                            [mapId]="'map'"
                            [clamp2DView]="true"
                            [enablePickFeatures] = "false"></gcs-cesium-container>
  <div id="overlay-controls" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="10px">
    <div class="control orientation">
      <mat-icon class="m-0 text-black" svgIcon="log-au-compass"></mat-icon>
    </div>
    <div class="control"
    >
      <mat-icon
        class="m-0 text-white"
        svgIcon="log-au-ul-list"
        (click)="toggleLegend()"
        matTooltip="{{ 'Legend' | translate }}"
        matTooltipPosition="left"
       >
      </mat-icon>
    </div>

<!--
    <div class="control">
      <mat-icon class="icon" svgIcon="log-au-measure"></mat-icon>
    </div>
    <div class="control">
      <mat-icon class="icon" svgIcon="log-au-print"></mat-icon>
    </div>

    <div class="control" *ngIf="isAdmin">
      <mat-icon class="icon" svgIcon="log-au-share" (click)="shareMapContext()"></mat-icon>
    </div>
    -->
  </div>
  <div id="legend-overlay" *ngIf="displayLegend">
    <app-legend-container></app-legend-container>
  </div>
  <div id="left-panel">
    <router-outlet></router-outlet>
    <ng-container *ngIf="viewer !== undefined">
      <div id="timeline-container" [ngStyle]="{'z-index': showTimeline ? '3' : '0'}">
        <gcs-timeline
          [clockViewModel]="viewer.clockViewModel"
          [dateTimeFormatter]="timelineDateTimeFormatter">
        </gcs-timeline>
      </div>
    </ng-container>
  </div>
</div>
