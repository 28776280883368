import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, map, Observable } from 'rxjs';
import { AccountResolverData } from '../shared/account-resolver-data.model';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { UserWithStructure } from '../shared/user-account.model';
import { SharedEntities } from '../shared/shared.model';
import { UtilsService } from '../services/utils.service';
import { AlertSubscriptionSummary } from '../shared/@types/alert/alert-types';

@Injectable({
  providedIn: 'root'
})
export class AccountResolver implements Resolve<AccountResolverData> {

  constructor(private apiService: ApiService, private authService: AuthService, private utilsService: UtilsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AccountResolverData> {
    const user: UserWithStructure = this.authService.user;
    const observables: [
      Observable<Array<SharedEntities>>,
      Observable<Array<SharedEntities>>,
      Observable<Array<SharedEntities>>,
      Observable<Array<SharedEntities>>,
      Observable<Array<AlertSubscriptionSummary>>
    ] = [
      this.utilsService.wrapObservable(this.apiService.properties.getSharedWithUser(), undefined, 'ERROR_MSG.GET_SHARED_PROPERTIES', []),
      this.utilsService.wrapObservable(this.apiService.properties.getSharedWithStructure(), undefined, 'ERROR_MSG.GET_SHARED_PROPERTIES', []),
      this.utilsService.wrapObservable(this.apiService.data.getSharedWithUser(), undefined, 'ERROR_MSG.GET_SHARED_LAYERS', []),
      this.utilsService.wrapObservable(this.apiService.data.getSharedWithStructure(), undefined, 'ERROR_MSG.GET_SHARED_LAYERS', []),
      this.utilsService.wrapObservable(this.apiService.alerts.getAllSubscriptionsForUser(user.id), undefined, 'ERROR_MSG.ALERT_SUBSCRIPTIONS', [])
    ];
    return forkJoin(observables)
      .pipe(
        map(res => {
          return {
            sharedProperties: {
              withUser: res[0],
              withUserStructure: res[1]
            },
            sharedLayers: {
              withUser: res[2],
              withUserStructure: res[3]
            },
            alertSubscriptions: res[4]
          };
        })
      );
  }
}
