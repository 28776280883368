<div class="p-5">
  <div class="flex flex-row justify-between items-center mb-5">
    <h1 class="m-0">{{ 'ALERTS.TITLE_FILTER' | translate }}</h1>
    <button mat-icon-button class="reset hover:bg-orange-100"
            (click)="resetFilters()"
            [matTooltip]="'ALERTS.RESET_FILTER' | translate"
    >
      <mat-icon svgIcon="log-au-arrow-rotate-left"></mat-icon>
    </button>
  </div>

  <form [formGroup]="filterForm" class=" flex flex-col gap-4">
    <div>
      <h2>{{ 'DATE' | translate }} :</h2>
      <div class=" date-filter w-full flex flex-row justify-between items-center gap-2.5 pb-[19px]">
        <app-date-picker-wrapper formControlName="startDate"
                                 placeHolder="{{ globalStartDate }}"></app-date-picker-wrapper>
        <app-date-picker-wrapper formControlName="endDate"
                                 placeHolder="{{ globalEndDate }}"></app-date-picker-wrapper>
      </div>
    </div>
    <div>
      <h2>{{ 'MEASURES.PROPERTY' | translate }} :</h2>
      <mat-form-field appearance="fill" class="w-full">
        <mat-chip-list #propertiesChipList>
          <mat-chip
            *ngFor="let property of filterForm.get('properties')?.value ?? []"
            (removed)="propertyRemoved(property)"
          >
            {{ property.name }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input
            #propertiesInnerInput
            placeholder="{{'SEARCH_PLACEHOLDER' | translate}}"
            [formControl]="propertyInnerInput"
            [matAutocomplete]="propertiesAuto"
            [matChipInputFor]="propertiesChipList"
          >
        </mat-chip-list>
        <mat-autocomplete #propertiesAuto="matAutocomplete" (optionSelected)="propertySelected($event)">
          <mat-option *ngFor="let propertyOption of filteredPropertiesOptions$ | async" [value]="propertyOption">
            {{ propertyOption.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div>
      <h2>{{ 'MEASURES.SENSOR_SELECT' | translate }} :</h2>
      <mat-form-field appearance="fill" class="w-full">
        <mat-chip-list #sensorsChipList [disabled]="onSensorRoute | wrapFn: route.snapshot.routeConfig">
          <mat-chip
            *ngFor="let sensor of filterForm.get('sensors')?.value ?? []"
            (removed)="sensorRemoved(sensor)"
          >
            {{ sensor.name }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input
            #sensorsInnerInput
            placeholder="{{'SEARCH_PLACEHOLDER' | translate}}"
            [formControl]="sensorInnerInput"
            [matAutocomplete]="sensorsAuto"
            [matChipInputFor]="sensorsChipList"
          >
        </mat-chip-list>
        <mat-autocomplete #sensorsAuto="matAutocomplete" (optionSelected)="sensorSelected($event)">
          <mat-option *ngFor="let sensorOption of filteredSensorsOptions$ | async" [value]="sensorOption">
            {{ sensorOption.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div>
      <h2>{{ 'ALERTS.ATTRIBUTE_QUALIFICATION' | translate }} :</h2>
      <section formGroupName="qualifications" class="flex flex-col gap-2.5">
        <mat-checkbox formControlName="NEW">
          {{ 'ALERTS.QUALIFICATION.NEW' | translate }}
          <span class="italic text-xs text-neutral-700">({{countByQualification.NEW}})</span>
        </mat-checkbox>
        <mat-checkbox formControlName="HEALTH_ALERT">
          {{ 'ALERTS.QUALIFICATION.HEALTH_ALERT_PLURAL' | translate }}
          <span class="italic text-xs text-neutral-700">({{countByQualification.HEALTH_ALERT}})</span>
        </mat-checkbox>
        <mat-checkbox formControlName="THRESHOLD_ALERT">
          {{ 'ALERTS.QUALIFICATION.THRESHOLD_ALERT_PLURAL' | translate }}
          <span class="italic text-xs text-neutral-700">({{countByQualification.THRESHOLD_ALERT}})</span>
        </mat-checkbox>
        <mat-checkbox formControlName="UNCERTAIN_DATA">
          {{ 'ALERTS.QUALIFICATION.UNCERTAIN_DATA_PLURAL' | translate }}
          <span class="italic text-xs text-neutral-700">({{countByQualification.UNCERTAIN_DATA}})</span>
        </mat-checkbox>
        <mat-checkbox formControlName="SAMPLE_ERROR">
          {{ 'ALERTS.QUALIFICATION.SAMPLE_ERROR_PLURAL' | translate }}
          <span class="italic text-xs text-neutral-700">({{countByQualification.SAMPLE_ERROR}})</span>
        </mat-checkbox>
        <mat-checkbox formControlName="ABERENT_DATA">
          {{ 'ALERTS.QUALIFICATION.ABERENT_DATA_PLURAL' | translate }}
          <span class="italic text-xs text-neutral-700">({{countByQualification.ABERENT_DATA}})</span>
        </mat-checkbox>
      </section>
    </div>
  </form>
</div>
