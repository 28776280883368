import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Alert, AlertQualification } from '../../../../shared/alert.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../../../services/utils.service';
import { ApiService } from '../../../../services/api.service';
import { AuthService } from '../../../../services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { AlertBatch } from '../../../../shared/@types/alert/alert-types';

@Component({
  selector: 'app-validation-form',
  templateUrl: './validation-form.component.html',
  styleUrls: ['./validation-form.component.scss']
})
export class ValidationFormComponent implements OnDestroy {

  @Input() set config(config: Array<number> | Alert) {
    if (config === undefined) {
      return;
    }
    this._config = config;
    if (this.isAlert(this._config)) {
      this.canEdit = this._config.structureId === this.authService.structure.id && this.authService.isAdmin;
      if (this._config.qualification !== AlertQualification.NEW) {
        this.alertQualificationCtrl.setValue(this._config.qualification);
      }
      this.alertCommentCtrl.setValue(this._config.comment);
    } else {
      this.canEdit = true;
    }

    if(!this.canEdit) {
      this.alertQualificationCtrl.disable();
    }
  }
  @Output() alertUpdated = new EventEmitter<Alert>();
  @Output() alertBatchUpdated = new EventEmitter<AlertBatch>();

  alertForm: FormGroup;
  alertQualificationCtrl: FormControl;
  alertCommentCtrl: FormControl;

  qualificationOptions= [
    {
      value: AlertQualification.ABERENT_DATA,
      label: 'ALERTS.QUALIFICATION.ABERENT_DATA'
    },
    {
      value: AlertQualification.HEALTH_ALERT,
      label: 'ALERTS.QUALIFICATION.HEALTH_ALERT'
    },
    {
      value: AlertQualification.SAMPLE_ERROR,
      label: 'ALERTS.QUALIFICATION.SAMPLE_ERROR'
    },
    {
      value: AlertQualification.THRESHOLD_ALERT,
      label: 'ALERTS.QUALIFICATION.THRESHOLD_ALERT'
    },
    {
      value: AlertQualification.UNCERTAIN_DATA,
      label: 'ALERTS.QUALIFICATION.UNCERTAIN_DATA'
    }
  ];
  canEdit = false;
  private _config!: Array<number> | Alert;
  private destroy$ = new Subject<void>();

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  constructor(private fb: FormBuilder, private utilsService: UtilsService,
              private apiService: ApiService, private authService: AuthService) {

    this.alertQualificationCtrl = fb.control(undefined, Validators.required);
    this.alertCommentCtrl = fb.control('');

    this.alertForm = fb.group({
      alertQualification: this.alertQualificationCtrl,
      alertComment: this.alertCommentCtrl
    });
  }

  submit() {
    if (this.isAlert(this._config)) {
      const payload = Object.assign({}, this._config, {
        comment: this.alertCommentCtrl.value,
        qualification: this.alertQualificationCtrl.value,
      });
      this.utilsService.wrapObservable(this.apiService.alerts.update(this._config.id, payload), 'SUCCESS_MSG.UPDATE_ALERT', 'ERROR_MSG.UPDATE_ALERT')
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: alert => {
            this.alertUpdated.next(alert);
          },
          error: err => console.error(err)
        });
    } else {
      const payload: AlertBatch = {
        ids: this._config,
        comment: this.alertCommentCtrl.value,
        qualification: this.alertQualificationCtrl.value,
      };
      this.utilsService.wrapObservable(this.apiService.alerts.batchUpdate(payload), 'SUCCESS_MSG.UPDATE_ALERT', 'ERROR_MSG.UPDATE_ALERT')
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.alertBatchUpdated.next(payload);
          },
          error: err => console.error(err)
        });
    }
  }

  private isAlert(config: Array<number> | Alert): config is Alert {
    return (config as Alert).id !== undefined;
  }
}
