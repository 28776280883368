<div class="sensor-type-legend absolute bottom-0 right-0 mx-11 m-6 drop-shadow-2xl">
  <mat-expansion-panel
    class="min-w-60 h-fit"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="true"
  >
    <mat-expansion-panel-header>
        <h2 class="sensor-type-legend__title w-full text-center">{{ 'Legend' | translate }}</h2>
    </mat-expansion-panel-header>
    <div class="flex flex-col items-center gap-5">
      <p class="text-sm text-neutral-700">{{ 'SENSORS.FILTERS.SENSOR_TYPE' | translate }}</p>
      <div class="w-full">
        <div class="flex flex-row w-full justify-between items-center">
          <span class="mb-3 w-3 h-3 bg-sensor-underground"></span>
          <p>{{ 'SENSORS.FILTERS.TYPE.UNDERGROUND' | translate }}</p>
        </div>
        <div class="flex flex-row w-full justify-between items-center">
          <span class="mb-3 w-3 h-3 bg-sensor-meteo"></span>
          <p>{{ 'SENSORS.FILTERS.TYPE.WEATHER' | translate }}</p>
        </div>
        <div class="flex flex-row w-full justify-between items-center">
          <span class="mb-3 w-3 h-3 bg-sensor-surface"></span>
          <p>{{ 'SENSORS.FILTERS.TYPE.SURFACE' | translate }}</p>
        </div>
        <div class="flex flex-row w-full justify-between items-center">
          <span class="mb-3 w-3 h-3 bg-sensor-hydro-std"></span>
          <p>{{ 'SENSORS.FILTERS.TYPE.HYDRO_STD' | translate }}</p>
        </div>
        <div class="flex flex-row w-full justify-between items-center">
          <span class="mb-3 w-3 h-3 bg-sensor-hydro-deb"></span>
          <p>{{ 'SENSORS.FILTERS.TYPE.HYDRO_DEB' | translate }}</p>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
