import {
  AfterViewInit,
  Component,
  ElementRef,
  Input, OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import {
  SensorSynthesisDataOptions,
  StructureSynthesisDataOptions,
  SynthesisDataOptions,
  SynthesisFilter,
} from '../../../../shared/synthesis-component.model';
import { DataTarget } from '../../../../shared/enums.model';
import { forkJoin, map, mergeMap, Observable, of, Subject, tap } from 'rxjs';
import { EChartsService } from '../../../../services/e-charts.service';
import {
  DualGraphConfig,
  GraphType,
} from '../../../../shared/graph-wrapper.model';
import { UtilsService } from '../../../../services/utils.service';
import {
  DataStream,
  SensorThingsService,
  StsDataArrayResponse,
  StsUtilsService,
  TimeSeriesGraphComponent,
} from '@geomatys/ngx-core/sensor-things';
import { ApiService } from 'src/app/services/api.service';
import { RegisterSensorFilter } from '../../../../shared/sensor.model';
import { TreeGraphNode } from '../../../../shared/treeGraphNode.model';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-synthesis-data',
  templateUrl: './synthesis-data.component.html',
  styleUrls: ['./synthesis-data.component.scss'],
})
export class SynthesisDataComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() options!: SynthesisDataOptions;
  @ViewChild('nitrateSts') nitrateSts?: TimeSeriesGraphComponent;
  @ViewChild('graphWrapper') graphWrapper!: ElementRef;

  public waterProductionOptions!: Observable<EChartsOption>;
  public nitrateP90Options!: Observable<EChartsOption>;
  public sensorNitratesOptions!: Observable<EChartsOption>;
  public _nitrateGaugeOptions: Subject<EChartsOption> =
    new Subject<EChartsOption>();
  public nitrateGaugeOptions: Observable<EChartsOption> =
    this._nitrateGaugeOptions.asObservable();
  public _nitratesOptions: Subject<EChartsOption> =
    new Subject<EChartsOption>();
  public nitratesOptions: Observable<EChartsOption> =
    this._nitratesOptions.asObservable();
  public resourcePressureOptions!: Observable<EChartsOption>;
  public waterChemistryOptions!: Observable<[EChartsOption, EChartsOption]>;
  public waterChemistryGraphConfig!: DualGraphConfig;
  public mpOptions!: Observable<[EChartsOption, EChartsOption]>;
  public mpGraphConfig!: DualGraphConfig;
  public psTypeOptions!: Observable<[EChartsOption, EChartsOption]>;
  public psTypeGraphConfig!: DualGraphConfig;
  public structurePsOptions!: Observable<EChartsOption>;
  public sensorPsOptions!: Observable<[EChartsOption, EChartsOption]>;
  public sensorPsGraphConfig!: DualGraphConfig;
  public seasonalityOptions!: Observable<EChartsOption>;
  public substanceSummary!: Observable<EChartsOption>;
  public landUseOptions!: Observable<EChartsOption>;
  public agriculturalUseOptions!: Observable<EChartsOption>;

  public volume!: number;
  public year!: number;
  public selectedEntity?: string;
  public structureOptions: Array<{ id: number; name: string }> = [];
  public startDate?: DateTime;
  public endDate?: DateTime;
  public type = DataTarget;
  public startObsDate?: string;
  public endObsDate?: string;
  public graphColumns = 1;
  public nitrateDetailsParams: {
    year?: number;
    level?: string;
  } = { year: undefined, level: undefined };
  private readonly MIN_WINDOW_SIZE = 700; // in pixel

  public projectSupervisors: Array<{ name: string; acronym: string }> = [];
  public selectedSupervisor?: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stsService: SensorThingsService,
    private eChartsService: EChartsService,
    private apiService: ApiService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initComponent(this.options);
    this.loadProjectSupervisors();
  }

  loadProjectSupervisors(): void {
    const filters: Partial<RegisterSensorFilter> = {
      structIds: (this.selectedEntity && this.options.target === 'STRUCTURE') ? [+this.selectedEntity] : [],
      bacIds: (this.selectedEntity && this.options.target === 'BAC') ? [this.selectedEntity] : [],
      sensorIds: (this.selectedEntity && this.options.target === 'SENSOR') ? [this.selectedEntity] : [],
    };

    this.apiService.sensors.getAllProjectSupervisors(filters).subscribe(
      (supervisors) => {
        this.projectSupervisors = supervisors;
      },
      (error) => {
        console.error('Failed to load project supervisors', error);
      }
    );
  }

  ngAfterViewInit(): void {
    const graphWrapperObserver = new ResizeObserver((entries) => {
      const wrapper = entries[0];
      const nColumns = Math.floor(
        wrapper.contentRect.width / this.MIN_WINDOW_SIZE
      );
      if (nColumns !== this.graphColumns) {
        let maxCols;
        switch (this.options.target) {
          case DataTarget.SENSOR:
            maxCols = 3;
            break;
          case DataTarget.BAC:
            maxCols = 2;
            break;
          case DataTarget.STRUCTURE:
            maxCols = 4;
            break;
        }
        this.graphColumns = Math.min(nColumns, maxCols);
      }
    });
    graphWrapperObserver.observe(this.graphWrapper.nativeElement);
  }

  ngOnChanges($event: SimpleChanges): void {
    if ($event['options'] && !$event['options'].firstChange) {
      this.initComponent($event['options'].currentValue);
    }
  }

  public structureNitratesCallback = (params: any) => {
    const level = params.value[0];
    const year = Number(params.seriesName);
    if (
      year !== this.nitrateDetailsParams.year ||
      level !== this.nitrateDetailsParams.level
    ) {
      this.nitrateDetailsParams = { year, level };
    }
  };

  clearSupervisor(): void {
    this.selectedSupervisor = undefined;
    this.filterSynthesis();
  }

  public filterSynthesis() {
    const queryParams: SynthesisFilter = {};
    if (this.startDate) {
      UtilsService.applyDateFilter(queryParams, 'start', this.startDate);
    }
    if (this.endDate) {
      UtilsService.applyDateFilter(queryParams, 'end', this.endDate);
    }
    if (this.selectedSupervisor) {
      UtilsService.applyProjectSupervisorFilter(queryParams, this.selectedSupervisor);
    }

    this.router
      .navigate(['./'], {
        relativeTo: this.route,
        queryParams,
        skipLocationChange: false,
      })
      .then();
  }

  public exportGraph() {
    if (this.nitrateSts) {
      this.nitrateSts.saveAs();
    }
  }

  private initComponent(options: SynthesisDataOptions): void {
    const id = this.route.snapshot.params['id'];
    this.selectedEntity = id ? id : undefined;

    if (options.queryParams?.startDate) {
      this.startDate = DateTime.fromISO(options.queryParams.startDate).toUTC();
    } else {
      this.startDate = undefined;
    }

    if (options.queryParams?.endDate) {
      this.endDate = DateTime.fromISO(options.queryParams.endDate).toUTC();
    } else {
      this.endDate = undefined;
    }

    if (options.queryParams?.projectSupervisor) {
      this.selectedSupervisor = options.queryParams.projectSupervisor;
    } else {
      this.selectedSupervisor = undefined;
    }

    if (options.target === DataTarget.STRUCTURE) {
      this.structureOptions = options.availableStructures.map((s) => {
        return { name: s.name, id: s.id };
      });

      this.waterProductionOptions = options.waterProduction.pipe(
        tap((res) => {
          this.year = Number(res.groups[res.groups.length - 2]);
          this.volume = res.values.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue[currentValue.length - 2],
            0
          );
        }),
        map((res) => this.eChartsService.generateStackedLineGraph(res))
      );

      this.nitrateP90Options = options.nitrateP90.pipe(
        map((res) =>
          this.eChartsService.generateNP90MultiPieGraph(res, 'SYNTHESIS.NP90.')
        )
      );
      this.structurePsOptions = options.pspSubstanceDistribution.pipe(
        map((res) => {
          return this.eChartsService.generatePSBarGraph(res, undefined, 'X');
        })
      );

      this.generateWaterChemistryGraph(options);
      this.generatePSTypeGraph(options);
    }

    if (options.target !== DataTarget.BAC) {
      this.resourcePressureOptions = options.resourcePressure.pipe(
        map((res) => this.eChartsService.generateResourcePressureGraph(res))
      );
      this.generateMPGraph(options);
    }

    if (options.target === DataTarget.SENSOR) {
      this.generateSensorPSGraph(options);
      const url = './proxy/sts/v1.1/Datastreams';
      const sensorId = options.sensorId;
      const minDate = this.startDate?.toJSDate().toISOString();
      const maxDate = this.endDate?.toJSDate().toISOString();
      const thingRule = StsUtilsService.filterRuleFromValues('Thing/id', [
        sensorId,
      ]);
      const propertyRule = StsUtilsService.filterRuleFromValues(
        'ObservedProperty/id',
        ['1340']
      );
      const filter = StsUtilsService.createFilterFromRules([
        thingRule,
        propertyRule,
      ]);
      filter!.$filter = StsUtilsService.addDateRuleToFilter(
        { minDate, maxDate },
        filter!.$filter
      );
      const params = {
        ...filter,
        $orderby: 'phenomenonTime',
        $resultFormat: 'dataArray',
      };

      this.stsService
        .getStsUrl<DataStream>(url, params)
        .pipe(
          mergeMap((res) => {
            const dataStream = res.value[0];
            if (dataStream !== undefined) {
              return this.stsService.getStsUrl<StsDataArrayResponse>(
                dataStream['Observations@iot.navigationLink'],
                params
              );
            } else {
              return of({ value: [] });
            }
          })
        )
        .subscribe({
          next: (res) => {
            const dataArray = res.value[0];
            if (dataArray) {
              const values: Array<number> = dataArray.dataArray.map((d) =>
                Number(d[3])
              );
              const quantile = UtilsService.getQuantile(values, 0.9);
              const colorPalette = new Array(
                ...this.eChartsService.gaugePalette
              );
              this._nitrateGaugeOptions.next(
                this.eChartsService.generateGaugeGraph(quantile, colorPalette)
              );
              this._nitratesOptions.next(
                this.eChartsService.generateNitratesGraph(dataArray)
              );
            } else {
              this._nitrateGaugeOptions.error(new Error('no data'));
              this._nitratesOptions.error(new Error('no data'));
            }
          },
        });
    }

    if (options.target === DataTarget.BAC) {
      this.substanceSummary = options.substanceSummary.pipe(
        map((res) => this.eChartsService.generateSubstanceSummary(res))
      );
      this.year = options.waterProduction.year;
      this.volume = options.waterProduction.volume;

      this.landUseOptions = options.landUse.pipe(
        map((res) =>
          this.eChartsService.generateMultiPieGraph(res, 'SYNTHESIS.LAND_USE.')
        )
      );

      this.agriculturalUseOptions = options.agriculturalUse.pipe(
        map((res) => this.eChartsService.generateStackedLineGraph(res))
      );

      this.nitrateP90Options = options.nitrateP90.pipe(
        map((res) => this.eChartsService.generateBacNitratesBarGraph(res))
      );

      this.sensorNitratesOptions = options.nitrates.pipe(
        map((res) => {
          let minDate: number | undefined;
          let maxDate: number | undefined;
          for (const dta of res.dataArrays) {
            const dateIndex = dta.components.indexOf('resultTime');
            const min = new Date(dta.dataArray[0][dateIndex]).getTime() / 1000;
            if (minDate === undefined || min < minDate) {
              minDate = min;
            }
            const max =
              new Date(
                dta.dataArray[dta.dataArray.length - 1][dateIndex]
              ).getTime() / 1000;
            if (maxDate === undefined || max > maxDate) {
              maxDate = max;
            }
          }
          this.setMinMaxGlobalDates(minDate, maxDate);
          return this.eChartsService.generateBacNitratesGraph(res);
        })
      );
    }

    if (options.target !== DataTarget.STRUCTURE) {
      this.seasonalityOptions = options.seasonality.pipe(
        map((res) => this.eChartsService.generateSeasonalityGraph(res, 'fr'))
      );
    }
  }

  private generateWaterChemistryGraph(options: StructureSynthesisDataOptions): void {
    this.waterChemistryOptions = forkJoin([
      options.waterChemistryQuantificationFrequency.pipe(
        map(res => this.formatResutGraph(res, this.translateService.instant('SYNTHESIS.FAMILY_ALERT')))
      ),
      options.waterChemistryAlertFrequency.pipe(
        map(res => this.formatResutGraph(res, this.translateService.instant('SYNTHESIS.FAMILY_ALERT')))
      ),
    ]);

    this.waterChemistryGraphConfig = {
      type: GraphType.DUAL,
      options: [
        {
          value: 'quantification',
          label: 'SYNTHESIS.FAMILY_QUANTIFICATION',
        },
        {
          value: 'alert',
          label: 'SYNTHESIS.FAMILY_ALERT',
        },
      ],
      defaultValue: 'alert',
    };
  }

  private generateMPGraph(
    options: SensorSynthesisDataOptions | StructureSynthesisDataOptions
  ): void {
    this.mpOptions = forkJoin([
      options.mpQuantificationFrequency.pipe(
        map((res) => {
          const colorPalette = new Array(...this.eChartsService.alertPalette);
          this.setMinMaxGlobalDates(res.minDate, res.maxDate);
          return this.eChartsService.generateMPBarGraph(
            res,
            colorPalette,
            'Y',
            true
          );
        })
      ),
      options.mpAlertFrequency.pipe(
        map((res) => {
          const colorPalette = new Array(
            ...this.eChartsService.alertPalette
          ).reverse();
          return this.eChartsService.generateMPBarGraph(
            res,
            colorPalette,
            'Y',
            true
          );
        })
      ),
    ]);

    this.mpGraphConfig = {
      type: GraphType.DUAL,
      options: [
        {
          value: 'quantification',
          label: 'SYNTHESIS.NP90.QUANTIFICATION_FREQUENCY',
        },
        {
          value: 'alert',
          label: 'SYNTHESIS.NP90.ALERT_FREQUENCY',
        },
      ],
      defaultValue: 'alert',
    };
  }

  private generateSensorPSGraph(options: SensorSynthesisDataOptions): void {
    this.sensorPsOptions = forkJoin([
      options.psQuantificationFrequency.pipe(
        map((res) => {
          const colorPalette = new Array(...this.eChartsService.alertPalette);
          return this.eChartsService.generateSensorPSBarGraph(
            res,
            colorPalette,
            'Y',
            true
          );
        })
      ),
      options.psAlertFrequency.pipe(
        map((res) => {
          const colorPalette = new Array(
            ...this.eChartsService.alertPalette
          ).reverse();
          return this.eChartsService.generateSensorPSBarGraph(
            res,
            colorPalette,
            'Y',
            true
          );
        })
      ),
    ]);

    this.sensorPsGraphConfig = {
      type: GraphType.DUAL,
      options: [
        {
          value: 'quantification',
          label: 'SYNTHESIS.NP90.QUANTIFICATION_FREQUENCY',
        },
        {
          value: 'alert',
          label: 'SYNTHESIS.NP90.ALERT_FREQUENCY',
        },
      ],
      defaultValue: 'alert',
    };
  }

  private generatePSTypeGraph(options: StructureSynthesisDataOptions): void {
    this.psTypeOptions = forkJoin([
      options.psTypeQuantificationFrequency.pipe(
        map((res) => {
          return this.eChartsService.generatePSTypeRingGraph(
            res,
            'PS Quantification'
          );
        })
      ),
      options.psTypeAlertFrequency.pipe(
        map((res) => {
          return this.eChartsService.generatePSTypeRingGraph(
            res,
            'PS Detection'
          );
        })
      ),
    ]);

    this.psTypeGraphConfig = {
      type: GraphType.DUAL,
      options: [
        {
          value: 'quantification',
          label: 'SYNTHESIS.FAMILY_QUANTIFICATION',
        },
        {
          value: 'alert',
          label: 'SYNTHESIS.FAMILY_ALERT',
        },
      ],
      defaultValue: 'alert',
    };
  }

  private setMinMaxGlobalDates(
    minDate: number | undefined,
    maxDate: number | undefined
  ) {
    let minDateISO: DateTime;
    if (minDate) {
      minDateISO = DateTime.fromSeconds(minDate).toUTC();
      this.startObsDate = minDateISO.toJSDate().toLocaleDateString('fr-FR');
    }
    let maxDateISO: DateTime;
    if (maxDate) {
      maxDateISO = DateTime.fromSeconds(maxDate).toUTC();
      this.endObsDate = maxDateISO.toJSDate().toLocaleDateString('fr-FR');
    }
  }

  private formatResutGraph (res: TreeGraphNode[], label: string) {
    const treeGraphNodesWithoutEmptyValue = res.reduce((acc, curr) => {
      if (curr.value) {
        acc.push(curr);
      }
      return acc;
    }, [] as TreeGraphNode[]);

    return this.eChartsService.generateWaterChemistryTreemapGraph(
      treeGraphNodesWithoutEmptyValue.length ? res : treeGraphNodesWithoutEmptyValue,
      treeGraphNodesWithoutEmptyValue.length ? label : this.translateService.instant('MEASURES.NO_DATA'),
    );
  }
}
