import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { GraphWrapperConfig, InnerGraphType } from '../../../../shared/graph-wrapper-config.model';
import {
  ComputationConfig, DataStream,
  DataStreamGraphicalEntity,
  GroupDisplayMode,
  GroupGraphComponent,
  GroupGraphicalEntity,
  ProfileGraphComponent, SensorThingsService, StsDataArrayResponse,
  StsGroupType, StsUtilsService,
  TimeSeriesGraphComponent
} from '@geomatys/ngx-core/sensor-things';
import { init, EChartsOption, LineSeriesOption, SeriesOption } from 'echarts';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../../services/api.service';
import * as _ from 'lodash';
import { EChartsService } from '../../../../services/e-charts.service';
import { mergeMap, Observable, Subject, throwError } from 'rxjs';
import { Property } from '../../../../shared/property.model';
import { DatasetOption, TitleOption } from 'echarts/types/dist/shared';
import { AxisBaseOption } from 'echarts/types/src/coord/axisCommonTypes';
import { UtilsService } from '../../../../services/utils.service';
import { ErrorToast, ToastService } from '@geomatys/ngx-core/toast';
import { AppInitializerService } from '../../../../services/app-initializer.service';
import { SensorSummary } from '../../../../shared/sensor.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ZRColor } from 'echarts/types/src/util/types';


@Component({
  selector: 'app-sts-graph-wrapper',
  templateUrl: './sts-graph-wrapper.component.html',
  styleUrls: ['./sts-graph-wrapper.component.scss']
})
export class StsGraphWrapperComponent implements AfterViewInit {

  @Input() groupType: StsGroupType | undefined;
  @Input() isGrouped: boolean | undefined;
  public type!: InnerGraphType;
  public types = InnerGraphType;
  public displayMode = GroupDisplayMode;
  public graphOptions!: EChartsOption;
  public computedGraphOptions!: EChartsOption;
  public topperStyle: any;
  public wrapperStyle: any;
  public graphHeight!: number;
  public title!: string;
  public grouptitle!: string;
  public dtge: DataStreamGraphicalEntity | undefined;
  public gge: GroupGraphicalEntity | undefined;
  public graphDisplayMode = GroupDisplayMode.SEPARATED;
  public thresholdOptionSelected = false;
  public canShowThresholdOption!: boolean;
  public computationConfig: ComputationConfig = {
    size: 'day'
  };
  public uom = '';
  public hasDoubleAxis = false;
  public filteredOptions: Subject<Array<{ name: string, id: string }>> = new Subject();
  public sensorFilteredOptions: Subject<Array<{ name: string, id: string }>> = new Subject();
  public cachedOptions: Array<{ name: string, id: string }> = [];
  private sensorCachedOptions: Array<{ name: string, id: string }> = [];
  public secondAxisQueryStr = '';
  private propertyName = '';
  public secondaryAxisSensor: string = '';

  @ViewChild('graph') graph!: ProfileGraphComponent | TimeSeriesGraphComponent | GroupGraphComponent;

  @Input() set config(config: GraphWrapperConfig) {
    this.type = config.type;
    this.grouptitle = config.title;
    let yAxisFormatter;
    if (config.type !== InnerGraphType.MULTIPLE) {
      this.dtge = config.dataStreamGraphicalEntity;
      this.uom = this.dtge.dataStream.unitOfMeasurement.name as string;
      this.canShowThresholdOption = true;
      this.secondaryAxisSensor = this.dtge.thingEntity.thing.name;
      this.sensorCachedOptions = [{ name: this.dtge.thingEntity.thing.name, id: this.dtge.thingEntity.thing['@iot.id'] }];
      yAxisFormatter = '{value} ' + this.uom;
    } else {
      this.dtge = undefined;
      this.gge = config.groupGraphicalEntity;
      if (config.groupGraphicalEntity.groupType === StsGroupType.BY_PROPERTY) {
        this.canShowThresholdOption = true;
        yAxisFormatter = (value: any) => {
          const uoms = Array.from(this.gge!.uoms.values());
          return value + ' ' + uoms[0];
        };
      } else {
        yAxisFormatter = '{value} ' + this.uom;
      }
    }
    const specificOption: Partial<EChartsOption> = {
      title: {
        show: config.type !== InnerGraphType.MULTIPLE,
        top: 10,
        textStyle: {
          fontSize: '18px',
          fontFamily: 'Montserrat'
        }
      },
      grid: {
        left: 100,
        right: config.type !== InnerGraphType.MULTIPLE ? 100 : 10
      },
      axisPointer: {
        show: false
      },
      yAxis: {
        show: true,
        axisLabel: {
          formatter: yAxisFormatter
        }
      },
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: (params: any) => this.eChartsService.stsTooltipFormatter(params, this.uom)
      },
      series: {
        symbol: (params: any) => {
          const dqCode = params[4];
          const value = params[3];
          return this.eChartsService.stsSymbolShapeFormatter(value, dqCode);
        },
        symbolSize: (params) => {
          const dqCode = params[4];
          const value = params[3];
          return this.eChartsService.stsSymbolSizeFormatter(value, dqCode);
        }
      }
    };

    const specificComputedGraphOptions: Partial<EChartsOption> = {
      grid: {
        left: 100,
        right: config.type !== InnerGraphType.MULTIPLE ? 100 : 10
      },
      yAxis: {
        show: true,
        axisLabel: {
          formatter: '{value} ' + this.uom
        }
      },
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: (params: any) => this.eChartsService.stsTooltipFormatter(params, this.uom)
      },
      series: {
        symbolSize: 8,
        symbol: 'circle'
      }
    };

    // Warning : lodash merge all subsequent sources in first argument which will be modified since it is the target.
    // in order to have a clean target for each time merge is done, we define an empty object as the target.
    this.graphOptions = _.merge({}, config.graphOptions, specificOption);
    this.computedGraphOptions = _.merge({}, config.graphOptions, specificComputedGraphOptions);

    this.graphHeight = config.maxHeight - config.topperHeight;
    this.topperStyle = { height: config.topperHeight + 'px', display: 'flex', 'justify-content': 'space-between' };
    const style: {
      [key: string]: any
    } = config.graphWidth ? { 'width': config.graphWidth + 'px' } : { 'flex': '1 1 0' };
    style['height'] = config.maxHeight + 'px';
    if (config.gutter) {
      style['margin'] = `0 ${config.gutter}px`;
      style['padding'] = `${config.gutter}px 0`;
    }
    this.wrapperStyle = style;
    this.title = config.title;
  }
  constructor(private translateService: TranslateService, private apiService: ApiService, private eChartsService: EChartsService,
              private toastService: ToastService, private elRef: ElementRef, private stsService: SensorThingsService) {
  }

  ngAfterViewInit(): void {
    this.graph.echartsInstantiated$.subscribe({
      next: () => {
        const options: EChartsOption = this.graph.getOption() as EChartsOption;
        this.propertyName = (options.title as Array<TitleOption>)[0].text!;
        this.setTitle();
        const graphWrapperObserver = new ResizeObserver(() => {
          this.setTitle();
        });
        graphWrapperObserver.observe(this.elRef.nativeElement);

      }
    });
  }

  private getSinglePropertyTitle(canvas: HTMLCanvasElement, graphWidth: number): string {
    let titleText: string;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    const oldFont = context.font;
    const titleFont = 'bold 18px Montserrat';
    context.font = titleFont;
    const singleTitleWidth = context.measureText(this.propertyName).width;
    if (singleTitleWidth > graphWidth) {
      const maxChar = Math.round((graphWidth - 10) / singleTitleWidth * this.propertyName.length) - 3;
      titleText = this.propertyName.slice(0, maxChar) + '...';
    } else {
      titleText = this.propertyName;
    }
    context.font = oldFont;
    return titleText;
  }

  private getDoublePropertyTitle(canvas: HTMLCanvasElement, graphWidth: number): string {
    let titleText: string;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    const oldFont = context.font;
    const titleFont = 'bold 18px Montserrat';
    context.font = titleFont;
    const secondaryName = this.dtge?.thingEntity.thing.name !== this.secondaryAxisSensor ?
      `${this.secondAxisQueryStr} (${this.secondaryAxisSensor})` : this.secondAxisQueryStr;
    const completeTitle = this.propertyName + ' / ' + secondaryName;
    const completeTitleWidth = context.measureText(completeTitle).width;
    if (completeTitleWidth > graphWidth) {
      const separatorWidth = context.measureText(' / ').width;
      const propertyWidth = context.measureText(this.propertyName).width;
      const secondaryPropertyWidth = context.measureText(secondaryName).width;
      const ratio = (graphWidth - 10 - separatorWidth) / (propertyWidth + secondaryPropertyWidth);
      const maxCharProperty = Math.round(this.propertyName.length * ratio) - 3;
      const propertySubStr = this.propertyName.slice(0, maxCharProperty) + '...';
      const maxCharSecondaryProperty = Math.round(secondaryName.length * ratio) - 3;
      const secondaryPropertySubStr = secondaryName.slice(0, maxCharSecondaryProperty) + '...';
      titleText = `{a|${propertySubStr}} / {b|${secondaryPropertySubStr}}`;
    } else {
      titleText = `{a|${this.propertyName}} / {b|${secondaryName}}`;
    }
    context.font = oldFont;
    return titleText;
  }

  private setTitle(): void {
    const options: EChartsOption = this.graph.getOption() as EChartsOption;
    if (options == undefined) {
      return;
    }

    const title = (options.title as Array<TitleOption>)[0];
    const canvas = this.elRef.nativeElement.getElementsByTagName('canvas')[0];
    if (canvas !== undefined && title.text !== undefined) {
      const tokens = title.text.split(' / ');
      const graphWidth = this.elRef.nativeElement.clientWidth - 20;
      let titleText: string;
      if (tokens.length === 1) {
        // Single Axis
        titleText = this.getSinglePropertyTitle(canvas, graphWidth);
      } else {
        // Double Axes
        titleText = this.getDoublePropertyTitle(canvas, graphWidth);
      }
      title.text = titleText;
      this.graph.setOption(options, false);
    }
  }

  exportData(): void {
    if (this.graph) {
      this.graph.exportData();
    }
  }

  saveAs(): void {
    if (this.graph) {
      this.graph.saveAs();
    }
  }

  saveAsWithLegend(): void {
    if (this.graph) {
      this.saveLegendAsImage(this.grouptitle);
      this.graph.saveAs();
    }
  }
  
  saveLegendAsImage(grouptitle: string): void {
    if (!this.graph || !this.graph.echartsInstance) {
        console.error('ECharts instance not found');
        return;
    }
    const option = this.graph.echartsInstance.getOption() as EChartsOption;

    const tempDiv = document.createElement('div');
    tempDiv.style.width = '400px'; 
    tempDiv.style.height = '300px';
    tempDiv.style.position = 'absolute';
    tempDiv.style.left = '-9999px'; // Position it off-screen
    document.body.appendChild(tempDiv);

    const tempEchartsInstance = init(tempDiv);
    tempEchartsInstance.setOption(option);

    const indicesToRemove: number[] = []; 

    const filteredSeries = (option.series as any)!.filter((series: any, index: number) => {
        if (series.datasetIndex === undefined) {
            indicesToRemove.push(index);
        }
        //@ts-ignore
        return series.datasetIndex !== undefined;
    })
    .map((series: any) => {
        series.data = [];
        return series;
    });
    
    let newColor = option.color;
    if (Array.isArray(option.color)) {
        newColor = option.color.filter((_, index) => {
            return !indicesToRemove.includes(index); 
        });
    }
    
    tempEchartsInstance.setOption({
      title: { show: false },
      toolbox: { show: false },
      xAxis: Array.isArray(option.xAxis) ? option.xAxis.map(x => ({ ...x, show: false })) : { show: false },
      yAxis: Array.isArray(option.yAxis) ? option.yAxis.map(y => ({ ...y, show: false })) : { show: false },
      grid: Array.isArray(option.grid) ? option.grid.map(g => ({ ...g, show: false })) : { show: false },
      series: Array.isArray(option.series) ? filteredSeries : { data: [] },
      legend: { show: true },
      color:newColor
    }, { replaceMerge: ['series'] });

    const legendImage = tempEchartsInstance.getDataURL();

    const image = new Image();
    image.src = legendImage;

    image.onload = function() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = image.width;
        canvas.height = image.height;
        ctx!.drawImage(image, 0, 0);

        const imageData = ctx!.getImageData(0, 0, canvas.width, canvas.height);
        let top = canvas.height, bottom = 0, left = canvas.width, right = 0;
        for (let y = 0; y < canvas.height; y++) {
            for (let x = 0; x < canvas.width; x++) {
                const alpha = imageData.data[(y * canvas.width + x) * 4 + 3];
                if (alpha > 0) {
                    if (x < left) left = x;
                    if (x > right) right = x;
                    if (y < top) top = y;
                    if (y > bottom) bottom = y;
                }
            }
        }

        const croppedWidth = right - left;
        const croppedHeight = bottom - top;
        const croppedCanvas = document.createElement('canvas');
        const croppedCtx = croppedCanvas.getContext('2d');
        croppedCanvas.width = croppedWidth;
        croppedCanvas.height = croppedHeight;

        croppedCtx!.drawImage(
            canvas,
            left, top, croppedWidth, croppedHeight,
            0, 0, croppedWidth, croppedHeight
        );

        const croppedImage = croppedCanvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = croppedImage;
        a.download = `${grouptitle}_légende.png`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(legendImage);
            window.URL.revokeObjectURL(croppedImage);
            tempEchartsInstance.dispose();
            document.body.removeChild(tempDiv);
        }, 0);
    };
}


  setDisplayMode(mode: GroupDisplayMode): void {
    const hasThreshold = this.thresholdOptionSelected;

    const component = this.graph as GroupGraphComponent;
    component.displayMode = mode;
    this.graphDisplayMode = mode;

    if (hasThreshold) {
      this.thresholdOptionSelected = false;
    }
  }

  toggleThreshold(): void {

    const options = this.graph.getOption();
    if (options === undefined) {
      return;
    }

    this.thresholdOptionSelected = !this.thresholdOptionSelected;

    if (this.thresholdOptionSelected) {
      this.addThreshold(options);
    } else {
      this.removeThreshold(options);
    }
  }

  private addThreshold(options: any): void {
    const name = this.translateService.instant('MEASURES.THRESHOLD');
    let id: string;
    let sensorId: string | undefined;
    if (this.graph instanceof TimeSeriesGraphComponent) {
      id = this.graph.dataStreamGE.dataStream.ObservedProperty['@iot.id'];
      sensorId = this.graph.dataStreamGE.thingEntity.thing['@iot.id'];
    } else if (this.graph instanceof GroupGraphComponent) {
      id = this.graph.groupGraphicalEntity.id;
    } else {
      this.thresholdOptionSelected = false;
      return;
    }


    this.apiService.sts.getThreshold(id, sensorId)
      .subscribe({
        next: res => {
          const threshold = res.threshold;
          const xMin = options.xAxis[0].min;
          const xMax = options.xAxis[0].max;

          const thresholdSeries: SeriesOption = {
            symbol: 'none',
            name,
            emphasis: {
              scale: true,
              focus: 'series'
            },
            encode: {
              x: 0,
              y: 1,
              tooltip: [0, 1]
            },
            data: [[xMin, threshold], [xMax, threshold]],
            type: 'line',
            smooth: true,
            color: 'red',
            lineStyle: {
              width: 1
            },
            markLine: {
              data: [{
                yAxis: 0.02,
                lineStyle: {
                  type: 'solid',
                  color: 'black',
                  width: 10
                }
              }],
              label: {
                formatter: (params: any) => {
                  let out = params.value;
                  if (this.type === InnerGraphType.SINGLE) {
                    out += ` ${this.uom}`;
                  } else if (this.gge?.groupType === StsGroupType.BY_PROPERTY) {
                    const uoms = Array.from(this.gge!.uoms.values());
                    out += ` ${uoms[0]}`;
                  }
                  return out;
                }
              }
            }
          };
          options.series.push(thresholdSeries);

          this.graph.setOption(options, false);
        }
      });
  }

  private removeThreshold(options: any): void {
    const name = this.translateService.instant('MEASURES.THRESHOLD');
    const loaded = options.series as Array<SeriesOption>;
    const idx = loaded.findIndex(s => s.name === name);
    loaded.splice(idx, 1);
    options.series = loaded;
    this.graph.setOption(options, true);
  }

  public onSecondAxisClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.searchProperties();
  }

  public onSecondAxisKeyUp($event: KeyboardEvent): void {
    this.searchProperties();
  }

  private searchProperties(): void {
    let obs: Observable<Array<Property>>;
    if (this.secondAxisQueryStr && this.secondAxisQueryStr !== '') {
      obs = this.apiService.properties.searchByName(this.secondAxisQueryStr);
    } else {
      obs = this.apiService.properties.getAll();
    }
    obs.subscribe({
      next: res => {
        const options = res.map(i => {
          const id = i.propertyId;
          return { name: i.name, id };
        });
        options.sort((a, b) => a.name.localeCompare(b.name));
        this.cachedOptions = options;
        this.filteredOptions.next(options);
      }
    });
  }

  private searchSensors(): void {
    let obs: Observable<Array<SensorSummary>>;
    if (this.secondaryAxisSensor && this.secondaryAxisSensor !== '') {
      obs = this.apiService.sensors.searchByName(this.secondaryAxisSensor);
    } else {
      obs = this.apiService.sensors.search({ }, { pageSize: 500 });
    }
    obs.subscribe({
      next: res => {
        const options = res.map(s => ({ name: s.name, id: s.sensorId }));
        this.sensorCachedOptions = options;
        this.sensorFilteredOptions.next(options);
      }
    });
  }

  public removeDoubleAxis(): void {
    if(!this.hasDoubleAxis) {
      return;
    }
    const options: EChartsOption = this.graph.getOption() as EChartsOption;
    if (options === undefined) {
      return;
    }
    // Remove secondary dataset
    (options.dataset as Array<DatasetOption>).pop();

    // Remove secondary series
    (options.series as Array<LineSeriesOption>).pop();

    const yAxisArray = options.yAxis as Array<AxisBaseOption>;

    yAxisArray[0].axisLine = {
      show: true
    };

    // Remove secondary axis
    yAxisArray.pop();

    // Remove secondary title
    const title = (options.title as Array<TitleOption>)[0];
    const canvas = this.elRef.nativeElement.getElementsByTagName('canvas')[0];
    const graphWidth = this.elRef.nativeElement.clientWidth - 20;
    title.text = this.getSinglePropertyTitle(canvas, graphWidth);

    this.graph.setOption(options, true);
    this.secondAxisQueryStr = '';
    this.hasDoubleAxis = false;
  }

  public onSecondaryAxisSensorClick($event: MouseEvent): void {
    $event.stopPropagation();
    this.searchSensors();
  }

  public onSecondaryAxisSensorKeyUp($event: KeyboardEvent): void {
    this.searchSensors();
  }

  private addSecondaryGraph(): void {
    const selectedProperty = this.cachedOptions.filter(o => o.name === this.secondAxisQueryStr)[0];
    const selectedSensor = this.sensorCachedOptions.filter(o => o.name === this.secondaryAxisSensor)[0];
    if (selectedProperty !== undefined && selectedSensor !== undefined) {
      let uom = '';
      const sensorFilter = StsUtilsService.filterRuleFromValues('Thing/id', [selectedSensor.id]);
      const propertyFilter = StsUtilsService.filterRuleFromValues('ObservedProperty/id', [selectedProperty.id.toString()]);
      const filters = StsUtilsService.createFilterFromRules([sensorFilter, propertyFilter]);
      const params: { [key: string]: string } = {
        '$filter': filters!.$filter
      };
      this.stsService.getStsUrl<DataStream>('./proxy/sts/v1.1/Datastreams', params)
        .pipe(
          mergeMap(res => {
            const datastream = res.value[0];
            if (datastream === undefined) {
              const msg = 'No datastream found corresponding to property ' + this.secondAxisQueryStr;
              return throwError(() => new Error(msg));
            }
            uom = datastream.unitOfMeasurement.symbol;
            return this.stsService.getStsUrl<StsDataArrayResponse>(datastream['Observations@iot.navigationLink'], { '$resultFormat': 'dataArray' });
          })
        )
        .subscribe({
          next: res => {
            const dataArray = res.value[0];
            const options: EChartsOption = this.graph.getOption() as EChartsOption;
            if (options === undefined) {
              return;
            }
            // Add secondary dataset
            const dqIndex = dataArray.components.indexOf('resultQuality');
            const dataset: DatasetOption = {
              name: this.secondAxisQueryStr,
              source: dataArray.dataArray.map(row => {
                const dq = (row[dqIndex][0] as any).DQ_Result.code;
                row[dqIndex] = dq;
                return row;
              }),
              dimensions: dataArray.components,
            };
            (options.dataset as Array<DatasetOption>)[1] = dataset;

            const yAxisArray = options.yAxis as Array<AxisBaseOption>;

            yAxisArray[0].axisLine = {
              lineStyle: {
                color: UtilsService.echartsPalette[0]
              }
            };


            // Add secondary axis
            const yAxis = {
              type: 'value',
              axisLine: {
                show: true,
                lineStyle: {
                  color: UtilsService.echartsPalette[1]
                }
              },
              position: 'right',
              inverse: true,
              alignTicks: true,
              axisLabel: {
                formatter: '{value} ' + uom
              }
            } as AxisBaseOption;
            yAxisArray[1] = yAxis;

            // Add secondary series
            const series: LineSeriesOption = {
              name: this.secondAxisQueryStr,
              datasetIndex: 1,
              type: 'line',
              encode: {
                x: 'resultTime',
                y: 'result'
              },
              yAxisIndex: 1,
              tooltip: {
                formatter: (params: any) => this.eChartsService.stsTooltipFormatter(params, uom)
              },
              symbol: (params: any) => {
                const dqCode = params[4];
                const value = params[3];
                return this.eChartsService.stsSymbolShapeFormatter(value, dqCode);
              },
              symbolSize: (params) => {
                const dqCode = params[4];
                const value = params[3];
                return this.eChartsService.stsSymbolSizeFormatter(value, dqCode);
              }
            };
            (options.series as Array<LineSeriesOption>)[1] = series;

            // Add secondary title
            const title = (options.title as Array<TitleOption>)[0];
            const canvas = this.elRef.nativeElement.getElementsByTagName('canvas')[0];
            const graphWidth = this.elRef.nativeElement.clientWidth - 20;
            title.text = this.getDoublePropertyTitle(canvas, graphWidth);
            title.textStyle!.rich = {
              a: {
                fontSize: 18,
                fontWeight: 'bold',
                color: UtilsService.echartsPalette[0]
              },
              b: {
                fontSize: 18,
                fontWeight: 'bold',
                color: UtilsService.echartsPalette[1]
              }
            };

            this.graph.setOption(options, false);
            this.hasDoubleAxis = true;
          },
          error: err => {
            console.error(err);
            this.toastService.createToast(ErrorToast, { duration: AppInitializerService.CONFIG.toastDuration, text: this.translateService.instant('ERROR_MSG.NO_DATASTREAM', { property: this.secondAxisQueryStr}) });
            this.secondAxisQueryStr = '';
          }
        });
    }
  }

  public onSecondaryAxisOptionChange($event: MatAutocompleteSelectedEvent): void {
    this.addSecondaryGraph();
  }
}
