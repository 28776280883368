import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardModule } from '../dashboard/dashboard.module';
import { SensorsComponent } from './sensors.component';
import { SensorListComponent } from './components/sensor-list/sensor-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';
import { SearchInputModule } from '@geomatys/ngx-core/search-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SensorItemComponent } from './components/sensor-item/sensor-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DirectivesModule } from '@geomatys/ngx-core';
import { SensorFiltersMenuComponent } from './components/sensor-filters-menu/sensor-filters-menu.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePickerWrapperModule } from '../date-picker-wrapper/date-picker-wrapper.module';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TreeDisplayModule } from '../tree-display/tree-display.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { LayerControllerComponent } from './components/layer-controller/layer-controller.component';
import { LayerItemComponent } from './components/layer-item/layer-item.component';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSliderModule } from '@angular/material/slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddLayerComponent } from './components/add-layer/add-layer.component';
import { PipesModule } from '../../pipes/pipes.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from "@angular/material/button";


@NgModule({
  declarations: [
    SensorListComponent,
    SensorsComponent,
    SensorItemComponent,
    SensorFiltersMenuComponent,
    LayerControllerComponent,
    LayerItemComponent,
    AddLayerComponent
  ],
  imports: [
    CommonModule,
    DashboardModule,
    TranslateModule,
    FlexModule,
    SearchInputModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    DirectivesModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    DatePickerWrapperModule,
    MatSelectModule,
    MatTooltipModule,
    TreeDisplayModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatInputModule,
    FontAwesomeModule,
    MatSliderModule,
    DragDropModule,
    MatProgressSpinnerModule,
    PipesModule,
    ScrollingModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    SensorsComponent
  ]
})
export class SensorsModule {
}
