import { Injectable } from '@angular/core';
import {
  catchError,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  MeasuresResolverConfig,
  MeasuresResolverData,
  StructureMeasuresResolverConfig
} from '../shared/resolver-data.model';
import { DataTarget, DataType } from '../shared/enums.model';
import {
  DataStreamDataSource,
  DataStreamDataSourceConfig, DataStreamGraphicalEntity,
  GroupDataSource,
  GroupDataSourceConfig, SensorThingsService, StsGroupType,
  StsUtilsService
} from '@geomatys/ngx-core/sensor-things';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { SensorFiltersService } from './sensor-filters.service';
import { MeasureControlsOptions } from '../shared/measures-component.model';
import { ErrorToast, InfoToast, ToastService } from '@geomatys/ngx-core/toast';
import { AppInitializerService } from './app-initializer.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

export type DownloadContent = { fileName: string, file: string };
export type DownloadResult = DownloadContent[]

@Injectable({
  providedIn: 'root'
})
export class MeasuresService {

  private _measureUpdated: Subject<MeasuresResolverData> = new Subject();
  public measureUpdated: Observable<MeasuresResolverData>;

  private _groupingChanged: Subject<StsGroupType> = new Subject();
  public groupingChanged: Observable<StsGroupType>;

  private readonly TOAST_DURATION: number;
  private lastConfiguration!: MeasuresResolverConfig;

  constructor(private http: HttpClient, private stsService: SensorThingsService, private apiService: ApiService, private utilsService: UtilsService,
              private sensorFiltersService: SensorFiltersService, private translateService: TranslateService,
              private toastService: ToastService) {
    this.measureUpdated = this._measureUpdated.asObservable();
    this.groupingChanged = this._groupingChanged.asObservable();
    this.TOAST_DURATION = AppInitializerService.CONFIG.toastDuration;
  }

  public getResolverObservable(config: MeasuresResolverConfig): Observable<MeasuresResolverData> {
    this.lastConfiguration = config;
    const { pageSize, graphsPerLine, structureAcronym, ...filtersConfig } = config as StructureMeasuresResolverConfig;
    let filterOptions: MeasureControlsOptions;
    const usageObservable = this.apiService.properties.getUsages();

    return forkJoin([
      this.sensorFiltersService.parseSensorResolverConfig(filtersConfig, true),
      this.utilsService.wrapObservable(usageObservable, undefined, 'ERROR_MSG.GET_USAGE', [])
    ])
      .pipe(
        mergeMap(res => {
          const url = './proxy/sts/v1.1';
          const useDecimation = true;
          const usageParam: string = config.params?.['usage'];
          const categoryParam: string = config.params?.['category'];
          if (config.target === DataTarget.STRUCTURE) {
            filterOptions = Object.assign({}, res[0], {
              availableUsages: res[1],
              availableSensors: [],
              name: config.name,
              structureAcronym: config.structureAcronym
            });
          } else {
            filterOptions = Object.assign({}, res[0], {
              availableUsages: res[1],
              availableSensors: [],
              name: config.name
            }) as MeasureControlsOptions;
          }
          if (usageParam !== undefined) {
            filterOptions.selectedUsages = UtilsService.paramsToArray(usageParam);
          }
          if (categoryParam !== undefined) {
            filterOptions.selectedCategories = UtilsService.paramsToArray(categoryParam);
          }

          // Thing filters
          const thingRules = [];
          let thingIds;
          let bacIds;
          let structIds;

          switch (filterOptions.target) {
            case DataTarget.STRUCTURE:
              bacIds = filterOptions.selectedBacs?.map(sb => sb.id);
              thingIds = filterOptions.selectedSensors?.map(s => s.sensorId);
              structIds = [structureAcronym];
              break;
            case DataTarget.BAC:
              bacIds = [filterOptions.bacId];
              thingIds = filterOptions.selectedSensors?.map(s => s.sensorId);
              break;
            case DataTarget.SENSOR:
              thingIds = [filterOptions.sensorId];
              break;
          }

          if (thingIds && thingIds.length > 0) {
            thingRules.push(StsUtilsService.filterRuleFromValues('Thing/id', thingIds));
          }

          if (bacIds && bacIds.length > 0) {
            thingRules.push(StsUtilsService.filterRuleFromValues('Thing/properties/bac', bacIds));
          }

          if (structIds && structIds.length > 0) {
            thingRules.push(StsUtilsService.filterRuleFromValues('Thing/properties/structure', structIds));
          }

          if (filterOptions.selectedState !== undefined && filterOptions.selectedState.length > 0) {
            const values = filterOptions.selectedState.map(a => a === 'active' ? 'actif' : 'inactif');
            const condition = StsUtilsService.filterRuleFromValues('Thing/properties/state', values);
            thingRules.push(condition);
          }

          if (filterOptions.selectedProjectSupervisor !== undefined && filterOptions.selectedProjectSupervisor.length > 0) {
            const condition = StsUtilsService.filterRuleFromValues('Thing/properties/project-supervisor', filterOptions.selectedProjectSupervisor);
            thingRules.push(condition);
          }

          // Property filters
          const propertyIds = filterOptions.selectedObservedProperties?.map(sop => sop.propertyId);
          const propertyRules = [];

          if (propertyIds && propertyIds.length > 0) {
            propertyRules.push(StsUtilsService.filterRuleFromValues('ObservedProperty/id', propertyIds));
          }

          if (filterOptions.selectedFamilies !== undefined && filterOptions.selectedFamilies.length > 0) {
            const families = filterOptions.selectedFamilies.map(sf => sf.trim());
            propertyRules.push(StsUtilsService.filterRuleFromValues('ObservedProperty/properties/family', families));
          }

          if (filterOptions.selectedAuthorization !== undefined && filterOptions.selectedAuthorization.length > 0) {
            const values = filterOptions.selectedAuthorization.map(a => a === 'authorized' ? 'INSCRITE' : 'NON_INSCRITE');
            const condition = StsUtilsService.filterRuleFromValues('ObservedProperty/properties/authorization', values);
            propertyRules.push(condition);
          }

          if (filterOptions.selectedUsages !== undefined && filterOptions.selectedUsages.length > 0) {
            propertyRules.push(StsUtilsService.filterRuleFromValues('ObservedProperty/properties/usages', filterOptions.selectedUsages));
          }

          if (filterOptions.selectedCategories !== undefined && filterOptions.selectedCategories.length > 0) {
            propertyRules.push(StsUtilsService.filterRuleFromValues('ObservedProperty/properties/category', filterOptions.selectedCategories));
          }

          // DataStream filters
          const dataStreamRules = [];
          if (filterOptions.selectedResultQuality !== undefined && filterOptions.selectedResultQuality.length > 0) {
            const values = filterOptions.selectedResultQuality.map(q => Number(q));
            const condition: string = StsUtilsService.filterRuleFromValues('Observations/result.code_remarque', values);
            dataStreamRules.push(condition);
          }

          if (filterOptions.groupType === undefined) {
            filterOptions.groupType = StsGroupType.NONE;
          }

          let dataSourceConfigObs: Observable<DataStreamDataSourceConfig | GroupDataSourceConfig>;

          switch (filterOptions.groupType) {
            case StsGroupType.NONE:
              const rules = StsUtilsService.createFilterFromRules([...thingRules, ...propertyRules, ...dataStreamRules])?.$filter;
              dataSourceConfigObs = this.stsService.getDataStreamDSConfig(url, useDecimation, undefined, graphsPerLine, pageSize, rules, undefined, undefined, filterOptions.startDate, filterOptions.endDate);
              break;
            case StsGroupType.BY_SENSOR:
            case StsGroupType.BY_PROPERTY:
              dataSourceConfigObs = this.stsService.getGroupDSConfig(filterOptions.groupType, url, useDecimation, undefined, graphsPerLine, pageSize, thingRules, propertyRules, dataStreamRules, filterOptions.startDate, filterOptions.endDate);
          }

          const registerFilter = UtilsService.sensorFilterComponentOptionsToRegisterFilter(res[0], true);
          const sensorsObservable = this.utilsService.wrapObservable(this.apiService.sensors.search(registerFilter, { pageSize: 200000 }), undefined, 'ERROR_MSG.GET_SENSORS', []);

          return forkJoin([
            this.utilsService.wrapObservable(dataSourceConfigObs, undefined, 'ERROR_MSG.DATA_SOURCE'),
            this.utilsService.wrapObservable(sensorsObservable, undefined, undefined, [])
          ]);
        }),
        map(res => {
          const dataSourceConfig = res[0];
          const summaries = res[1];

          filterOptions.availableSensors = res[1].map(s => ({ name: s.name, id: s.sensorId }));

          // Possible refactor here. These two properties are used in the sensor-filters-menu.component. The problem is that, for the filtering purposes,
          // in the sensor-filters-menu component we should use the register and here the datastreams (now we use register for both). If we stay with register for both
          // we could move the globalDates into the BaseSensorFilterOptions.
          // ToDo: We stay with register for globalDates => REFACTOR
          const { globalStartDate, globalEndDate } = UtilsService.getMinMaxGlobalDates(summaries);
          dataSourceConfig.globalStartDate = globalStartDate;
          dataSourceConfig.globalEndDate = globalEndDate;

          let dataSource: DataStreamDataSource | GroupDataSource;

          switch (filterOptions.groupType) {
            default:
            case StsGroupType.NONE:
              const dataStreamDSConfig = dataSourceConfig as DataStreamDataSourceConfig;
              dataSource = this.stsService.createDataStreamDataSource(dataStreamDSConfig);
              break;
            case StsGroupType.BY_SENSOR:
              const sensorGroupDSConfig = dataSourceConfig as GroupDataSourceConfig;
              dataSource = this.stsService.createGroupDataSource(sensorGroupDSConfig);
              break;
            case StsGroupType.BY_PROPERTY:
              const propertyGroupDSConfig = dataSourceConfig as GroupDataSourceConfig;
              dataSource = this.stsService.createGroupDataSource(propertyGroupDSConfig);
              break;
          }

          const complement: {
            dataSource: DataStreamDataSource | GroupDataSource,
            type: DataType.MEASURES
          } = {
            dataSource,
            type: DataType.MEASURES
          };

          return Object.assign({}, filterOptions, complement);
        })
      );
  }

  public updateMeasures(config: MeasuresResolverConfig): void {
    this.lastConfiguration = config;
    this.getResolverObservable(config)
      .subscribe({
        next: res => this._measureUpdated.next(res),
        error: err => {
          const text = this.translateService.instant('ERROR_MSG.GET_MEASURES');
          this.toastService.createToast(ErrorToast, { text, duration: this.TOAST_DURATION });
          console.error(err);
        }
      });
  }

  public updateGroupType(option: StsGroupType, mustReload: boolean): void {
    if (mustReload) {
      this.lastConfiguration.params = Object.assign({}, this.lastConfiguration.params, { groupType: option });
      this.updateMeasures(this.lastConfiguration);
    }
    this._groupingChanged.next(option);
  }

  getDataSourceWithEdilabo$(dataSource: DataStreamDataSource): Observable<DownloadResult> {
    const toObservable = (dsge: DataStreamGraphicalEntity) => this.downloadEdilaboFile$(dsge)
      .pipe(map(fileContent => ({
        fileName: `${dsge.thingEntity.thing.name} - ${dsge.dataStream.ObservedProperty.name}.csv`,
        file: fileContent,
      })));
    const observables: Observable<DownloadContent>[] = dataSource.data.flat().filter(ds => ds !== undefined).map(dsge => toObservable(dsge));
    return forkJoin(observables);
  }

  private downloadEdilaboFile$(dsge: DataStreamGraphicalEntity): Observable<string> {
    return this.http.get(dsge.dataStream['Observations@iot.navigationLink'], {
      params: {
        orderBy: 'phenomenonTime',
        $resultFormat: 'edilabo'
      },
      responseType: 'text'
    })
      .pipe(catchError(err => {
        this.toastService.createToast(ErrorToast, { text: 'Error', duration: this.TOAST_DURATION });
        console.error('Error');
        console.error(err);
        return of('');
      }));
  }

}
