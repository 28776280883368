import { Component, Input, OnInit } from '@angular/core';
import { MeasureDataOptions } from '../../../../shared/measures-component.model';
import { EChartsOption } from 'echarts';
import {
  DataStreamDataSource,
  GroupDataSource,
  SensorThingsService,
  StsGroupType
} from '@geomatys/ngx-core/sensor-things';
import { ViewportLayout } from '@geomatys/ngx-core/responsive-virtual-scroller/src/viewport-layout.model';
import { AppInitializerService } from '../../../../services/app-initializer.service';
import { catchError, EMPTY, forkJoin, mergeMap, of } from 'rxjs';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ErrorToast, InfoToast, ToastService } from '@geomatys/ngx-core/toast';
import { TranslateService } from '@ngx-translate/core';
import { DownloadResult, MeasuresService } from '../../../../services/measures.service';
import { RegisterSensorFilter } from '../../../../shared/sensor.model';
import { ActivatedRoute, Router } from '@angular/router';
import { EChartsService } from '../../../../services/e-charts.service';

@Component({
  selector: 'app-measures-data',
  templateUrl: './measures-data.component.html',
  styleUrls: ['./measures-data.component.scss']
})
export class MeasuresDataComponent implements OnInit {

  resultCount = 0;
  public GRAPH_HEIGHT = 400;
  public TOPPER_HEIGHT = 40;
  public GUTTER = 10;
  public ds: DataStreamDataSource | undefined;
  public sensorGroupDs: GroupDataSource | undefined;
  public propertyGroupDs: GroupDataSource | undefined;
  public graphOptions: Partial<EChartsOption> = {
    toolbox: [],
    xAxis: {
      axisLabel: {
        formatter: (value: number) => this.echartsService.timeAxisLabelFormatter(value)
      }
    },
    yAxis: {
      min: 0
    },
    series: {
      type: 'scatter'
    }
  };
  public viewportLayout: ViewportLayout;
  public groupingOption = StsGroupType;
  public selectedGroupingOption!: StsGroupType;
  public reload: number = new Date().getTime();
  private readonly TOAST_DURATION;
  private _options!: MeasureDataOptions;
  private groupingChanged = false;

  get options(): MeasureDataOptions {
    return this._options;
  }

  @Input() set options(value: MeasureDataOptions) {
    this._options = value;
    // @ts-expect-error we should expose max item
    this.resultCount = value.dataSource._maxItems;
    if (this._options.groupType) {
      this.selectedGroupingOption = this._options.groupType;
    } else {
      this.selectedGroupingOption = StsGroupType.NONE;
    }

    if (!this.groupingChanged) {
      this.ds = undefined;
      this.sensorGroupDs = undefined;
      this.propertyGroupDs = undefined;
    }
    // In the case that the reload was triggered by a change of grouping we reset the groupingChanged value
    this.groupingChanged = false;

    switch (this.selectedGroupingOption) {
      case StsGroupType.NONE as StsGroupType:
        this.ds = value.dataSource as DataStreamDataSource;
        break;
      case StsGroupType.BY_SENSOR as StsGroupType:
        this.sensorGroupDs = value.dataSource as GroupDataSource;
        break;
      case StsGroupType.BY_PROPERTY as StsGroupType:
        this.propertyGroupDs = value.dataSource as GroupDataSource;
        break;
    }
    this.reload = new Date().getTime();
  }

  constructor(private route: ActivatedRoute, private router: Router, private stsService: SensorThingsService, private toastService: ToastService,
              private translateService: TranslateService, private measureService: MeasuresService, private echartsService: EChartsService) {
    const itemSize = this.GRAPH_HEIGHT + this.TOPPER_HEIGHT + 2 * this.GUTTER;
    this.viewportLayout = {
      itemSize,
      maxBufferPx: itemSize * 5,
      minBufferPx: itemSize
    };
    this.TOAST_DURATION = AppInitializerService.CONFIG.toastDuration;
  }

  ngOnInit(): void {
    this.measureService.measureUpdated.subscribe({
      next: value => {
        const { dataSource, ...filterOptions } = value;
        this.options = { target: value.target, dataSource, groupType: value.groupType };
      }
    });
  }

  download() {
    let creationToast: InfoToast;
    let errorText = 'Error';
    forkJoin([this.translateService.get('MEASURES.EXPORTING'), this.translateService.get('MEASURES.EXPORT_ERROR')])
      .pipe(
        catchError(() => of(['Exporting', 'Error'])),
        mergeMap(([text, errorTextRes]) => {
          errorText = errorTextRes;
          creationToast = this.toastService.createToast(InfoToast, { text, duration: this.TOAST_DURATION });
          switch (this.selectedGroupingOption) {
            case StsGroupType.NONE:
              if (this.ds) {
                return this.measureService.getDataSourceWithEdilabo$(this.ds);
              }
              return EMPTY;
            case StsGroupType.BY_SENSOR:
              return this.stsService.downloadGroupedGraphs(this.sensorGroupDs!);
            case StsGroupType.BY_PROPERTY:
              return this.stsService.downloadGroupedGraphs(this.propertyGroupDs!);
          }
        })
      )
      .subscribe({
        next: res => {
          this.createZipFromResult(res, creationToast);
        },
        error: err => {
          creationToast.close();
          this.toastService.createToast(ErrorToast, { text: errorText, duration: this.TOAST_DURATION });
          console.error(errorText);
          console.error(err);
        }
      });
  }

  public setGrouping(option: StsGroupType) {
    if (option !== this.selectedGroupingOption) {
      this.selectedGroupingOption = option;
      this.groupingChanged = true;
      const queryParams: RegisterSensorFilter & { groupType?: StsGroupType } = {};

      if (option) {
        queryParams.groupType = option;
      } else {
        queryParams.groupType = StsGroupType.NONE;
      }

      this.router.navigate(['./'], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
        skipLocationChange: false
      }).then(() => {
        const mustReload = (option === StsGroupType.NONE && this.ds === undefined) ||
          (option === StsGroupType.BY_PROPERTY && this.propertyGroupDs === undefined) ||
          (option === StsGroupType.BY_SENSOR && this.sensorGroupDs === undefined);
        this.measureService.updateGroupType(option, mustReload);
      });
    }
  }

  private createZipFromResult(res: DownloadResult | {
    fileNames: Array<string>;
    files: Array<string>;
  }, creationToast: InfoToast): void {
    const zip = new JSZip();
    if (this.selectedGroupingOption === StsGroupType.NONE) {
      (res as DownloadResult).forEach(({ fileName, file }) => {
        zip.file(fileName, file);
      });
    } else {
      const result = res as {
        fileNames: Array<string>;
        files: Array<string>;
      };
      for (const [index, fileName] of result.fileNames.entries()) {
        zip.file(fileName, result.files[index]);
      }
    }

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      creationToast.close();
      saveAs(content, 'Aqualit.zip');
    });
  }

}
