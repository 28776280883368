import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DashboardComponent } from '../../../dashboard/dashboard.component';
import { SensorFilterOptions, SensorSummary } from '../../../../shared/sensor.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl, FormGroup } from '@angular/forms';
import {
  combineLatest,
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import { UtilsService } from '../../../../services/utils.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SensorBillboardService } from '../../../../services/sensor-billboard.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SensorFiltersService } from '../../../../services/sensor-filters.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ViewerService } from '../../../../services/viewer.service';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.scss']
})
export class SensorListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() parent!: DashboardComponent;
  @Input() filterOptions!: SensorFilterOptions;
  @Input() countSensors!: number;
  @Input() sensorNoFilter!: Array<SensorSummary>;
  @ViewChild(MatMenuTrigger) triggerBtn!: MatMenuTrigger;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;
  query = '';
  globalStartDate!: string;
  globalEndDate!: string;
  form = new FormGroup({
    sensorQuery: new FormControl()
  });

  // This can be replaced by signal input in future version and so remove ngOnChanges impl
  private sensorInputChanged$ = new Subject<void>();

  filteredOptions$: Observable<Array<{ name: string, id: string }>> = combineLatest([
    this.form.get('sensorQuery')!.valueChanges.pipe(startWith('')),
    this.sensorInputChanged$.pipe(startWith(undefined)),
  ])
    .pipe(
      map(([sensorQuery, _]) => sensorQuery),
      map(value => {
        const target = value.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
        return this.sensorNoFilter
          .filter(s => {
            const normalizedName = s.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
            const normalizedBss = s.sensorId.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
            return normalizedName.indexOf(target) !== -1 || normalizedBss.indexOf(target) !== -1;
          })
          .map(s => {
            const normalizedName = s.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
            if (normalizedName.indexOf(target) !== -1) {
              return { name: s.name, id: s.sensorId };
            } else {
              return { name: s.sensorId, id: s.sensorId };
            }
          });
      })
    );

  private destroy$ = new Subject<void>();
  private _sensors!: Array<SensorSummary>;
  private sensorSelected = this.billboardService.sensorSelected;
  private filterQueryParams: Params = this.route.snapshot.queryParams;

  public get sensors(): Array<SensorSummary> {
    return this._sensors;
  }

  @Input() set sensors(sensors: Array<SensorSummary>) {
    this._sensors = sensors;
    this.initComponent();
  }

  constructor(
    private billboardService: SensorBillboardService,
    private sensorFilterService: SensorFiltersService,
    private route: ActivatedRoute,
    private viewerService: ViewerService
  ) {
  }

  ngOnInit(): void {
    this.sensorSelected.pipe(takeUntil(this.destroy$)).subscribe({
      next: evt => {
        if (evt.trigger === 'map') {
          const index = this._sensors.findIndex(s => s.sensorId === evt.sensorId);
          if (index !== -1) {
            this.viewPort.scrollToIndex(index, 'smooth');
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['sensorNoFilter'].firstChange) {
      this.sensorInputChanged$.next();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCloseMenu() {
    if (this.triggerBtn) {
      this.triggerBtn.closeMenu();
    }
  }

  onQueryParams(queryParams: Params) {
    this.filterQueryParams = queryParams;
  }

  reset() {
    this.form?.get('sensorQuery')?.setValue('');
    this.billboardService.selectSensor(undefined, 'map');

    if ('sensorName' in this.filterOptions && this.filterOptions.sensorName) {
      this.filterOptions.sensorName = '';
    }

    if ('sensorName' in this.filterQueryParams && this.filterQueryParams['sensorName']) {
      delete this.filterQueryParams['sensorName'];
    }

    this.sensorFilterService.goToFilteredMeasures(this.filterOptions, this.filterQueryParams);
  }

  search() {
    if (!this.form.get('sensorQuery')?.value) {
      return this.reset();
    }
    const queryParams = {...this.filterQueryParams, sensorName: this.form.get('sensorQuery')?.value};
    this.viewerService.flyToEntityGeometry();
    this.sensorFilterService.goToFilteredMeasures(this.filterOptions, queryParams);
    this.autocompleteTrigger.closePanel();
  }

  private initComponent(): void {
    const { globalStartDate, globalEndDate } = UtilsService.getMinMaxGlobalDates(this._sensors);
    this.globalStartDate = globalStartDate;
    this.globalEndDate = globalEndDate;

    if (this.billboardService.selectedSensor !== undefined) {
      // We need some time before the virtual scroller is updated
      // ToDo: find a better way to do this
      setTimeout(() => {
        const index = this._sensors.findIndex(s => s.sensorId === this.billboardService.selectedSensor);
        if (index !== -1) {
          this.viewPort.scrollToIndex(index, 'smooth');
        }
      }, 100);
    }
  }

}
