<div id="alert-container" fxLayout="column">
  <div id="title" fxLayout="column">
    <h1>{{ title | translate }}</h1>
    <div class="flex flex-row gap-12 mb-10">
      <div class="flex flex-row justify-center items-center gap-2.5">
        <mat-icon class="icon on" svgIcon="log-au-alert-on"></mat-icon>
        <p class="alert-desc">{{ currentAlerts }} {{ currentAlertsMsg | translate }}</p>
      </div>
      <div class="flex flex-row justify-center items-center gap-2.5">
        <mat-icon class="icon" svgIcon="log-au-alert"></mat-icon>
        <p class="alert-desc">{{ archivedAlerts }} {{ archivedAlertsMsg | translate }}</p>
      </div>
      <button (click)="batchUpdate()" *ngIf="canBatchUpdate"
              [matTooltip]="'ALERTS.TITLE_QUALIFICATION_SELECTION' | translate"
              class="validate-button">{{ 'ALERTS.TITLE_BUTTON_QUALIFICATION' | translate }}
      </button>
    </div>
    <div matSort (matSortChange)="sortData($event)" matSortActive="sampleDate" matSortDirection="desc"
         class="grid grid-cols-[2%_15%_15%_25%_30%_13%] w-full items-center justify-center text-center mb-7">
      <mat-checkbox
        (ngModelChange)="updateAllAlertSelection($event)"
        [(indeterminate)]="alertSelectionIndeterminate"
        [ngModel]="allAlertsSelected"
        matTooltip="{{'SELECT_ALL' | translate}}"
        *ngIf="canValidateAlerts"
        class="justify-self-start"
      >
      </mat-checkbox>
      <div mat-sort-header="sampleDate" class="flex justify-center items-center text-neutral-700">
        {{ 'DATE' | translate }}
      </div>
      <div mat-sort-header="sensor.structure.name" class="flex justify-center items-center text-neutral-700">
        {{ 'STRUCTURE' | translate | titlecase }}
      </div>
      <div mat-sort-header="sensor.name" class="flex justify-center items-center text-neutral-700">
        {{ 'MEASURES.SENSOR_SELECT' | translate }}
      </div>
      <div mat-sort-header="property.property.name" class="flex justify-center items-center text-neutral-700">
        {{ 'MEASURES.PROPERTY' | translate }}
      </div>
      <div mat-sort-header="value" class="flex justify-center items-center text-neutral-700">
        {{ 'VALUE' | translate }}
      </div>
    </div>
    <mat-divider></mat-divider>

  </div>
  <cdk-virtual-scroll-viewport id="alert-list" [itemSize]="itemSize" appendOnly>
    <ng-container *cdkVirtualFor="let alert of sortedData ?? ds">
      <app-alert-item [alert]="alert" (alertUpdated)="onAlertUpdated($event)" *ngIf="alert"
                      (alertSelectionChanged)="onAlertSelectionChanged($event, alert.id)"
                      [checked]="selectedAlerts.has(alert.id) || allAlertsSelected"></app-alert-item>
      <div class="load-msg" *ngIf="alert===undefined">
        <h1>{{ 'LOADING' | translate }}...</h1>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>

  <div id="overlay-controls" class="flex flex-col justify-end items-start">
    <div class="control" (click)="download()">
      <mat-icon class="icon" svgIcon="log-au-download"></mat-icon>
    </div>
  </div>
</div>

