import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EChartsOption } from 'echarts';
import { GraphConfig, GraphType } from '../../shared/graph-wrapper.model';
import { ECharts } from 'echarts';
import { DatasetOption } from 'echarts/types/dist/shared';

@Component({
  selector: 'app-graph-wrapper',
  templateUrl: './graph-wrapper.component.html',
  styleUrls: ['./graph-wrapper.component.scss']
})
export class GraphWrapperComponent implements OnInit, OnChanges {

  state: 'loading' | 'ok' | 'error' | 'no-data' = 'loading';
  options?: EChartsOption;
  radioButtonSelection?: string;
  radioButtonOptions?: { [key: string]: EChartsOption };
  graphType = GraphType;
  sliderSelection!: number;
  sliderOptions?: Array<EChartsOption>;
  sliderMax!: number;
  echartsInstance!: ECharts;
  private subscription?: Subscription;


  @Input() graphConfig!: GraphConfig;
  @Input() source!: Observable<EChartsOption | Array<EChartsOption>>;
  @Input() info!: string;
  @Input() mouseoverCallback: ((params: any) => void) | undefined;

  ngOnInit(): void {
    this.subscription = this.initComponent(this.source);
  }

  ngOnChanges($event: SimpleChanges): void {
    if ($event['source'] && !$event['source'].firstChange) {
      this.state = 'loading';
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.initComponent($event['source'].currentValue);
    }
  }

  exportGraph() {
    const image = this.echartsInstance.getDataURL();
    const a = document.createElement('a');
    a.href = image;
    a.download = 'Aqualit.png';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(image);
    }, 0);
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    if (this.mouseoverCallback !== undefined) {
      ec.on('mouseover', 'series', (params) => {
        this.mouseoverCallback!(params);
      });
    }
  }

  private initComponent(graphSource: Observable<EChartsOption | Array<EChartsOption>>): Subscription {
    return graphSource.subscribe({
      next: value => {
        this.state = 'ok';
        if (Array.isArray(value)) {
          if (this.graphConfig.type === GraphType.DUAL) {
            this.radioButtonOptions = this.handleDualGraph(value);
            this.setState(this.radioButtonOptions);
          } else if (this.graphConfig.type === GraphType.MULTI) {
            this.sliderOptions = this.handleMultiGraph(value);
            this.setState(this.sliderOptions);
          }
        } else {
          this.options = this.handleSingleGraph(value);
          this.setState(this.options);
        }
      },
      error: err => {
        this.state = err.message === 'no data' ? 'no-data' : 'error';
      }
    });
  }

  private setState(handleGraphValue: EChartsOption | EChartsOption[] | {
    [key: string]: EChartsOption
  } | undefined): void {
    this.state = handleGraphValue ? 'ok' : 'no-data';
  }

  private handleSingleGraph(value: EChartsOption): EChartsOption | undefined {
    const dataset = value.dataset as DatasetOption;
    if (value.timeline?.data?.length || (dataset && Array.isArray(dataset.source) && dataset.source.length)) {
      return value;
    }

    // Alerts graphs case
    if (Array.isArray(value.dataset)) {
      const alertsDataset = value.dataset[0] as DatasetOption;
      if (alertsDataset && Array.isArray(alertsDataset.source) && alertsDataset.source.length) {
        return value;
      }
    }

    //graphs with selected bac case
    if (value.series && (Array.isArray(value.series) || value.series.data && Array.isArray(value.series.data))) {
      return value;
    }

    return undefined;
  }

  private handleDualGraph(value: Array<EChartsOption>): { [key: string]: EChartsOption } | undefined {
    // need to redo the test to avoid compiling errors
    if (this.graphConfig.type === GraphType.DUAL) {
      const dataset = value[0].dataset as DatasetOption;

      if (dataset && Array.isArray(dataset.source) && dataset.source.length) {
        this.radioButtonSelection = this.graphConfig.defaultValue;
        return {
          [this.graphConfig.options[0].value]: value[0],
          [this.graphConfig.options[1].value]: value[1],
        };
      }
      const series = value[0].series;

      if (series && Array.isArray(series)) {
        this.radioButtonSelection = this.graphConfig.defaultValue;
        return {
          [this.graphConfig.options[0].value]: value[0],
          [this.graphConfig.options[1].value]: value[1],
        };
      }

    }
    return undefined;
  }

  private handleMultiGraph(value: Array<EChartsOption>): Array<EChartsOption> | undefined {
    this.sliderSelection = 0;
    this.sliderMax = value.length - 1;
    return value;
  }
}
