import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SensorFilterOptions, SensorSummary } from '../../shared/sensor.model';
import { SensorMapResolverData } from '../../shared/resolver-data.model';
import { SensorBillboardService } from '../../services/sensor-billboard.service';
import { SensorFiltersService } from '../../services/sensor-filters.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss']
})
export class SensorsComponent implements OnInit, OnDestroy {

  countSensors = 0;
  public sensors!: Array<SensorSummary>;
  public sensorsNoFilter!: Array<SensorSummary>;
  public filterOptions!: SensorFilterOptions;
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private sensorBillboardService: SensorBillboardService,
    private sensorFilterService: SensorFiltersService
  ) {
  }

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe({
      next: res => {
        const data: SensorMapResolverData = res['data'];
        this.initComponent(data);
      }
    });

    this.sensorFilterService.sensorListUpdated.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => this.initComponent(value)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initComponent(data: SensorMapResolverData): void {
    const { summaries, allSensors, ...filterOptions } = data;
    this.sensors = summaries;
    this.sensorsNoFilter = allSensors;
    this.countSensors = allSensors.length;

    this.filterOptions = filterOptions;
    const locations = summaries
      .map(s => {
        return {
          coordinates: [s.lon, s.lat] as [number, number],
          id: s.sensorId,
          name: s.name
        };
      });
    this.sensorBillboardService.updateBillboards(locations);
  }

}
