<cdk-virtual-scroll-viewport #responsiveDsScroller id="ds-virtual-scroll-viewport"
                             [itemSize]="viewportLayout.itemSize"
                             [minBufferPx]="viewportLayout.minBufferPx"
                             [maxBufferPx]="viewportLayout.maxBufferPx">

  <div *ngIf="resultCount" class="mx-2.5 py-3 text-slate-500 ">
    <span>{{ resultCount }} {{(resultCount > 1 ? 'MEASURES.RESULTS' : 'MEASURES.RESULT') | translate | lowercase }} </span>
  </div>

  <ng-container *cdkVirtualFor="let groupDataSources of dataSource">
    <mat-grid-list class="graph-wrapper" [cols]="dataSource.itemsPerLine" *ngIf="groupDataSources" [rowHeight]="viewportLayout.itemSize + 'px'">
      <mat-grid-tile *ngFor="let gds of groupDataSources" colspan="1">
        <app-sts-graph-wrapper [config]="{ maxHeight: graphHeight!,
                                       gutter: gutter,
                                       topperHeight: topperHeight,
                                       graphOptions: groupGraphOptions,
                                       title: gds.name,
                                       type: 1,
                                       groupGraphicalEntity: gds }"
                                       [isGrouped]="isGrouped">
        </app-sts-graph-wrapper>
        <ng-container *ngIf="!gds">
        </ng-container>
      </mat-grid-tile>
    </mat-grid-list>
  </ng-container>
</cdk-virtual-scroll-viewport>
<div *ngIf="isLoading()" class="loader">
  <mat-spinner></mat-spinner>
  <h1>{{'LOADING' | translate}}</h1>
</div>
<div *ngIf="!resultCount" class="loader">
  <mat-icon class="error-icon">warning_amber</mat-icon>
  <h1>{{'MEASURES.NO_DATA' | translate}}</h1>
</div>
