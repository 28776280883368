import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { get as getProjection, transform } from 'ol/proj';
import { PerimeterOptions } from '../../shared/mini-map.model';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON, KML } from 'ol/format';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { WMTS } from 'ol/source';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { getTopLeft, getWidth } from 'ol/extent';
import { DataTarget } from '../../shared/enums.model';
import { Layer } from 'ol/layer';
import { SensorType } from '../../shared/sensor.model';
import { SensorBillboardService } from '../../services/sensor-billboard.service';
import { SynthesisControlOptions } from '../../shared/synthesis-component.model';

@Component({
  selector: 'app-mini-map',
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.scss']
})
export class MiniMapComponent implements AfterViewInit {

  @Input() set options(options: SynthesisControlOptions) {
    this.perimeter = options.perimeterOptions;
    this.target = options.target;
    this.targetType = options.tree?.sensorType ?? 'underground';
    this.setUpMap();
  }

  @ViewChild('olMap') olMap: ElementRef | undefined;
  private perimeter: PerimeterOptions | undefined;
  private target!: DataTarget;
  private targetType?: SensorType;
  private map: Map | undefined;
  private layer: Layer | undefined;

  constructor(private billboardService: SensorBillboardService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const projection = getProjection('EPSG:3857');
      const projectionExtent = projection!.getExtent();
      const size = getWidth(projectionExtent) / 256;
      const maxZoom = 21;
      const resolutions = new Array(maxZoom);
      const matrixIds = new Array(maxZoom);
      for (let z = 0; z < maxZoom; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = z;
      }

      this.map = new Map({
        view: new View({
          center: [0, 0],
          zoom: 1
        }),
        layers: [
          new TileLayer({
            source: new WMTS({
              url: 'https://data.geopf.fr/wmts?',
              layer: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
              matrixSet: 'PM',
              format: 'image/png',
              projection: 'EPSG:3857',
              tileGrid: new WMTSTileGrid({
                origin: getTopLeft(projectionExtent),
                resolutions: resolutions,
                matrixIds: matrixIds
              }),
              style: 'normal',
              wrapX: true
            })
          })
        ],
        target: 'ol-map'
      });

      this.setUpMap();
    }, 100);
  }

  private setUpMap(): void {
    if (this.map && this.perimeter) {

      if (this.layer) {
        this.map.removeLayer(this.layer);
      }

      const format = this.perimeter.type === 'kml' ? new KML({ extractStyles: false }) : new GeoJSON();
      const vectorSource = new VectorSource({
        url: 'data:,' + encodeURIComponent(this.perimeter.geometry),
        format
      });

      if (this.target === DataTarget.SENSOR) {
        this.layer = new VectorLayer({
          source: vectorSource,
          style: new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              src: this.targetType ? this.billboardService.getSensorImgByType(this.targetType) : this.billboardService.getDefaultSensorImg()
            })
          })
        });
      } else {
        this.layer = new VectorLayer({
          source: vectorSource,
          style: new Style({
            fill: new Fill({
              color: 'rgba(0, 162, 226, 0.5)'
            }),
            stroke: new Stroke({
              color: '#00a2e2'
            })
          })
        });
      }

      this.map.addLayer(this.layer);

      vectorSource.once('change', (e) => {
        if (vectorSource.getState() === 'ready') {
          if (this.target === DataTarget.SENSOR) {
            const geojson = JSON.parse(this.perimeter?.geometry);
            this.map!.getView().setCenter(transform(geojson.coordinates, 'EPSG:4326', 'EPSG:3857'));
            this.map!.getView().setZoom(15);
          } else {
            const extent = vectorSource.getExtent();
            // @ts-ignore
            this.map.getView().fit(extent, this.map.getSize());
          }
        }
      });
    }
  }

}
