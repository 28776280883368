<app-single-graph-scroller *ngIf="ds && selectedGroupingOption===groupingOption.NONE"
                           [viewportLayout]="viewportLayout"
                           [dataSource]="ds"
                           [topperHeight]="TOPPER_HEIGHT"
                           [graphOptions]="graphOptions"
                           [gutter]="GUTTER"
                           [reload]="reload">
</app-single-graph-scroller>
<app-group-graph-scroller *ngIf="sensorGroupDs && selectedGroupingOption===groupingOption.BY_SENSOR"
                          [viewportLayout]="viewportLayout"
                          [dataSource]="sensorGroupDs"
                          [topperHeight]="TOPPER_HEIGHT"
                          [graphOptions]="graphOptions"
                          [gutter]="GUTTER"
                          [reload]="reload">
</app-group-graph-scroller>
<app-group-graph-scroller *ngIf="propertyGroupDs && selectedGroupingOption===groupingOption.BY_PROPERTY"
                          [viewportLayout]="viewportLayout"
                          [dataSource]="propertyGroupDs"
                          [topperHeight]="TOPPER_HEIGHT"
                          [graphOptions]="graphOptions"
                          [gutter]="GUTTER"
                          [reload]="reload">
</app-group-graph-scroller>
<div id="overlay-controls" class="flex flex-col items-start justify-end gap-2.5 mt-8">
  <div [matTooltipDisabled]="!resultCount" matTooltip='{{ "DOWNLOAD" | translate }}' matTooltipPosition="left">
    <button mat-mini-fab [ngClass]="{'active': resultCount > 0}"
            [disabled]="!resultCount"
            class="control"
            aria-label="download all graphs button"
            (click)="download()"
    >
      <mat-icon class="icon" svgIcon="log-au-download"></mat-icon>
    </button>
  </div>
  <div class="control active">
    <mat-icon class="icon" svgIcon="log-au-parameters" [matMenuTriggerFor]="groupingOptions" matTooltip='{{ "PARAMETERS" | translate }}' matTooltipPosition="left"></mat-icon>
    <mat-menu #groupingOptions id="measures-grouping-options">
      <div id="grouping-options">
        <p class="grouping-option" [ngClass]="{'selected': selectedGroupingOption===groupingOption.NONE}" (click)="setGrouping(groupingOption.NONE)">
          {{'MEASURES.GROUPING.NONE' | translate}}
        </p>
        <p class="grouping-option" [ngClass]="{'selected': selectedGroupingOption===groupingOption.BY_SENSOR}" (click)="setGrouping(groupingOption.BY_SENSOR)">
          {{'MEASURES.GROUPING.GROUP_BY_SENSOR' | translate}}
        </p>
        <p class="grouping-option" [ngClass]="{'selected': selectedGroupingOption===groupingOption.BY_PROPERTY}" (click)="setGrouping(groupingOption.BY_PROPERTY)">
          {{'MEASURES.GROUPING.GROUP_BY_PROPERTY' | translate}}
        </p>
      </div>
    </mat-menu>
  </div>
</div>
