<div class="flex flex-col gap-5" id="container">
  <h1>{{ 'SENSORS.LIST.TITLE' | translate }}</h1>
  <div>
    <form [formGroup]="form" (keydown.enter)="search()" class="flex flex-row items-center justify-center gap-5">
      <gco-search-input>
        <input
          [matAutocomplete]="auto"
          class="input"
          formControlName="sensorQuery"
          gcoInputRef
          placeholder="{{ 'SEARCH_PLACEHOLDER' | translate }}">
        <img (click)="reset()" alt="Clear" class="clear-icon clear-button" src="assets/icon_xmark.svg">
        <img (click)="search()" alt="Rechercher" class="search-icon search-button" src="assets/icon_recherche.svg">
      </gco-search-input>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)='search()'>
        <mat-option *ngFor="let option of filteredOptions$ | async" [matTooltip]="option.name" [value]="option.name"
                    matTooltipPosition="right">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
      <button mat-icon-button aria-label="filter button" class="hover:bg-neutral-200"
              [matTooltip]="'SENSORS.FILTERS.TITLE' | translate">
        <img class="w-6 mx-auto" [matMenuTriggerFor]="sensorFilters" alt="Filtrer" src="assets/icon_filtres.svg">
        <mat-menu #sensorFilters class="sensor-filters-menu">
          <app-sensor-filters-menu
            (click)="$event.stopPropagation()"
            (closeMenu)="onCloseMenu()"
            (queryParam)="onQueryParams($event)"
            [globalEndDate]="globalEndDate"
            [globalStartDate]="globalStartDate"
            [options]="filterOptions">
          </app-sensor-filters-menu>
        </mat-menu>
      </button>
    </form>
    <div class="flex justify-center items-center mx-2.5 py-3 text-neutral-400">
      <span *ngIf="sensors.length">
        {{ sensors.length }}
        / {{ countSensors }}
        {{ (countSensors > 1 ? 'MEASURES.RESULTS' : 'MEASURES.RESULT') | translate | lowercase }}
      </span>
      <span *ngIf="!sensors.length">
        {{ 'MEASURES.NO_DATA' | translate }}
      </span>
    </div>
  </div>
  <cdk-virtual-scroll-viewport fxFlex="100" id="list-container" itemSize="110">
    <div class="flex flex-col">
      <app-sensor-item *ngFor="let item of sensors" [summary]="item"></app-sensor-item>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
