import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertsResolverData } from '../../shared/resolver-data.model';
import { AlertsControlOptions } from '../../shared/@types/alert/alert-filters';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  public controlOptions!: AlertsControlOptions;
  public dataOptions!: AlertsResolverData;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.data
      .subscribe({
        next: res => {
          const data: AlertsResolverData = res['data'];
          this.controlOptions = {
            target: data.alertDatasourceConfig.target,
            params: data.params,
            dateRange: data.dateRange,
            availableSensors: data?.availableSensors,
            availableProperties: data?.availableProperties
          };
          this.dataOptions = data;
        }
      });
  }

}
