import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Alert, AlertQualification } from '../../../../shared/alert.model';
import { DateTime } from 'luxon';
import { AlertsService } from '../../../../services/alerts.service';
import { Observable } from 'rxjs';
import { EChartsOption } from 'echarts';
import { AlertGraphSource } from '../../classes/alert-graph-source';
import { EChartsService } from '../../../../services/e-charts.service';
import { AuthService } from '../../../../services/auth.service';
import { AlertUpdatedEvent } from '../../../../shared/@types/alert/alert-filters';

@Component({
  selector: 'app-alert-item',
  templateUrl: './alert-item.component.html',
  styleUrls: ['./alert-item.component.scss']
})
export class AlertItemComponent {

  @Input() set alert(alert: Alert) {
    this._alert = alert;
    this.graphSource = new AlertGraphSource(this.alertsService, this._alert, this.eChartsService);
    this.graphOptions = this.graphSource.source;
    this.initComponent();
  }

  @Input() set checked(value: boolean) {
    this._checked = value;
  }

  @Output() alertUpdated = new EventEmitter<AlertUpdatedEvent>();
  @Output() alertSelectionChanged = new EventEmitter<boolean>();

  graphOptions!: Observable<EChartsOption>;
  icon!: string;
  iconColor!: string;
  iconSize!: string;
  iconMargin!: string;
  date!: string;
  structure!: string;
  sensor!: string;
  substance!: string;
  open = false;
  chevronIcon = 'log-au-chevron-down';
  canValidateAlerts = false;

  private graphSource!: AlertGraphSource;
  private _checked = false;
  private _alert!: Alert;

  public get checked(): boolean {
    return this._checked;
  }

  public get alert(): Alert {
    return this._alert;
  }

  constructor(
    private alertsService: AlertsService,
    private eChartsService: EChartsService,
    private authService: AuthService) {
  }

  private initComponent(): void {
    const date: DateTime = DateTime.fromSeconds(this._alert.sampleDate);
    this.date = date.toFormat('yyyy/MM/dd');
    this.structure = this._alert.structureName;
    this.sensor = this._alert.sensorName;
    this.substance = this._alert.paramName;
    this.canValidateAlerts = this.authService.isAdmin && this._alert.structureId === this.authService.structure.id;

    // TODO probably review these icons for better specific icon due to alert qualification refactoring
    if (this._alert.qualification === AlertQualification.NEW) {
      this.icon = 'log-au-alert-full-on';
      this.iconColor = '#f39200';
      this.iconSize = '36px';
      this.iconMargin = '26px';
    } else if (this._alert.qualification === AlertQualification.UNCERTAIN_DATA) {
      this.icon = 'log-au-remove';
      this.iconColor = '#000000';
      this.iconSize = '40px';
      this.iconMargin = '21px';
    } else if (this._alert.qualification === AlertQualification.HEALTH_ALERT || this._alert.qualification === AlertQualification.THRESHOLD_ALERT) {
      this.icon = 'log-au-sanitary-alert';
      this.iconColor = '#a8056d';
      this.iconSize = '30px';
      this.iconMargin = '29px';

      // this._alert.qualification === AlertQualification.ABERENT_DATA || this._alert.qualification === AlertQualification.SAMPLE_ERROR
    } else {
      this.icon = 'log-au-x-mark';
      this.iconColor = '#000000';
      this.iconSize = '24px';
      this.iconMargin = '32px';
    }
  }

  togglePanel() {
    this.open = !this.open;
    this.chevronIcon = this.open ? 'log-au-chevron-up' : 'log-au-chevron-down';
  }

  public onSelectionChange($event: boolean): void {
    this.alertSelectionChanged.next($event);
  }

  public onAlertUpdated(alert: Alert): void {
    const event: AlertUpdatedEvent = { oldVal: this._alert.qualification, newVal: alert.qualification };
    // Instead of doing this._alert = alert we assign only the fields that could potentially change because we want
    // to keep the reference to the this._alert object
    this._alert.qualification = alert.qualification;
    this._alert.comment = alert.comment;
    this.graphSource.update();
    this.initComponent();
    this.alertUpdated.next(event);
  }
}
