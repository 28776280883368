import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Alert, AlertQualification, AlertSearchFilter, AlertStats, AlertSubscription } from '../shared/alert.model';
import { forkJoin, map, Observable, of } from 'rxjs';
import { PageableOptions } from '../shared/pageable';
import { HttpResponse } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { DataTarget, DataType } from '../shared/enums.model';
import { AlertDataSourceConfig } from '../shared/alert-data-source-config';
import { SensorThingsService, ThingEntity } from '@geomatys/ngx-core/sensor-things';
import { AlertsResolverConfig, AlertsResolverData } from '../shared/resolver-data.model';
import { AuthService } from './auth.service';
import { DateRange } from '../shared/date-range';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  private maxPages = 0;
  private currentPage = 0;
  private maxAlerts = 0;

  private readonly firstPageOptions: PageableOptions = {
    offset: 0,
    pageNumber: 0,
    pageSize: 1
  };

  constructor(private apiService: ApiService, private utilsService: UtilsService, private stsService: SensorThingsService,
              private authService: AuthService, private translateService: TranslateService) {
  }

  public getAll(pageableOptions: PageableOptions, filters: AlertSearchFilter | undefined): Observable<Array<Alert>> {
    const obs = this.apiService.alerts.getAll(pageableOptions, filters)
      .pipe(
        map(res => this.initPaging(res))
      );
    return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.GET_ALERTS', []);
  }

  public getByStructureId(structureId: number, pageableOptions: PageableOptions, filters: AlertSearchFilter | undefined): Observable<Array<Alert>> {
    const obs = this.apiService.alerts.getByStructureId(structureId, pageableOptions, filters)
      .pipe(
        map(res => this.initPaging(res))
      );
    return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.GET_ALERTS', []);
  }

  public getByBacId(bacId: string, pageableOptions: PageableOptions, filters: AlertSearchFilter | undefined): Observable<Array<Alert>> {
    const obs = this.apiService.alerts.getByBacId(bacId, pageableOptions, filters)
      .pipe(
        map(res => this.initPaging(res))
      );
    return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.GET_ALERTS', []);
  }

  public getBySensorId(sensorId: string, pageableOptions: PageableOptions, filters: AlertSearchFilter | undefined): Observable<Array<Alert>> {
    const obs = this.apiService.alerts.getBySensorId(sensorId, pageableOptions, filters)
      .pipe(
        map(res => this.initPaging(res))
      );
    return this.utilsService.wrapObservable(obs, undefined, 'ERROR_MSG.GET_ALERTS', []);
  }

  public getThingEntityForAlert(sensorCode: string): Observable<ThingEntity> {
    const url = './proxy/sts/v1.1';
    return this.stsService.createThingEntity(url, sensorCode, true, undefined);
  }

  public getStatistics(config: AlertDataSourceConfig, filters: AlertSearchFilter | undefined): Observable<AlertStats> {
    const newAlertsFilter: AlertSearchFilter = { qualification: [AlertQualification.NEW] };
    let archivedAlertsFilter: AlertSearchFilter = { qualification: [AlertQualification.ABERENT_DATA,
                                                                    AlertQualification.HEALTH_ALERT, 
                                                                    AlertQualification.SAMPLE_ERROR, 
                                                                    AlertQualification.THRESHOLD_ALERT,
                                                                    AlertQualification.UNCERTAIN_DATA] };

    let searchNewAlerts = true;
    let searchArchivedAlerts = true;
    if (filters?.qualification !== undefined) {
      if (Array.isArray(filters.qualification)) {
        searchNewAlerts = filters.qualification.indexOf(AlertQualification.NEW) !== -1;
        archivedAlertsFilter = {};
        const qualification = filters.qualification.filter(q => q !== AlertQualification.NEW);
        if (qualification.length > 0) {
          archivedAlertsFilter.qualification = qualification;
        } else {
          searchArchivedAlerts = false;
        }
      } else {
        if (filters.qualification !== AlertQualification.NEW) {
          searchNewAlerts = false;
          archivedAlertsFilter.qualification = [filters.qualification];
        } else {
          searchArchivedAlerts = false;
        }
      }
    }

    if (filters?.startDate) {
      newAlertsFilter.startDate = filters.startDate;
      archivedAlertsFilter.startDate = filters.startDate;
    }

    if (filters?.endDate) {
      newAlertsFilter.endDate = filters.endDate;
      archivedAlertsFilter.endDate = filters.endDate;
    }

    let obsNewAlerts: Observable<HttpResponse<Array<Alert>> | undefined> = of(undefined);
    let obsArchivedAlerts: Observable<HttpResponse<Array<Alert>> | undefined> = of(undefined);

    if (searchNewAlerts) {
      switch (config.target) {
        case DataTarget.STRUCTURE:
          obsNewAlerts = config.id ? this.apiService.alerts.getByStructureId(config.id, this.firstPageOptions, newAlertsFilter) :
            this.apiService.alerts.getAll(this.firstPageOptions, newAlertsFilter);
          break;
        case DataTarget.BAC:
          obsNewAlerts = this.apiService.alerts.getByBacId(config.id, this.firstPageOptions, newAlertsFilter);
          break;
        case DataTarget.SENSOR:
          obsNewAlerts = this.apiService.alerts.getBySensorId(config.id, this.firstPageOptions, newAlertsFilter);
          break;

      }
    }
    if (searchArchivedAlerts) {
      switch (config.target) {
        case DataTarget.STRUCTURE:
          obsArchivedAlerts = config.id ? this.apiService.alerts.getByStructureId(config.id, this.firstPageOptions, archivedAlertsFilter) :
            this.apiService.alerts.getAll(this.firstPageOptions, archivedAlertsFilter);
          break;
        case DataTarget.BAC:
          obsArchivedAlerts = this.apiService.alerts.getByBacId(config.id, this.firstPageOptions, archivedAlertsFilter);
          break;
        case DataTarget.SENSOR:
          obsArchivedAlerts = this.apiService.alerts.getBySensorId(config.id, this.firstPageOptions, archivedAlertsFilter);
          break;

      }
    }

    return forkJoin([obsNewAlerts, obsArchivedAlerts])
      .pipe(
        map(res => {
          const out: AlertStats = {
            new: 0,
            archived: 0
          };

          if (res[0]?.body) {
            out.new = Number(res[0].headers.get('X-Total-Count'));
          }

          if (res[1]?.body) {
            out.archived = Number(res[1].headers.get('X-Total-Count'));
          }

          return out;
        })
      );
  }

  public getResolverObservable(config: AlertsResolverConfig): Observable<AlertsResolverData> {
    let alertDatasourceConfig: AlertDataSourceConfig;
    let alertSubscription: AlertSubscription;
    const userId = this.authService.user.id;
    const dateRangeFilter: AlertSearchFilter = { };
    switch (config.target) {
      case DataTarget.STRUCTURE:
        alertSubscription = { userId, structureId: config.structureId.toString() };
        const structureId = config.structureId === 'all' ? undefined : config.structureId;
        alertDatasourceConfig = {
          target: config.target,
          alertsService: this,
          length: 1,
          pageSize: 1,
          id: structureId
        };
        dateRangeFilter.structureId = structureId;
        break;
      case DataTarget.BAC:
        alertDatasourceConfig = {
          target: config.target,
          alertsService: this,
          length: 1,
          pageSize: 1,
          id: config.bacId
        };
        alertSubscription = { userId, bacId: config.bacId };
        dateRangeFilter.bacId = config.bacId;
        break;
      case DataTarget.SENSOR:
        alertDatasourceConfig = {
          target: config.target,
          alertsService: this,
          length: 1,
          pageSize: 1,
          id: config.sensorId
        };
        alertSubscription = { userId, sensorId: config.sensorId };
        dateRangeFilter.sensorId = config.sensorId;
        break;
    }

    Object.assign(dateRangeFilter, config.params);

    const dateRangeDefault: DateRange = {
      minDate: this.translateService.instant('DATE_FROM'),
      maxDate: this.translateService.instant('DATE_TO')
    };
    const statsObs = this.utilsService.wrapObservable(this.getStatistics(alertDatasourceConfig, config.params), undefined, 'ERROR_MSG.GET_ALERTS');
    const dateRangObs = this.utilsService.wrapObservable(this.getDateRange(dateRangeFilter), undefined, undefined, dateRangeDefault);

    return forkJoin([statsObs, dateRangObs])
      .pipe(
        map(res => {
          const stats = res[0];
          alertDatasourceConfig.length = stats.new + stats.archived;
          alertDatasourceConfig.pageSize = this.utilsService.CONFIG.alertsPageSize;
          return {
            type: DataType.ALERTS,
            alertDatasourceConfig,
            new: stats.new,
            archived: stats.archived,
            alertSubscription,
            params: config.params,
            dateRange: res[1]
          };
        })
      );
  }

  private getDateRange(filters: AlertSearchFilter | undefined): Observable<DateRange> {
    return this.apiService.alerts.getDateRange(filters)
      .pipe(
        map(res => {
          return {
            minDate: res.minDate ? new Date(res.minDate).toLocaleDateString('fr-Fr') : this.translateService.instant('DATE_FROM'),
            maxDate: res.maxDate ? new Date(res.maxDate).toLocaleDateString('fr-Fr') : this.translateService.instant('DATE_TO')
          };
        })
      );
  }

  private initPaging(httpRes: HttpResponse<Array<Alert>>): Array<Alert> {
    this.currentPage = 0;
    this.maxAlerts = Number(httpRes.headers.get('X-Total-Count'));
    this.maxPages = Math.ceil(this.maxAlerts / this.utilsService.CONFIG.alertsPageSize);
    return httpRes.body ? httpRes.body : [];
  }

}


