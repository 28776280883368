import { Injectable } from '@angular/core';
import { CesiumContainerService, GeomUtils } from '@geomatys/ngx-cesium';
import { Structure } from '../shared/structure.model';
import { BacSummary } from '../shared/bac.model';
import { SensorSummary } from '../shared/sensor.model';
import { Rectangle, Cartesian3, BoundingSphere } from 'cesium';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {
  private entity ?: Pick<Structure, 'id' | 'name' | 'geometry' | 'geometryContentType'> |
    Pick<BacSummary, 'id' | 'bacId' | 'name' | 'geometry' | 'geometryContentType'> |
    Pick<SensorSummary, 'id' | 'bacId' | 'name' | 'lon' | 'lat'>;


  constructor(
    private cesiumService: CesiumContainerService,
  ) {
  }

  flyToEntityGeometry(entity?: Pick<Structure, 'id' | 'name' | 'geometry' | 'geometryContentType'> |
    Pick<BacSummary, 'id' | 'bacId' | 'name' | 'geometry' | 'geometryContentType'> |
    Pick<SensorSummary, 'id' | 'bacId' | 'name' | 'lon' | 'lat'> | null) {
    if (!entity) {
      entity = this.entity;
    } else {
      this.entity = entity;
    }

    if (entity) {
      if ('geometry' in entity) {
        this.flyToByGeometry(entity?.geometry, entity?.geometryContentType);
      } else if ('lon' in entity) {
        this.flyByFlatGeometry(entity?.lon, entity?.lat);
      }
    }
  }

  flyToCalculatedGeometryBySensors(sensors: SensorSummary[]): void {
    const sensorsCartesians3: Cartesian3[] = [];
    sensors.forEach(sensor => sensorsCartesians3.push(Cartesian3.fromDegrees(sensor.lon, sensor.lat)));
    const rectangle = Rectangle.fromCartesianArray(sensorsCartesians3);
    const destination = this.resizeRectangle(rectangle, 0.0003);

    if (destination.north > 0 && destination.south > 0 && destination.east > 0 && destination.west > 0)
      this.cesiumService.getCurrentViewer()?.camera?.flyTo({ destination });
  }

  private flyToByGeometry(geometry: Structure['geometry'], geometryContentType?: Structure['geometryContentType']) {
    if (geometry) {
      const rectangle = geometryContentType === 'geojson'
        ? GeomUtils.getBboxFromGeoJSON(JSON.parse(geometry))
        : GeomUtils.getBboxFromKML(geometry);

      if (rectangle) {
        const destination = this.resizeRectangle(rectangle);
        this.cesiumService.getCurrentViewer()?.camera?.flyTo({ destination });
      }
    }
  }

  private flyByFlatGeometry(longitude: number, latitude: number) {
    const rectangle = Rectangle.fromDegrees(
      longitude,
      latitude,
      longitude,
      latitude,
    );

    const destination = this.resizeRectangle(rectangle);
    this.cesiumService.getCurrentViewer()?.camera.flyTo({ destination });
  }

  private resizeRectangle(rect: Rectangle, width = 0.003): Rectangle {
    rect.west = rect.west - width;
    rect.south = rect.south - width;
    rect.east = rect.east + width;
    rect.north = rect.north + width;

    return rect;
  }
}
