import { Component, Input, OnInit } from '@angular/core';
import { StsGroupGraphScrollerComponent } from '@geomatys/ngx-core/sensor-things';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-group-graph-scroller',
  templateUrl: './group-graph-scroller.component.html',
  styleUrls: ['./group-graph-scroller.component.scss']
})
export class GroupGraphScrollerComponent extends StsGroupGraphScrollerComponent implements OnInit {

  @Input() topperHeight!: number;
  @Input() graphOptions!: Partial<EChartsOption>;
  @Input() set reload(reload: number) {
    this.loaded = false;
    // @ts-ignore
    this.resultCount = this.dataSource._maxItems;
  }

  resultCount = 0;
  public groupGraphOptions!: Partial<EChartsOption>;
  public isGrouped = true;
  private loaded = false;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.groupGraphOptions = Object.assign(this.graphOptions, { title: { show: false }});
  }

  public isLoading(): boolean {
    if (this.loaded) {
      return false;
    }
    const loading = this.dataSource.data.length > 0 && this.dataSource.data
      .flat()
      .filter(i => i != undefined)
      .length === 0;
    if (!loading) {
      this.loaded = true;
    }
    return loading;
  }

}
