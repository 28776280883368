<div fxLayout="row" fxLayoutAlign="start center">
  <h1>{{'ALERTS.TITLE_FILTER' | translate}}</h1>
  <mat-icon (click)="resetFilters()" id="reset" svgIcon="log-au-arrow-rotate-left"></mat-icon>
</div>

<h2>{{'DATES' | translate}} :</h2>
<div fxLayout="row" fxLayoutAlign="space-between center" id="date-filter">
  <app-date-picker-wrapper (ngModelChange)="setStartDate($event)" [ngModel]="startDate"
                           placeHolder="{{ globalStartDate }}"></app-date-picker-wrapper>
  <app-date-picker-wrapper (ngModelChange)="setEndDate($event)" [ngModel]="endDate"
                           placeHolder="{{ globalEndDate }}"></app-date-picker-wrapper>
</div>
<div fxLayout="row" fxLayoutAlign="start center">
  <h2>{{'ALERTS.ATTRIBUTE_QUALIFICATION' | translate}} :</h2>
  <mat-icon (click)="toggleQualificationOptions()" [svgIcon]="chevronQualification" class="toggle"></mat-icon>
</div>
<div [hidden]="!openQualification">
  <div class="filter-container" fxLayout="column" fxLayoutGap="5px">
    <mat-checkbox (ngModelChange)="setQualification($event, qualification.NEW)" [(ngModel)]="newSelected">
      {{'ALERTS.QUALIFICATION.NEW' | translate}}
    </mat-checkbox>
    <mat-checkbox (ngModelChange)="setQualification($event, qualification.HEALTH_ALERT)" [(ngModel)]="healthAlertSelected">
      {{'ALERTS.QUALIFICATION.HEALTH_ALERT_PLURAL' | translate}}
    </mat-checkbox>
    <mat-checkbox (ngModelChange)="setQualification($event, qualification.THRESHOLD_ALERT)" [(ngModel)]="thresholdAlertSelected">
      {{'ALERTS.QUALIFICATION.THRESHOLD_ALERT_PLURAL' | translate}}
    </mat-checkbox>
    <mat-checkbox (ngModelChange)="setQualification($event, qualification.UNCERTAIN_DATA)" [(ngModel)]="uncertainDataSelected">
      {{'ALERTS.QUALIFICATION.UNCERTAIN_DATA_PLURAL' | translate}}
    </mat-checkbox>
    <mat-checkbox (ngModelChange)="setQualification($event, qualification.SAMPLE_ERROR)" [(ngModel)]="sampleErrorSelected">
      {{'ALERTS.QUALIFICATION.SAMPLE_ERROR_PLURAL' | translate}}
    </mat-checkbox>
    <mat-checkbox (ngModelChange)="setQualification($event, qualification.ABERENT_DATA)" [(ngModel)]="aberentDataSelected">
      {{'ALERTS.QUALIFICATION.ABERENT_DATA_PLURAL' | translate}}
    </mat-checkbox>
  </div>
</div>
