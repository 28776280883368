export type Alert = {
  id: number;
  qualification: AlertQualification;
  comment: string;
  value: number;
  sensorId: string;
  sensorName: string;
  structureName: string;
  structureId: number;
  paramCode: string;
  paramName: string;
  sampleDate: number;
  threshold: number;
  thresholdType: ThresholdType;
  thresholdPeriod?: ThresholdPeriod;
  thresholdUom: string;
}

export enum AlertQualification {
  NEW = 'NEW',
  HEALTH_ALERT = 'HEALTH_ALERT',
  THRESHOLD_ALERT = 'THRESHOLD_ALERT',
  UNCERTAIN_DATA = 'UNCERTAIN_DATA',
  SAMPLE_ERROR = 'SAMPLE_ERROR',
  ABERENT_DATA = 'ABERENT_DATA',
}

export enum ThresholdType {
  SIMPLE_THRESHOLD = 'SIMPLE_THRESHOLD',
  DELTA = 'DELTA'
}

export enum ThresholdPeriod {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}
