import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EChartsOption } from 'echarts';
import { GraphConfig, GraphType } from '../../shared/graph-wrapper.model';
import { ECharts } from 'echarts';
import { DatasetOption } from 'echarts/types/dist/shared';

@Component({
  selector: 'app-graph-wrapper',
  templateUrl: './graph-wrapper.component.html',
  styleUrls: ['./graph-wrapper.component.scss']
})
export class GraphWrapperComponent implements OnInit, OnChanges {

  public state: 'loading' | 'ok' | 'error' | 'no-data' = 'loading';
  public options: EChartsOption | undefined;
  public radioButtonSelection: string | undefined;
  public radioButtonOptions: {
    [key: string]: EChartsOption
  } | undefined;
  public graphType = GraphType;
  public sliderSelection!: number;
  public sliderOptions: Array<EChartsOption> | undefined;
  public sliderMax!: number;
  private subscription: Subscription | undefined;
  public echartsInstance!: ECharts;


  @Input() graphConfig!: GraphConfig;
  @Input() source!: Observable<EChartsOption | Array<EChartsOption>>;
  @Input() info!: string;
  @Input() mouseoverCallback: ((params: any) => void) | undefined;

  ngOnChanges($event: SimpleChanges): void {
    if ($event['source'] && !$event['source'].firstChange) {
      this.state = 'loading';
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.initComponent($event['source'].currentValue);
    }
  }

  ngOnInit(): void {
    this.subscription = this.initComponent(this.source);
  }

  private initComponent(graphSource: Observable<EChartsOption | Array<EChartsOption>>): Subscription {
    return graphSource.subscribe({
      next: value => {
        this.state = 'ok';

        if (Array.isArray(value)) {
          if (this.graphConfig.type === GraphType.DUAL) {
            this.radioButtonOptions = this.handleDualGraph(value);
            if(this.radioButtonOptions){
              this.state = 'ok';
            }else{
              this.state = 'no-data';
            }
          } else if (this.graphConfig.type === GraphType.MULTI) {
            this.sliderOptions = this.handleMultiGraph(value);
            if(this.sliderOptions){
              this.state = 'ok';
            }else{
              this.state = 'no-data';
            }
          }
        } else {
          this.options = this.handleSingleGraph(value);
          if(this.options){
            this.state = 'ok';
          }else{
            this.state = 'no-data';
          }
        }
      },
      error: err => {
        if (err.message === 'no data') {
          this.state = 'no-data';
        } else {
          this.state = 'error';
        }
      }
    });
  }


  private handleSingleGraph(value: EChartsOption): EChartsOption | undefined  {
    if(value.timeline){
      if(value.timeline?.data?.length){
        if(value.timeline?.data?.length > 0){
          return value;
        }
      }
    }
    const dataset =value.dataset as DatasetOption;
    if (dataset) {
      if (Array.isArray(dataset.source) && dataset.source.length > 1) {
          return value;
        }
    }

    // Alerts graphs case
    if(Array.isArray(value.dataset)){
    const altertdataset = value.dataset[0] as DatasetOption;
    if (altertdataset) {
      if (Array.isArray(altertdataset.source) && altertdataset.source.length > 1) {
          return value;
        }
    }
    }

    //graphs with selected bac case
    if(value.series){
      if(Array.isArray(value.series)){
        return value;
      }
      if(value.series.data && Array.isArray(value.series.data)){
        return value;
      }
    }

    return undefined;
  }

  private handleDualGraph(value: Array<EChartsOption>): { [key: string]: EChartsOption }  | undefined  {
    // need to redo the test to avoid compiling errors
    if (this.graphConfig.type === GraphType.DUAL) {
      const dataset = value[0].dataset as DatasetOption;
      if (dataset) {
        if (Array.isArray(dataset.source) && dataset.source.length > 1) {
          this.radioButtonSelection = this.graphConfig.defaultValue;
          return {
            [this.graphConfig.options[0].value]: value[0],
            [this.graphConfig.options[1].value]: value[1],
          };
          }
      }
      const series = value[0].series;
      if(series){
        if(Array.isArray(series) ){
          this.radioButtonSelection = this.graphConfig.defaultValue;
          return {
            [this.graphConfig.options[0].value]: value[0],
            [this.graphConfig.options[1].value]: value[1],
          };
        }
      }

    }
    return undefined;
  }

  private handleMultiGraph(value: Array<EChartsOption>): Array<EChartsOption> | undefined {
    this.sliderSelection = 0;
    this.sliderMax = value.length - 1;
    return value;
  }


  exportGraph() {
    const image = this.echartsInstance.getDataURL();
    const a = document.createElement('a');
    a.href = image;
    a.download = 'Aqualit.png';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(image);
    },0);
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    if (this.mouseoverCallback !== undefined) {
      ec.on('mouseover', 'series', (params) => {
        this.mouseoverCallback!(params);
      });
    }
  }
}
