<div fxLayout="column" id="app-container">
  <div *ngIf="isAuthenticated" fxLayout="row" fxLayoutAlign="start center" id="header">
    <img alt="Aqualit" id="logo" src="/assets/logo.svg"/>
    <div fxFlex>
      <h1>{{title}}</h1>
      <div *ngIf="!isAdminRoute && sensorTree !== undefined" class="nav-header" fxLayout="row"
           fxLayoutAlign="start center"
           fxLayoutGap="10px">
        <mat-select [(ngModel)]="sensorTree.structureName" class="tree-choice">
          <mat-option (click)="onStructureSelected(so.name)" *ngFor="let so of structureOptions"
                      [value]="so.name">{{so.name}}</mat-option>
        </mat-select>
        <fa-icon [icon]="faArrowRightLong"></fa-icon>
        <mat-select [(ngModel)]="sensorTree.bacName" class="tree-choice">
          <mat-option (click)="onBacSelected(bo.name)" *ngFor="let bo of bacOptions"
                      [value]="bo.name">{{bo.name}}</mat-option>
        </mat-select>
        <fa-icon [icon]="faArrowRightLong"></fa-icon>
        <mat-select [(ngModel)]="sensorTree.sensorName" class="tree-choice">
          <mat-option (click)="onSensorSelected(so.name)" *ngFor="let so of sensorOptions"
                      [value]="so.name">{{so.name}}</mat-option>
        </mat-select>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">
      <img (click)="navigateTo('')" *ngIf="!isAdminRoute" alt="Map" class="icon"
           matTooltip="{{'MAP_INFO' | translate}}" src="/assets/icon_map.svg">
      <img (click)="navigateTo('./synthesis')" *ngIf="!isAdminRoute" alt="Synthese" class="icon"
           matTooltip="{{'SYNTHESIS_INFO' | translate}}" src="/assets/icon_synthese.svg">
      <img (click)="navigateTo('./measures')" *ngIf="!isAdminRoute" alt="Chart" class="icon"
           matTooltip="{{'MEASURE_INFO' | translate}}" src="/assets/icon_chart-column.svg">
      <img (click)="navigateTo('./alerts')" *ngIf="!isAdminRoute" alt="Alerte" class="icon"
           matTooltip="{{'ALERT_INFO' | translate}}" src="/assets/icon_alertes.svg">
      <div *ngIf="isAdminRoute" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <h1 id="user-login">{{usrLogin}}</h1>
        <fa-icon [icon]="faCircleUser" class="icon"></fa-icon>
      </div>
      <button [matMenuTriggerFor]="menu">
        <img alt="Barres" class="icon !mx-auto" src="/assets/icon_barres.svg">
      </button>
      <mat-menu #menu="matMenu" id="tool-bar-menu">
        <button (click)="navigateTo('')" mat-menu-item>Aqualit</button>
        <button (click)="navigateTo('./account')" mat-menu-item>{{'HEADER.MY_ACCOUNT' | translate}}</button>
        <button (click)="navigateTo('./administration/users')" *ngIf="isAdmin"
                mat-menu-item>{{'HEADER.USERS' | translate}}</button>
        <button (click)="navigateTo('./administration/structures')" *ngIf="isAdmin"
                mat-menu-item>{{'HEADER.STRUCTURES' | translate}}</button>
        <button (click)="openExamind()" *ngIf="isAdmin"
                mat-menu-item>{{'HEADER.OPEN_EXAMIND' | translate}}</button>
        <button (click)="logout()" mat-menu-item>{{'HEADER.LOGOUT' | translate}}</button>
      </mat-menu>
    </div>
  </div>

  <div id="body">
    <router-outlet></router-outlet>
  </div>

  <gco-toast [y_translate]="-120"></gco-toast>

</div>
