import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';
import { FilterLayersPipe } from './filter-layers.pipe';
import { WrapFnPipe } from './wrap-fn.pipe';

@NgModule({
  declarations: [
    CapitalizeFirstPipe,
    FilterLayersPipe,
    WrapFnPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CapitalizeFirstPipe,
    FilterLayersPipe,
    WrapFnPipe,
  ]
})
export class PipesModule { }
