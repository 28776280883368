<app-dashboard [dashboardOpened]="true" #dashboard>
  <mat-tab-group class="controls">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon svgIcon="log-au-sensor-ico" matTooltip="{{'SENSOR_PLURAL' | translate}}" ></mat-icon>
      </ng-template>
      <app-sensor-list
        [sensors]="sensors"
        [sensorNoFilter]="sensorsNoFilter"
        [countSensors]="countSensors"
        [filterOptions]="filterOptions"
      ></app-sensor-list>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon matTooltip="{{'LAYERS.LIST.TITLE' | translate}}">layers</mat-icon>
      </ng-template>
      <app-layer-controller></app-layer-controller>
    </mat-tab>
  </mat-tab-group>
</app-dashboard>
